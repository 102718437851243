//中文对照表
module.exports = {
  delete: '删除',
  yes: '是',
  no: '否',
  startTime: '开始日期',
  to: '至',
  endTime: '开始日期',
  invoiceStatus: '发票状态',
  all: '全部',

  importantNotice: '重要告知',
  fileColumn: '文件专栏',
  updateTime: '更新时间',
  downloadFile: '下载文件',


  invoiced: '已开票',
  uninvoiced: '未开票',
  invoiceWaiting: '开票中',
  downloadBill: '下载账单',
  tcCertApplyPrice: 'TC证书申请费用',
  applyInvoice: '申请开票',
  uploadVoucher: '上传付款水单',
  reUploadVoucher: '重新上传付款水单',
  payVoucherUploadSuccess: '付款水单上传成功',
  viewBankVoucher: '预览银行水单',
  deductedFromAccountBalance: '已从账户余额扣款',
  payStatus: '付款状态',
  waitPay:'待付款',
  paid: '已入账',
  waitETKOConfirm:'待ETKO确认收款',
  applyExtensionPrice: '申请扩项费用',
  extensionApplyTcNumber: '扩项申请的SC编号',
  selectDate: '请选择日期',
  reUpload: '重新上传',
  otherOrganizations: '其他机构',
  uploadSuccessPleaseSave: '上传成功，请保存',
  uploadSuccess: '上传成功',
  uploadInvoiceAndRecord: '上传发票及银行收款记录',
  uploadTransportationDoc: '上传运输文件',
  uploadOrder:'上传订单及补充文件',
  uploadAppendix:'上传附件',
  uploadTestReport:'上传测试报告：',
  checkOrder:'查看订单及补充文件',
  checkAppendix:'查看附件',
  checkTransportationDoc: '查看运输文件',
  checkInvoiceAndRecord: '查看发票及银行收款记录',
  buyerOrderOrContract:'买方订单或销售合同(须上传上表所填的所有订单合同的原件)及其它必要补充文件：',

  pleaseUploadContract_file: '请上传与分包商签署的合同',
  pleaseUploadSc_file: '请上传分包商的有效SC证书',
  pleaseUploadUnit_no: '请输入独立持证分包商的持证许可号',
  pleaseUploadUnit_no_1: '请输入分包商的认证机构客户编号或GOTS持证许可号',
  pleaseInputUnit_name: '请输入分包商的公司名称',
  pleaseInputPercent: '请正确输入材料成分所含的百分比',
  pleaseInputUnit_address: '请输入分包商的公司地址',
  pleaseInputStep: '请输入操作步骤/生产活动',
  pleaseInputEnglishNameOfFinalParty: '请输入操作步骤/生产活动',
  pleaseSelectCountry: '请选择国家',
  pleaseInputDistrict: '请输入乡/镇',
  pleaseInputZipcode: '请输入邮编',
  pleaseSelectStandard: '请选择认证标准',
  pleaseSelectCert_body: '请选择独立认证分包商的认证机构',
  pleaseSelectCert_sub_expire: '请选择独立认证分包商的SC有效期',
  pleaseFillInAsRequired: '请按照要求填写',
  pleaseUploadOneOrder: '请至少上传一个买方订单或销售合同',
  pleaseUploadTransportationDoc: '请上传运送待加工材料到分包方的运输单据及签收记录',
  pleaseUploadProcessingInvoice: '请上传分包商开具的加工发票',
  pleaseInput: '请输入',
  pleaseSelectType: '请先选择类型！',
  pleaseFillInformation: ' 请至少填写一项附加信息',
  pleaseFillAndCheck: ' 请填写并验证合格上一个组件的信息',

  fillInformation: ' 填写附加信息',
  extensionApplicationStatus: ' 扩项申请状态',
  rechargeDetails: ' 充值明细',
  rechargeAmount: ' 充值金额',
  rechargeDescription: ' 充值说明',
  recharge_record: ' 充值记录',
  serialNumber: ' 流水号',
  bankSlip: ' 银行水单',
  submissionTime: ' 提交时间',
  typeofInvoice: '发票类型：',
  VAT_electronic_ticket: '增值税电子普票',
  VAT_special_electronic_ticket: '增值税专用电子普票',
  VAT_electronic_special_invoice: '增值税电子专用发票',
  Paper_VAT_special_invoice: '纸质增值税专用发票',

  noSCPleaseSelectAnother: '没有对应的SC证书，请选择其他标准',
  ExceedPleaseRefill: '使用量超出总量，请重新输入！',
  whenNeedPleaseUploadTestReport: '当需要时，请上传相应的测试报告',
  ifNotDisplayPleaseSubmit: '如果您要申请的成品的产品类别、产品细节及产品成分未在下拉菜单中呈现，您需要首先移步到首页“申请扩项”模块提交扩项申请',
  ifNonTradersPleaseProvideInvoice: '如果在最终买家和卖方(贵司)中间有涉及到非认证的贸易商参与中间交易，请同时提供贸易商开给最终买家的发票和卖方(贵司)开给贸易商的发票',

  subcontractorScopeCertificate:'分包商的范围证书（适用于独立持证分包商）：',
  subcontractorInvoice:'分包商开具的加工发票：',
  subcontractorTransportDoc:'分包商运送加工过的产品的运输单据：',
  transToSubcontractorDoc:'运送待加工材料到分包方的运输单据及签收记录：',
  calculateAffterFill:'准确填写完每个认证组件的净重后，将会自动计算',

  productNumNeedSubtract:'修改的成品数量不可以大于最大值',
  weightOfComponent:'组件的认证重量：',
  atLeastOneCertificationStandard: '至少有一个认证标准！',
  englishNameOfFinalParty:'最后加工方的英文名称',
  UpToSixCertificationStandard: '最多只能添加6条认证标准',
  dataError: '!数据错误：Shipment No. ',
  weightErrorPleaseCorrect: '包含的产品净重比毛重大，请核查并更正',
  saveSuccess: '保存成功',
  audit:'待审核',
  returned:'已退回',
  ratify:'已批准',
  areyouwanttoreturned: '您确定要撤回MB吗？',
  returnSuccess: '撤回成功',
  returnwarn: '撤回异常',
  closeConfirm:'确认关闭？',
  appendx: '附件',
  noBill: '暂无账单',
  invoiceApplicationReturned: '开票申请已退回',


  consignee: '收货方',




  applyStatus: '审批状态',
  rawMaterialStatusPending: '待审批',
  rawMaterialStatusReturned: '已退回',
  rawMaterialStatusPass: '已批准',



  recharge:{
    serialSn: '流水号',
    money: '充值金额',
    remark: '充值说明',
    bankVoucher: '银行水单',
  },
  auditStatus: '审核状态',
  returnReason: '退回原因',
  submitTime: '提交时间',
  etkoTcNumber: 'ETKO TC编号',

  dlczfbsdczxkh: '独立持证分包商的持证许可号',
  dlczfbsdczxkh_1: '分包商的TE-ID',
  dlczfbsdczxkh_2: '分包商的GOTS持证许可号',
  dlczfbsdczxkh_3: '分包商的认证机构客户编号',
  fbsdgsmc: '分包商的公司名称',
  qsrczbz: '请输入操作步骤/生产活动',
  qsrczbzTs: '请将该分包商范围证书上的”Process Categories“栏内显示的所有操作工序及工序代码复制到这里，不同工序之间用";"隔开。',
  clhlzbbhf: '材料含量占比选择不合法，请重新选择',
  dqcplhlzbym: '当前产品材料含量占比已满',
  qsrzjmc: '请输入组件名称',

  warning: '警告',
  materialMismatchWarningFirst: '原料TC中的',
  materialMismatchWarningTips: '材料未存在于您声明的成品材料成分, 烦请解释原因供ETKO人工审批。',
  entryReason: '填写理由',
  refresh_success: '刷新成功',
  placeholder:{
    'p1':'请选择类型',
    'p2':'请输入关键词搜索',
    'p3':'请选择供应链类型',
    'p4':'请选择认证标准',
    'p5':'请选择',
    'p6':'请输入公司英文名',
    'p7':'请输入公司英文地址',
    'p8':'请输入公司中文名称',
    'p9':'请输入公司中文地址',
    'p10':'请选择公司所在国家',
    'p11':'请选择公司所在州/省',
    'p12':'镇 (仅允许输入英文和"-")',
    'p13':'请输入联络人名称',
    'p14':'请输入联络电话',
    'p15':'请输入联络邮箱',
    'p16':'请输入许可号',
    'p17':'请输入证书编号',
    'p18':'请选择产品类别',
    'p19':'请选择产品细节',
    'p20':'请选择产品细节',
    'p21':'请输入编号',
    'p22':'请选择成品标准',
    'p23':'请选择是否为组件认证',
    'p24':'请选择审批状态',
    'p25':'选择日期',
    'p26':'请选择产品生产和存储涉及的工序',
    'p27':'请选择最后加工方',
    'p28':'请输入订单号或销售合同号',
    'p29':'请输入成品货号',
    'p30':'请选择产品销售单位',
    'p31':'请输入产出的成品数量',
    'p32':'请输入成品总净重',
    'p33':'请输入附属材料重量',
    'p34':'请输入',
    'p35':'请选择产品标准(标签等级)',
    'p36':'请选择卖方公司',
    'p37':'请选择非持证的贸易商',
    'p38':'请选择买方公司',
    'p39':'收货方英文名称',
    'p40':'请选择原料TC标准',
    'p41':'请选择SC标准',
    'p42':'请输入内容',
    'p43':'请输入TC编号',
    'p44':'；请直接制复制料TC上对应的信息',
    'p45':'请输入GOTS认证重量',
    'p46':'最后加工方的持证许可号',
    'p47':'最后加工企业的国家/地区',
    'p48':'邮编',
    'p49':'请选择材料类型',
    'p50':'请选择材料名称',
    'p51':'请输入名称详细描述',
    'p52':'邮编',
    'p53':'请输入工序详细描述',
    'p54':'请先上传发票和运输文件',
    'p55':'请上传文件压缩包',
  },
  serch:{
    's1':'供应链类型',
    's2':'认证标准',
    's3':'查询',
    's4':'重置',
    's5':'证书有效期',
    's6':'是否为认证的附属场所',
  },
  Table_Operation:{
    'delete':'删除',
    'edit':'编辑',
    'preservation':'保存',
    'upload':'上传',
    'see':'查看',
    'Download-contract-template':'下载合同模板',
    'add_rows':'添加行',
    'Payment_voucher':'付款水单',
    'preview':'预览',
    'download':'下载',
    'Upload_attachments':'上传附件',
    'Re_upload':'重新上传',
    'contract_template':'合同模板.pdf',
  },
  table_state:{
    'Approval_Status':'ETKO审批状态',
    'reviewed':'待审核',
    'Approved':'已批准',
    'Returned':'已退回',
    'record':'个记录',
    'confirmed':'待确认草本',
    'be_issued':'待出TC正本',
    'be_issued_sure':'已出TC正本',
    'review_completed':'预审完成',
  },
  Public_btn:{
    'return':'退回',
    'back':'返回',
    'next':'下一步',
    'Previous-step':'上一步',
    'Continue_adding':'继续添加',
    'modify':'修改',
    'details':'详情',
    'Upload_files':'上传文件',
    'Upload_files_click':'点击上传',
    'Support_extension':'支持扩展名：.pdf .jpg...',
    'Export-to-Excel':'导出Excel',
    'cancel':'取消',
    'Submit':'提交',
    'Stow':'收起',
    'open':'展开',
    'buyer':'买方',
    'Ship':'收货方',
    'Final_processing_party':'最后加工方',
    'Non_licensed_traders':'非持证贸易商',
    'supplier':'供应商',
    'see':'查看',
    'add_push':'添加',
    'Import_add':'导 入',
    'Reselect':'重新选择',
    'determine':'确定',
    'Confirm_modification':'确认修改',
    'copy_mb':'复制MB',
  },
  Headers: {
    'Headers_t1': '首页',
    'Headers_t2': '附属单位和供应链',
    'Headers_t3': '我的认证产品',
    'Headers_t4': '我的原料库存',
    'Headers_t5': '申请状态管理',
    'Headers_t6': '账单及付款信息',
    'Account':'我的账户信息',
    'sign_out':'退出',
    'extension':'申请扩项',
    'push_inventory':'新增原料库存',
    'herbaceous_sure':'确认TC草本',
    'view_tc_dart':'查看TC草本',
    'Certification_Details':'TC认证详情',
    'push_mb':'添加成品的总量平衡(MB)信息',
    'Headers_t7':'修改成品的总量平衡(MB)信息',
    'Headers_t8':'修改并提交',
    'Headers_t9':'请先选择类型！',
    'Headers_t10':' 请至少填写一项附加信息',
  },
  home:{
    'text_01':'祝您开心每一天',
    'text_02':'认证产品',
    'text_03':'原料库存(个)',
    'text_04':'申请TC数(次)',
    'text_05':'常用功能',
    'text_06':'添加供应链单位',
    'text_07':'创建原料库存',
    'text_08':'填写成品的总量平衡(MB)',
    'text_09':'申请TC证书',
    'text_10':'申请扩项',
    'text_11':'未读消息',
    'text_12':'已读消息',
    'text_13':'申请扩项',
    'text_14':'申请扩项',
  },
  Extension:{
    'Extension_01':'选择类型',
    'Extension_02':'是否需要增加独立认证的分包商',
    'Extension_yes':'是',
    'Extension_no':'否',
    'Extension_03':'填写扩项信息',
    'Extension_04':'选择需要扩项的证书',
    'Extension_05':'认证标准',
    'Extension_06':'编号',
    'Extension_07':'SC证书到期日',
    'Extension_09':'填写需新增的独立持证的分包商信息',
    'Extension_10':'请根据您的实际需求新增单个或多个独立持证分包商',
    'Extension_11':'客户编号',
    'Extension_63':'地址',

    'Extension_12':'附属单位的公司名称',
    'Extension_13':'地址',
    'Extension_14':'操作步骤/生产活动',
    'Extension_15':'附属单位类型',
    'Extension_16':'国家/地区',
    'Extension_17':'州/省',
    'Extension_18':'乡/镇',
    'Extension_19':'邮编',
    'Extension_20':'认证标准',
    'Extension_21':'联络人',
    'Extension_22':'联络电话',
    'Extension_23':'联络邮箱',
    'Extension_23_1':'营业执照号码',
    'Extension_23_2':'员工人数',
    'Extension_24':'独立认证分包商的认证机构',
    'Extension_25':'独立认证分包商的SC有效期',
    'Extension_26':'分包商的有效SC证书',
    'Extension_27':'与分包商签署的合同',
    'Extension_28':'操作',
    'Extension_29':'独立认证分包商',
    'Extension_30':'填写扩项认证产品信息',
    'Extension_31':'请根据实际情况添加单个/多个认证产品信息',
    'Extension_32':'产品类别',
    'Extension_33':'产品细节',
    'Extension_34':'产品材料成分',
    'Extension_35':'标准(标签等级)',
    'Extension_36':'设施编号',
    'Extension_37':'增加认证产品',
    'Extension_38':'添加认证产品',
    'Extension_39':'场所名称(可多选)',
    'Extension_40':'是否组件认证',
    'Extension_41':'认证标准(标签等级)',
    'Extension_42':'组件',
    'Extension_43':'组件名称',
    'Extension_44':'组件认证标准(标签等级)',
    'Extension_45':'组件的产品成分信息',
    'Extension_46':'材料类型',
    'Extension_47':'所含材料名称',
    'Extension_48':'材料含量占比',
    'Extension_49':'添加组件认证',
    'Extension_50':'上传签章申请并提交',
    'Extension_51':'下载扩项申请单据',
    'Extension_52':'需要支付的费用',
    'Extension_53':'扩项签章文件',
    'Extension_54':'费用信息',
    'Extension_55':'费用付款方式为线下付款，请在提交申请后安排银行转账付款，并移步到"账单及付款信息"界面找到对应的账单上传付款水单',
    'Extension_56':'附加说明',
    'Extension_57':'产品成分信息',
    'Extension_58':'根据实际情况填写单个或多个成分信息',
    'Extension_59':'请输入机构名称',
    'Extension_60':'您有分包商信息暂未保存，请保存后进行下一步。',
    'Extension_61':'请选择需要扩项的证书。',
    'Extension_62':'您暂未填写需新增的独立持证的分包商信息，请至少填写一条后进行下一步。',
  },
  Login:{
    'login_01': '系统',
    'login_02': '欢迎使用',
    'login_03': '忘记密码',
    'login_04': '请输入账号',
    'login_05': '请输入密码',
    'login_06': '登录',
    'login_07': '重置密码',
    'login_08': '发送验证码',
    'login_09': '如无法通过邮箱验证',
    'login_10': '请发送邮件至',
    'login_11': '请输入您的登录邮箱',
    'login_12': '忘记用户名',
    'login_13': '输入8位数的ETKO客户编号找回账号',
    'login_14': '寻求帮助',
    'login_15': '找回登录账号',
    'login_16': '您的账号是',
    'login_17': '请输入新密码',
    'login_18': '请确认新密码',
    'login_19': '两次密码不一致',
    'login_20': '重置密码的链接已发送到您的用户名邮箱中, 请点击您邮箱中收到的链接重置密码。',
    'login_21': '登录成功！',
  },
  Detachment:{
    'detachment_01':'我的公司信息和附属单位',
    'detachment_02':'我的供应链单位',
    'detachment_03':'我的公司信息',
    'detachment_04':'公司英文名称',
    'detachment_05':'英文地址',
    'detachment_06':'法定语言的公司名称',
    'detachment_07':'法定语言的公司地址',
    'detachment_08':'营业执照号码',
    'detachment_09':'ETKO跟单员',
    'detachment_10':'认证状态',
    'detachment_101':'持有的品牌',
    'detachment_102':'员工人数',
    'detachment_11':'最新的SC正本',
    'detachment_12':'SC编号',
    'detachment_13':'证书上的附属单位',
    'detachment_14':'序号',
    'detachment_15':'附属单位名称',
    'detachment_16':'附属单位详情',
    'detachment_17':'基本信息',
    'detachment_18':'联络人信息',
    'detachment_19':'单位地址',
    'detachment_20':'国家/地区',
    'detachment_21':'供应链单位',
    'detachment_22':'共',
    'detachment_23':'新建供应链单位',
    'detachment_24':'个供应链单位',
    'detachment_25':'公司中文名称',
    'detachment_26':'持证许可号',
    'detachment_26_4':'GOTS持证许可号或TE证书的认证机构客户编号',
    'detachment_26_3':'持证许可号或认证机构客户编号',
    'detachment_26_2':'GOTS持证许可号或TE证书的认证机构客户编号',
    'detachment_53':'主持证许可号',
    'detachment_53_1':'GOTS持证许可号',
    'detachment_53_2':'认证机构客户编号',

    'detachment_27':'SC证书的认证标准',
    'detachment_28':'SC证书的编号',
    'detachment_29':'SC证书有效期',
    'detachment_30':'SC证书附件',
    'detachment_31':'供应链单位详情',
    'detachment_32':'供应链单位基本信息',
    'detachment_33':'公司中文地址',
    'detachment_34':'镇',
    'detachment_35':'供应链认证信息',
    'detachment_36':'买家的认证状态',
    'detachment_37':'上传SC证书',
    'detachment_38':'主持证公司英文名称',
    'detachment_39':'主持证公司持证许可号',
    'detachment_40':'所在SC证书的认证标准',
    'detachment_41':'所在SC证书有效期',
    'detachment_42':'公司英文地址',
    'detachment_43':'供应链信息',
    'detachment_44':'附加信息',
    'detachment_45':'买方的认证状态',
    'detachment_46':'未持有SC证书',
    'detachment_47':'SC的主持证方',
    'detachment_48':'做为SC证书的附属场所',
    'detachment_49':'无需填写',
    'detachment_50':'如果供应链单位有多个SC证书，请添加第二个SC证书',
    'detachment_51':'供应商的认证状态',
    'detachment_52':'邮编',
  },
  MyCertifiedProducts:{
    'product_01':'我的认证产品',
    'product_02':'个认证产品',
  },
  Rawmaterialinventory:{
    'home_01':'我的原料库存',
    'home_02':'原料库存',
    'home_03':'原料TC标准',
    'home_05':'原料TC颁发日期',
    'home_06':'库存创建时间',
    'home_07':'审批时间',
    'home_08':'添加原料库存',
    'home_09':'原料TC编号',
    'home_09_1':'回收废料的声明书编号',
    'home_10':'当前库存',
    'home_11':'状态',
    'home_12':'原料库存详情',
    'home_13':'当前库存原料净重',
    'home_14':'查看附件',
    'home_15':'审批状态',
    'home_16':'原料信息',
    'home_17':'原料采购发票号',
    'home_18':'原料运输单号',
    'home_19':'原料运输日期',
    'home_20':'原料供应商',
    'home_21':'在原料TC上排列的产品序号',
    'home_22':'原料订单或销售合同号',
    'home_23':'原料货号',
    'home_24':'原料TC上所示净重',
    'home_24_1':'回收废料净重',
    'home_25':'原料产品类别',
    'home_26':'原料产品细节',
    'home_27':'原料产品成分',
    'home_28':'认证标准及认证重量',
    'home_29':'GOTS其它',
    'home_30':'库存使用记录',
    'home_31':'成品生产日期',
    'home_32':'原料TC的颁发日期',
    'home_33':'生产前该原料剩余库存净重(kgs)',
    'home_34':'该原料的生产投入总净重(kgs)',
    'home_35':'原料的该次生产损耗净重',
    'home_36':'原料有效转化至成品上的认证重量',
    'home_37':'生产后该原料剩余库存净重(kgs)',
    'home_38':'使用记录详情',
    'home_39':'原料TC上所示该款原料的净重',
    'home_40':'附属物重量',
    'home_41':'成品TC编号',
    'home_42':'成品生产日期',
    'home_43':'原料TC颁发日期',
    'home_44':'产出的成品的订单号或销售合同号',
    'home_45':'成品产品类别',
    'home_46':'成品产品细节',
    'home_47':'成品产品成分',
    'home_48':'该原料的生产投入总量净重(kgs)',
    'home_49':'成品TC的颁发日期',
    'home_50':'销售后剩余库存成品数量(以销售单位计)',
    'home_51':'销售后剩余库存成品总净重(kgs)',
    'home_52':'销售后剩余库存成品所含认证成分总量(kgs)',
    'home_53':'建立库存时先前已用掉的库存净重',
    'home_54':'原料TC编号',
    'home_55':'产品序号',
    'home_56':'原料TC上的产品序号',
    'home_57':'关闭',
  },
  New_inventory:{
    'inventory_01':'原料类型',
    'inventory_02':'填写原料信息',
    'inventory_03':'完成',
    'inventory_04':'请选择原料的认证标准所属组织',
    'inventory_05':'类型一',
    'inventory_06':'TE旗下的标准有GRS, RCS, OCS, RDS, RWS, RMS & RAS.',
    'inventory_07':'类型二',
    'inventory_08':'全球有机纺织标准, 该标准由国际天然纺织品协会（IVN, 德国）、日本有机棉协会（JOCA），美国有机贸易协会（OTA）和英国土壤协会（SA）组成的GOTS国际工作组IWG共同制定和发布',
    'inventory_09':'原料TC是否为ETKO颁发',
    'inventory_09_1':'是否为再生材料源头工序',
    'inventory_10':'TC编号',
    'inventory_11':'卖方公司名称',
    'inventory_12':'原料TC信息',
    'inventory_13':'原料TC认证标准',
    'inventory_14':'查看TC附件',
    'inventory_15':'原料TC卖家公司信息',
    'inventory_16':'卖方公司英文全名',
    'inventory_17':'卖方公司英文地址',
    'inventory_18':'原料TC上所列的关键运输信息',
    'inventory_18_1':'回收废料运输信息',
    'inventory_19':'运输序号',
    'inventory_20':'出运日期',
    'inventory_21':'运输文件编号',
    'inventory_22':'发票号码',
    'inventory_23':'毛重',
    'inventory_24':'附件',
    'inventory_25':'原料运输单',
    'inventory_26':'原料采购发票及银行转账记录',
    'inventory_27':'原料TC上所列的产品信息',
    'inventory_27_1':'回收废料信息',
    'inventory_28':'产品序号',
    'inventory_29':'先前已用掉的原料净重',
    'inventory_30':'原料TC及原料测试报告',
    'inventory_30_1':'回收废料的声明书及供应商协议',
    'inventory_31':'上传产品图片及生产记录',
    'inventory_31_2':'上传原料TC及要求的测试报告',
    'inventory_31_3':'上传回收废料的声明书及供应商协议',

    'inventory_32':'原料TC卖方公司信息',
    'inventory_32_1':'回收废料供应商',
    'inventory_33':'选择公司',
    'inventory_34':'请根据实际情况添加供应商信息或通过TC文件批量导入',
    'inventory_35':'成品总认证重量',
    'inventory_35_2':'组件认证重量',

    'inventory_36':'选择供应商',
    'inventory_37':'确定选中',
    'inventory_38':'添加原料TC上的关键运输信息',
    'inventory_38_1':'添加回收废料运输信息',
    'inventory_39':'装运序号',
    'inventory_40':'请根据原料TC的第9栏中相对应的“Shipment No”的序号填写',
    'inventory_41':'请输入运输文件编号',
    'inventory_42':'请输入发票号码',
    'inventory_43':'请输入毛重',
    'inventory_44':'上传原料运输单',
    'inventory_45':'上传原料采购发票及银行转账记录',
    'inventory_46':'请按原料TC上所示的产品序号填写',
    'inventory_47':'请输入原料订单或销售合同号',
    'inventory_48':'请输入原料货号',
    'inventory_49':'请输入原料TC上所示净重',
    'inventory_50':'请输入该产品的附属重量',
    'inventory_51':'请输入原料产品类别',
    'inventory_52':'请输入原料产品细节',
    'inventory_53':'请输入原料产品成分',
    'inventory_54':'请输入认证标准的重量',
    'inventory_55':'请输入先前已用掉的原料净重',
    'inventory_56':'SC证书附件预览',
    'inventory_57':'GOTS认证重量',
    'inventory_58':'包含的再生材料成分百分比',
    'inventory_59':'包含的可持续来源的材料百分比',
    'inventory_60':'提交成功',
    'inventory_61':'请耐心等待平台审核',
    'inventory_62':'原料TC上所列的产品信息',
    'inventory_62_1':'回收废料信息',
    'inventory_63':'未上传原料运输单',
    'inventory_64':'未上传原料采购发票',
  },
  Applicationstatus:{
    Total_balance:{
      'Total_01':'总量平衡(MB)和成品库存列表',
      'Total_02':'成品标准',
      'Total_03':'生产日期',
      'Total_04':'组件认证',
      'Total_05':'添加成品的总量平衡(MB)信息',
      'Total_06':'MB序号',
      'Total_07':'工序名称',
      'Total_08':'最后加工企业名称和地址',
      'Total_09':'订单号',
      'Total_10':'成品货号',
      'Total_11':'成品的产品类别',
      'Total_12':'成品的产品细节',
      'Total_13':'成品的产品成分',
      'Total_14':'销售单位',
      'Total_15':'产出成品数量',
      'Total_16':'成品总净重',
      'Total_17':'附属材料重量',
      'Total_18':'产品图片及生产记录',
      'Total_19':'总量平衡验证结果',
      'Total_20':'申请的成品TC编号',
      'Total_21':'剩余成品数量',
      'Total_22':'剩余总净重',
      'Total_23':'剩余库存成品总净重(kgs)',
      'Total_24':'查看组件认证详情',
      'Total_25':'认证组件序号',
      'Total_26':'认证组件名称',
      'Total_27':'认证组件材料成分',
      'Total_28':'认证组件的净重',
      'Total_29':'认证组件的附属物重量',
      'Total_30':'原料成分',
      'Total_31':'投入的认证原料信息',
      'Total_32':'查看原料详情',
      'Total_33':'原料订单号或销售合同号',
      'Total_34':'生产前剩余TC原料库存净重(kgs)',
      'Total_35':'本批生产投入的TC原料净重(kgs)',
      'Total_36':'生产损耗(kgs)',
      'Total_37':'生产损耗%',
      'Total_38':'转化到成品中的认证成分含量(kgs)',
      'Total_39':'对应的出运序号',
      'Total_40':'成品TC上的订单号',
      'Total_41':'附加信息',
      'Total_42':'未纳入成分计算的辅料明细（如适用）: ',
      'Total_43':'该批产品被认证的其他TC号（如适用）: ',
      'Total_44':'纤维长度和支数（如适用）: ',
      'Total_45':'纱线支数（如适用）: ',
      'Total_46':'布料的克重和经纬密度结构（如适用）: ',
      'Total_47':'成衣或成品纺织品的布料克重（如适用）: ',
      'Total_48':'组件的认证重量: ',
      'Total_49':'关闭: ',


    },
    tc_Apply:{
      'Apply_01':'TC申请状态',
      'Apply_02':'TC申请日期',
      'Apply_03':'申请成品TC',
      'Apply_04':'ETKO TC编号',
      'Apply_05':'申请TC标准',
      'Apply_06':'卖方公司名称和地址',
      'Apply_07':'认证产品明细',
      'Apply_08':'总毛重',
      'Apply_09':'总净重',
      'Apply_10':'总的认证重量',
      'Apply_11':'是否确认TC草本',
      'Apply_12':'已申请修正',
      'Apply_13':'未确认',
      'Apply_14':'已确认',
      'Apply_15':'是否付款',
      'Apply_16':'待确认付款',
      'Apply_17':'已付款',
      'Apply_18':'未付款',
      'Apply_19':'批复详情',
      'Apply_20':'TC草本/正本',
      'Apply_21':'确认TC草本',
      'Apply_22':'要求修正草本',
      'Apply_23':'上传付款水单',
      'Apply_24':'认证的产品明细',
      'Apply_25':'请确认是否已查看TC草本内容，若确认需要修正请填写具体原因',
      'Apply_26':'查看TC草本',
      'Apply_27':'修正原因',
      'Apply_28':'请输入其他需要声明的信息',
      'Apply_29':'请按实际账单金额上传付款水单',
      'Apply_30':'查看TC草本',
      'Apply_31':'请输入其他需要声明的信息',
    },
    TC_herbaceous:{
      'herbaceous_01':'是否确认草本？若未查看请先查看TC草本后确认无误后再点击确认',
      'herbaceous_02':'确认草本',
      'herbaceous_03':'订单信息',
      'herbaceous_04':'申请标准',
      'herbaceous_05':'认证产品的卖方信息',
      'herbaceous_06':'被代销方',
      'herbaceous_07':'SC证书编号',
      'herbaceous_08':'ETKO客户编号',
      'herbaceous_09':'非持证的贸易商',
      'herbaceous_10':'认证产品的买方信息',
      'herbaceous_11':'被代购方',
      'herbaceous_12':'运输信息',
      'herbaceous_13':'出货日期',
      'herbaceous_14':'运输文件号码',
      'herbaceous_15':'毛重(kg)',
      'herbaceous_16':'收货方名称',
      'herbaceous_17':'上传发票和运输文件',
      'herbaceous_18':'查看发票',
      'herbaceous_19':'查看运输文件',
      'herbaceous_20':'认证产品的信息',
      'herbaceous_21':'产出的产品的订单号或销售合同号',
      'herbaceous_22':'当前剩余库存成品数量(以销售单位计)',
      'herbaceous_23':'当前剩余库存成品总净重(kgs)',
      'herbaceous_24':'当前剩余库存成品所含认证成分总量(kgs)',
      'herbaceous_25':'本次TC申请的成品数量(以销售单位计)',
      'herbaceous_26':'本次TC申请成品总净重(kgs)',
      'herbaceous_27':'本次TC申请的成品所含认证成分总量(kgs)',
      'herbaceous_28':'认证组件信息',
      'herbaceous_29':'查看组件信息',
      'herbaceous_30':'卖方公司声明',
      'herbaceous_31':'本次申请的TC上所涵盖的认证产品有外包给分包商的加工步骤',
      'herbaceous_32':'卖方要在该TC上声明的其它信息',
      'herbaceous_33':'卖方公司声明',
      'herbaceous_34':'申请的TC的认证标准',
      'herbaceous_35':'出运序号',
      'herbaceous_36':'本次TC申请的成品所含附属材料重量(kgs)',
    },
    MB_pop:{
      'mbpop_01':'成品类型',
      'mbpop_02':'填写原料信息',
      'mbpop_03':'完成',
      'mbpop_04':'成品出货运输信息',
      'mbpop_04_1':'是否为Dropship',
      'mbpop_05':'成品的认证标准',
      'mbpop_06':'产品图片及生产记录',
      'mbpop_07':'成品的详情信息',
      'mbpop_08':'是否为组件认证',
      'mbpop_09':'最后加工企业的名称和地址',
      'mbpop_10':'请填写选择材料成分所含的百分比',
      'mbpop_11':'产出的成品数量',
      'mbpop_12':'以销售单位计算',
      'mbpop_13':'含产品附件但不含包材(kgs)',
      'mbpop_14':'成品所含附属材料重量(kg)',
      'mbpop_15':'未计入到产品成分组成的非认证附件',
      'mbpop_16':'和装饰辅料的总重量(不含包材)',
      'mbpop_17':'a.未计入成分计算的附件和装饰辅料明细。b.如果此产品也已包含在其它TE标准的TC上了，须主明相关信息如(Also OCs certified.See OCS TC XXoXXX0)c.对于纤维，须注明纤维长度和适用的纤维细度,d.对于纱线.须注明纤线支数。e.对于面料，须注明面料的克重和结构(即每英寸的经密和纬密，纱支数)f.对于成衣或成品的纺织产品，所有含有认成材料的面料的克至GSM。',
      'mbpop_18':'填写投入的认证原料信息',
      'mbpop_19':'请根据实际情况添加单个/多个实际投入的认证原料信息',
      'mbpop_20':'添加投入原料',
      'mbpop_21':'总量平衡验证',
      'mbpop_22':'请选择认证原料库存中的产品，并填写本批次投入的原料净重与生产损耗',
      'mbpop_23':'添加投入的TC原料信息',
      'mbpop_24':'转化到成品中的再生材料含量',
      'mbpop_25':'转化到成品中的可持续来源材料含量',
      'mbpop_26':'GOTS认证重量',
      'mbpop_27':'再生材料含量',
      'mbpop_28':'最后加工方的持证许可号',
      'mbpop_28_1':'认证机构客户编号',
      'mbpop_29':'最后加工企业的国家/地区',
      'mbpop_30':'可持续来源的材料含量',
    },
    TC_pop:{
      'tcpop_01':'认证信息',
      'tcpop_02':'运输和产品信息',
      'tcpop_03':'完成',
      'tcpop_04':'TC申请认证标准',
      'tcpop_05':'自动生成，无需填写',
      'tcpop_06':'买方公司名称和地址',
      'tcpop_07':'如果选择的买方公司未出现在列表，可点击',
      'tcpop_08':'完成添加后再重新选择',
      'tcpop_09':'收货地址',
      'tcpop_10':'(变更订单号)',
      'tcpop_11':'完成添加后再重新选择',
      'tcpop_12':'完成添加后再重新选择',
      'tcpop_13':'完成添加后再重新选择',
    }
  },
  bill:{
    TC_bill:{
      'tcbill_01':'TC账单',
      'tcbill_02':'TC申请标准',
      'tcbill_03':'付款状态',
      'tcbill_04':'申请方的客户编号',
      'tcbill_05':'申请方的公司名称',
      'tcbill_06':'申请的TC标准',
      'tcbill_07':'申请的TC日期',
      'tcbill_08':'产品个数',
      'tcbill_09':'审批员',
      'tcbill_10':'下载TC',
      'tcbill_11':'账单金额',
      'tcbill_12':'产品个数',
      'tcbill_13':'产品个数',
      'tcbill_14':'产品个数',
    },
    SC_ill:{
      'scbill_01':'扩项账单',
      'scbill_02':'SC认证标准',
      'scbill_03':'申请日期',
      'scbill_04':'SC编号',
      'scbill_05':'扩项申请日期',
      'scbill_06':'增加分包商个数',
      'scbill_07':'申请日期',
      'scbill_08':'申请日期',
    },
    public:{
      'pub_01':'个账单',
      'pub_02':'以入账(钱包扣款)',
      'pub_03':'已入账',
      'pub_04':'待付款',
      'pub_05':'待ETKO确认收款',
      'pub_06':'是否已开发票',
      'pub_07':'开具发票',
      'pub_08':'查看发票',
      'pub_09':'预览水单',
      'pub_10':'发票详情',
      'pub_11':'发票抬头',
      'pub_11_2':'地址',
      'pub_12':'税号',
      'pub_13':'公司电话',
      'pub_14':'开户银行名称',
      'pub_15':'银行账号',
      'pub_16':'发票内容',
      'pub_17':'发票金额',
      'pub_18':'收件人信息',
      'pub_19':'收件人',
      'pub_19_1':'接收发票的电子邮箱',
      'pub_20':'手机号码',
      'pub_21':'收件地址',
      'pub_22':'发票信息',
      'pub_23':'开票状态',
      'pub_24':'待开票',
      'pub_25':'拒绝原因',
      'pub_26':'发票类型',
      'pub_27':'专用发票',
      'pub_28':'已开票',
      'pub_29':'已拒绝',
      'pub_30':'请重新修改后提交',
      'pub_31':'发票编号',
      'pub_32':'发票附件',
      'pub_33':'物流信息',
      'pub_34':'快递单号',
      'pub_35':'物流商家',
      'pub_36':'物流查询',
      'pub_37':'开票申请已退回',
      'pub_38':'下载账单',
      'pub_39':'退回原因：',
      'pub_40':'充值记录',
      'pub_41':'附件',
      'pub_42':'暂无账单',
      'pub_43':'申请方的客户编号',
      'pub_44':'申请方的公司名称',
      'pub_45':'ETKO TC编号',
      'pub_46':'申请的TC标准',
      'pub_47':'申请的TC日期',
      'pub_48':'买方公司名称和地址',
      'pub_49':'总毛重',
      'pub_50':'总净重',
      'pub_51':'总的认证重',
      'pub_52':'产品个数',
      'pub_53':'是否组件认证',
      'pub_54':'审批员',
      'pub_55':'审批时间',
      'pub_56':'账单金额',
      'pub_57':'付款状态',
      'pub_58':'以入账(钱包扣款)',
      'pub_59':'已入账',
      'pub_60':'客户未付款',
      'pub_61':'待ETKO确认收款',
      'pub_62':'TC Bills_exports',
      'pub_63':'TC Bills_exports.xlsx',
      'pub_64':'申请方的客户编号',
      'pub_65':'申请方的公司名称',
      'pub_66':'SC编号',
      'pub_67':'SC认证标准',
      'pub_68':'扩项申请日期',
      'pub_69':'增加分包商个数',
      'pub_70':'账单金额',
      'pub_71':'扩项账单',
      'pub_72':'扩项账单.xlsx',
    }
  },
  userInfor:{
    'user_01':'我的账户信息',
    'user_01_1':'管理子账户',
    'user_01_2':'添加子账户',
    'user_01_3':'密码',
    'user_01_4':'有效',
    'user_01_5':'无效',
    'user_01_6':'是否改变该状态',
    'user_02':'基本设置',
    'user_03':'基本信息',
    'user_04':'联系我们',
    'user_05':'钱包明细',
    'user_06':'主联系人邮箱',
    'user_07':'用户名',
    'user_08':'联系电话',
    'user_09':'我的钱包余额',
    'user_10':'账户安全',
    'user_11':'修改密码',
    'user_12':'用户头像',
    'user_13':'更换头像',
    'user_14':'更新信息',
    'user_15':'原密码',
    'user_16':'新密码',
    'user_17':'确认密码',
    'user_18':'ETKO联系方式',
    'user_19':'联系邮箱',
    'user_20':'跟单员联系方式',
    'user_21':'联系人',
    'user_22':'流水号',
    'user_23':'明细类型',
    'user_24':'交易类型',
    'user_25':'金额',
    'user_26':'余额变化',
    'user_27':'关联的TC编号',
    'user_28':'时间',
  },
  renZhengTwo: {
    'rz_01':'填写扩项认证产品信息：',
    'rz_02':'产品类别',
    'rz_03':'产品细节',
    'rz_04':'产品材料成分',
    'rz_05':'标准(标签等级)',
    'rz_06':'设施编号',
    'rz_07':'操作',
    'rz_08':'保存',
    'rz_09':'取消',
    'rz_10':'编辑',
    'rz_11':'删除',
    'rz_12':'保存成功！',
    'rz_13':'请根据实际情况添加单个/多个认证产品信息',
  },
  application: {
    'ac_01':'下载扩项申请表并签章',
    'ac_02':'保存扩项申请',
    'ac_03':'PDF预览',
    'ac_05':'上传成功',
  },
  gotsInfo: {
    'gi_01':'上传测试报告：',
    'gi_02':'产品上如有附件或辅料，必须提供相关的OEKO-TEX 100证书或根据GOTS2.4.16的测试报告，并提供合规附件/辅料的相关采购证明文件',
    'gi_03':'最后加工方的英文名称：',
    'gi_04':'如果您要申请的成品的产品类别、产品细节及产品成分未在下拉菜单中呈现，您需要首先移步到首页“申请扩项”模块提交扩项申请',
    'gi_05':'未纳入成分计算的辅料明细（如适用）：',
    'gi_06':'该批产品被认证的其他TC号（如适用）：',
    'gi_07':'纤维长度和支数（如适用）：',
    'gi_08':'纱线支数（如适用）：',
    'gi_09':'布料的克重和经纬密度结构（如适用）：',
    'gi_10':'成衣或成品纺织品的布料克重（如适用）：',
    'gi_11':'请正确输入材料成分所含的百分比',
    'gi_12':'使用量超出总量，请重新输入！',
    'gi_13':'请输入最后加工方的英文名称',
  },
  teInfo: {
    'ti_01':'上传测试报告：',
    'ti_02':'当需要时，请上传相应的测试报告',
    'ti_03':'最后加工方的英文名称：',
    'ti_04':'如果您要申请的成品的产品类别、产品细节及产品成分未在下拉菜单中呈现，您需要首先移步到首页“申请扩项”模块提交扩项申请',
    'ti_05':'准确填写完每个认证组件的净重后，将会自动计算',
    'ti_06':'未纳入成分计算的辅料明细（如适用）：',
    'ti_07':'该批产品被认证的其他TC号（如适用）：',
    'ti_08':'纤维长度和支数（如适用）：',
    'ti_09':'纱线支数（如适用）：',
    'ti_10':'布料的克重和经纬密度结构（如适用）：',
    'ti_11':'成衣或成品纺织品的布料克重（如适用）：',
    'ti_12':'填写附加信息',
    'ti_13':' 组件附加信息',
    'ti_14':'组件的认证重量：',
    'ti_15':'请正确输入材料成分所含的百分比',
    'ti_16':'重新读取认证标准',
    'ti_17':'至少有一个认证标准！',
    'ti_18':'最多只能添加六条信息',
    'ti_19':'使用量超出总量，请重新输入！',
    'ti_20':'确认关闭？',
    'ti_21':'请填写并验证合格上一个组件的信息',
    'ti_22':'总量平衡(MB)验证不合格，不可提交。请仔细检查及纠正投入原料的用量、生产损耗值、产出成品净重或附件辅料重量等信息，直到验证结果为\"conformed\"才可提交',
    'ti_23':'比例：',
    'ti_24':'至少要有一条组件认证信息！',
    'ti_25':'请输入最后加工方的英文名称',
    'ti_26':'删除',
    'ti_27':'产品上如有附件或辅料，必须提供相关的OEKO-TEX 100证书或根据GOTS2.4.16的测试报告，并提供合规附件/辅料的相关采购证明文件',
  },
  changePassword: {
    'cp_01':'两次输入的密码不一致，请重新输入',
    'cp_02':'修改成功,请重新登录！',
  },
  walletDetails: {
    'wd_01':'余额',
    'wd_02':'关联的TC或扩项的SC',
    'wd_03':'Income and expenditure details.xlsx',
  },
  personIndex: {
    'pi_01': '用户名',
    'pi_02': '联系人姓名',
    'pi_03': '充值',
    'pi_04': '充值说明(如需要):',
    'pi_05': '充值金额',
    'pi_06': '上传银行水单:',
    'pi_07': '选择文件',
    'pi_08': '查看银行水单',
    'pi_09': '提交充值',
    'pi_10': '取消',
    'pi_11': '请填写金额',
    'pi_12': '请上传银行水单',
    'pi_13': '提交完成，请等待审核.',
    'pi_14': '操作异常',
    'pi_15': '上传成功',
    'pi_16': '修改成功',
  },
  rawmaterialinventoryDetail: {
    'rd_01':'有机原料的认证是否符合USDA NOP：',
    'rd_02':'镇和邮编',
    'rd_03':'原料TC的卖方公司持证许可号：',
    'rd_03_2':'认证机构客户编号：',
    'rd_04':'原料TC的卖方公司SC编号：',
    'rd_05':'原料TC的认证机构：',
    'rd_06':'详情',
    'rd_07':'查看原料附件',
    'rd_08':'原料认证重量',
    'rd_09':'操作',
    'rd_10':'原料产品数量：',
    'rd_11':'生产日期：',
    'rd_12':'原料标准（标签等级）：',
    'rd_13':'GOTS认证重量(kg)：',
    'rd_14':'认证重量(kg)：',
    'rd_15':'提示： 如果此份原料TC上该批原料在登记到本系统前已被使用过一部分(例如从别的机构转剩余库存到ETKO的情况或者先前在ETKO的EXCEL库存表上使用过)，',
    'rd_16':'请在此处填写先前已用掉的总净重；否则，此处填0',
    'rd_17':'原料TC上所列的附加信息：',
    'rd_17_1':'附加信息：',
    'rd_18':'未纳入成分计算的辅料明细（如适用）：',
    'rd_19':'该批产品被认证的其他TC号（如适用）：',
    'rd_20':'纤维长度和支数（如适用）：',
    'rd_21':'纱线支数（如适用）：',
    'rd_22':'布料的克重和经纬密度结构（如适用）：',
    'rd_23':'成衣或成品纺织品的布料克重（如适用）：',
    'rd_23_1':'其它附加信息(如适用)：',
    'rd_24':'公司英文名称',
    'rd_25':'公司英文地址',
    'rd_26':'公司中文名称',
    'rd_27':'公司中文地址',
    'rd_28':'联络人',
    'rd_29':'联络电话',
    'rd_30':'联络邮箱',
    'rd_31':'持证许可号',
    'rd_32':'当前TC已存在，请重新输入！',
    'rd_33':'至少有一个认证标准！',
    'rd_34':'最多只能添加6条认证',
    'rd_35':'确认关闭？',
    'rd_36':'请输入',
    'rd_37':'请选择',
    'rd_38':'选择日期',
    'rd_38_1':'不适用',
    'rd_38_2':'不适用',

    'rd_39':'选择原料TC卖方公司',
    'rd_40':'SC认证标准',
    'rd_41':'原料产品数量：',
    'rd_42':'请输入原料产品数量',
    'rd_43':'生产日期：',
    'rd_44':'选择日期',
    'rd_45':'原料标准（标签等级）：',
    'rd_46':'请选择标签等级',
    'rd_47':'GOTS认证重量(kg)：',
    'rd_48':'认证重量(kg)：',
    'rd_48_1':'回收料声明重量(kg)：',
    'rd_49':'点击查看',
    'rd_50':'少了',
    'rd_51':'相同',
  },
  rawmaterialinventoryIndex:{
    'ri_01': '开始日期',
    'ri_02': '结束日期',
    'ri_03': '至',
    'ri_04': '条库存申请记录',
    'ri_05': '库存',
    'ri_06': '当前库存净重(kg)',
    'ri_07': '库存序号：',
    'ri_08': '条使用记录',
    'ri_09': '序号',
    'ri_10': '原料TC编号',
    'ri_11': '原料TC颁发日期',
    'ri_12': '原料供应商',
    'ri_13': '原料订单或销售合同号',
    'ri_14': '成品TC上的订单号：',
    'ri_15': '原料货号',
    'ri_16': '原料产品类别',
    'ri_17': '原料产品细节',
    'ri_18': '原料产品成分',
    'ri_19': '原料TC上所示净重',
    'ri_19': '原料TC上所示该款原料的净重',
    'ri_20': '附属物重量',
    'ri_21': '认证标准及认证重量',
    'ri_22': '生产前该原料剩余库存净重(kgs)',
    'ri_23': '成品生产日期',
    'ri_24': '产出的成品的订单号或销售合同号',
    'ri_25': '成品的产品类别',
    'ri_26': '成品的产品细节',
    'ri_27': '成品货号',
    'ri_28': '成品的产品成分',
    'ri_29': '该原料的生产投入总量净重(kgs)',
    'ri_30': '原料的该次生产损耗净重',
    'ri_31': '原料有效转化至成品上的认证重量',
    'ri_32': '生产后该原料剩余库存净重(kgs)',
    'ri_33': '成品TC编号(颁发日期)',
    'ri_33_1': '我是标题',
    'ri_34': '成品TC编号：',
    'ri_35': '成品生产日期：',
    'ri_36': '产出的成品的订单号或销售合同号：',
    'ri_37': '成品产品类别：',
    'ri_38': '成品产品细节：',
    'ri_39': '成品产品成分：',
    'ri_40': '该原料的生产投入总量净重(kgs)：',
    'ri_41': '原料的该次生产损耗净重：',
    'ri_42': '原料有效转化至成品上的认证重量：',
    'ri_43': '生产后该原料剩余库存净重(kgs)：',
    'ri_44': '成品TC的颁发日期：',
    'ri_45': '销售后剩余库存成品数量(以销售单位计)：',
    'ri_46': '销售后剩余库存成品总净重(kgs)：',
    'ri_47': '销售后剩余库存成品所含认证成分总量(kgs)：',
    'ri_48': '原料TC编号',
    'ri_49': '原料运输单号',
    'ri_50': '原料供应商',
    'ri_51': '原料TC上排列的产品序号',
    'ri_52': '原料订单或销售合同号',
    'ri_53': '原料货号',
    'ri_54': '原料TC上所示净重',
    'ri_55': '附属物重量',
    'ri_56': '序号',
    'ri_57': '原料TC编号',
    'ri_58': '原料订单号或销售合同号',
    'ri_59': '原料货号',
    'ri_60': '产出的成品订单号或销售合同号',
    'ri_61': '成品TC编号',
    'ri_62': '原料库存附件.zip',
    'ri_63': '操作成功！',
    'ri_64': '原料库存日志列表.xlsx',
    'ri_64_1': '原料库存列表.xlsx',
    'ri_65': '附件',
  },
  newInventory: {
    'ni_01': '编辑原料库存',
    'ni_02': '请先选择类型！',
    'ni_03': '原料库存详情',
  },
  buyer: {
    'buyer_01': '主持证公司英文名称',
    'buyer_02': '主',
    'buyer_03': '认证机构',
    'buyer_04': '保存成功！',
    'buyer_05': '您确定要删除该数据?',
  },
  scCertInfo: {
    'sci_01':'认证信息',
    'sci_02':'认证状态：',
    'sci_03':'主持证公司英文名称',
    'sci_03_1':'主持证公司的TE-ID',
    'sci_04':'主',
    'sci_05':'认证机构',
    'sci_06':'您确定要删除该数据?',
    'sci_07':'SC证书编号',
    'sci_08':'认证机构客户编号',
    'sci_09':'我是标题',
    'sci_10':'所属SC编号：',
    'sci_11':'至',
    'sci_12':'开始日期',
    'sci_13':'结束日期',
    'sci_14':'买方',
    'sci_15':'最后加工方',
    'sci_16':'供应商',
    'sci_17':'联络人：',
    'sci_18':'买家;',
    'sci_19':'供应商;',
    'sci_20':'收货方;',
    'sci_21':'非持证贸易方;',
    'sci_22':'未持有SC证书',
    'sci_23':'SC证书主持证方',
    'sci_24':'SC证书的附属单位',
    'sci_25':'主持证公司英文名称',
    'sci_26':'SC证书附件',
    'sci_26_1':'SC证书附件.zip',
    'sci_27':'预览',
    'sci_28':'买方',
    'sci_29':'供应商',
    'sci_30':'收货方',
    'sci_31':'非持证贸易商',
    'sci_32':'公司英文名称',
    'sci_33':'公司英文地址',
    'sci_34':'公司中文名称',
    'sci_35':'公司中文地址',
    'sci_36':'联络人',
    'sci_37':'联络电话',
    'sci_38':'联络邮箱',
    'sci_39':'持证许可号',
    'sci_40':'供应链单位.xlsx',
    'sci_41':'序号',
    'sci_42':'原料TC编号',
    'sci_43':'原料TC标准',
    'sci_44':'原料TC颁发日期',
    'sci_45':'库存创建时间',
    'sci_46':'审批时间',
    'sci_47':'当前库存',
    'sci_48':'状态',
    'sci_49':'我的认证产品',
    'sci_50':'我的认证产品.xlsx',
  },
  subsidiaryIndex: {
    'si_01':'编辑供应链单位',
    'si_02':'至',
    'si_03':'开始日期',
    'si_04':'结束日期',
    'si_05':'TC申请日期',
    'si_06':'是',
    'si_07':'否',
    'si_08':'不适用',
    'si_09':'使用的原料TC编号',
    'si_10':'已撤证',
    'si_11':'撤证原因：',
    'si_12':'待付款',
    'si_13':'查看TC正本',
    'si_14':'撤回',
    'si_15':'修改',
    'si_16':'上传付款水单',
    'si_17':'重新上传付款水单',
    'si_18':'查看组件认证明细',
    'si_19':'认证产品明细',
    'si_20':'组件明细',
    'si_21':'PDF预览',
    'si_22':'成品TC编号',
    'si_23':'原料TC编号',
    'si_23_1':'成品订单号',
    'si_23_2':'成品货号',
    'si_24':'待审核',
    'si_25':'已退回',
    'si_26':'预审完成',
    'si_27':'待确认草本',
    'si_28':'待出TC正本',
    'si_29':'已出TC正本',
    'si_29_1':'附加信息内容',
    'si_30':'付款水单上传成功',
    'si_31':'确认关闭？',
    'si_32':'您确定要撤回TC申请吗?',
    'si_33':'提示',
    'si_34':'确定',
    'si_35':'取消',
    'si_36':'您已查看并将确认该TC草本吗?',
    'si_37':'上传成功',
    'si_38':'TC草本/正本',
    'si_39':'暂无账单',
    'si_40':'TC申请详情',
    'si_41':'未上传发票文件',
    'si_42':'未上传运输文件',
    'si_43':'查看详情',
    'si_44':'剩余库存成品数量',
    'si_45':'剩余库存总净重(KG)',
    'si_46':'剩余库存成品所含认证成分总量(kgs)',
    'si_47':'删除',
  },
  TC_application: {
    'TCe_01':'申请扩项',
    'TCe_02':'已保存的扩项（',
    'TCe_02_2':'已提交的扩项申请（',
    'TCe_03':'条）:',
    'TCe_04':'申请方公司名称',
    'TCe_05':'SC编号',
    'TCe_06':'SC认证标准',
    'TCe_07':'扩项保存日期',
    'TCe_07_1':'扩项申请日期',
    'TCe_08':'增加独立持证分包商数量',
    'TCe_09':'申请状态',
    'TCe_10':'未提交',
    'TCe_11':'操作',
    'TCe_12':'继续申请',
    'TCe_13':'已提交申请',
    'TCe_14':'初审通过',
    'TCe_15':'扩项完成',
    'TCe_16':'已退回',
    'TCe_17':'操作',
    'TCe_18':'修改',
    'TCe_19':'退回',
    'TCe_20':'扩项申请附件.zip',
    'TCe_21':'下载附件',
    'TCe_22':'买方订单或销售合同(须上传上表所填的所有订单合同的原件)及其它必要补充文件：',
    'TCe_22_1':'如果在最终买家和卖方(贵司)中间有涉及到非认证的贸易商参与中间交易，请同时提供买家下给贸易商的订单(或销售合同)和贸易商下给贵司的订单(或销售合同)',
    'TCe_23':'查看订单及补充文件',
    'TCe_24':'查看订单及补充文件',
    'TCe_25':'查看订单及补充文件',
    'TCe_26':'查看订单及补充文件',
    'TCe_27':'查看订单及补充文件',
    'TCe_28':'分包商的范围证书（适用于独立持证分包商）：',
    'TCe_29':'查看附件',
    'TCe_30':'运送待加工材料到分包方的运输单据及签收记录：',
    'TCe_31':'分包商开具的加工发票：',
    'TCe_32':'分包商运送加工过的产品的运输单据：',
    'TCe_33':'收货方公司名称',
    'TCe_34':'深圳-518000',
    'TCe_35':'广东省',
    'TCe_36':'中国',
    'TCe_37':'加工',
    'TCe_38':'xxxxx企业',
    'TCe_39':'类别说明',
    'TCe_40':'细节说明',
    'TCe_41':'材料成分说明',
    'TCe_42':'附加信息',
    'TCe_43':'您确定已仔细检查了TC草本上的内容并确认无误吗？',
    'TCe_44':'提示',
    'TCe_45':'确定',
    'TCe_46':'取消',
    'TCe_47':'已取消确认',
    'TCe_48':'确认关闭？',
    'TCe_49':'上传成功',
    'TCe_50':'原料TC编号',
    'TCe_51':'原料TC上的产品序号',
    'TCe_52':'产品序号',
    'TCe_53':'MB序号',
    'TCe_54':'产出的产品订单号或销售合同号',
    'TCe_55':'成品货号',
    'TCe_56':'关联成品TC编号',
    'TCe_62':'您确定要撤回MB吗？',
    'TCe_63':'撤回成功',
    'TCe_64':'撤回异常',
    'TCe_65':'附件',
    'TCe_66':'暂无账单',
    'TCe_67':'提示',
    'TCe_68':'检测到有未提交的填写内容，是否继续填写',
    'TCe_69':'取 消',
    'TCe_70':'确 定',
  },
  'switching_languages_succeeded':'切换多语言成功',
  'please_select_product_category':'请选择产品类别',
  'please_select_product_details':'请选择产品细节',
  'please_select_certification_standard':'请选择产品的认证标准(标签等级)',
  'please_select_location':'请选择产品的场所',
  'save_successfully': '保存成功',
  'proportion: ':'比例：',
  'top_up':'充值',
  'additional_information_content':'附加信息内容',
  'mb_no':'MB序号',
  'order_sn':'产出的产品订单号或销售合同号',
  'product_no':'成品货号',
  'material_tc_no':'关联成品TC编号',
  'return_extension_application':'您确定退回该扩项申请吗?',
  'tips':'提示',
  'confirm':'确定',
  'cancel':'取消',
  'delete_success':'删除成功!',
  'confirm_close':'确认关闭？',
  'addition':'附件',
  'no_bill':'暂无账单',
  'PDF_preview':'PDF预览',
  'component_additional_information':' 组件附加信息',
  'read_authentication_criteria_again':'重新读取认证标准',
  'please_enter_english_name':'请输入最后加工方的英文名称',
  'please_type_in_English': '请使用英文填写',
  'please_firstly_select_the_input_TC_certification_standard' : '请先选择原料TC认证标准',
  'remark': '备注',
  'requireModify': '要求修改',
  'buyerScNumber': '买方的SC证书编号',// Buyer's Scope Certificate number
  'buyerName': '买方公司名称',
  'etko_bank_account_info': 'ETKO银行账户信息',
  'sfjjsq': '是否加急申请',
  'sfjjsq-header': '是否加急申请',
  'sfjjsq-tips': '选择加急申请，将会产生额外的加急费用',

  "shenpi.shenpitc.xzczzs": "预览撤证证书",


  "components.detail.74lq1y": "*有机原料的认证是否符合USDA NOP：",
  "components.detail.072962": "查看原料附件",
    "components.detail.3cy3ox": "*原料TC的卖方公司持证许可号：",
    "components.detail.15y4n0": "*原料TC的卖方公司SC编号：",
    "components.detail.gmcg38": "*原料TC的认证机构：",
    "components.detail.0x5156": "详情",
    "components.detail.da2yth": "原料产品数量：",
    "components.detail.48fx0f": "生产日期：",
    "components.detail.n7n31n": "原料标准（标签等级）：",
    "components.detail.g10oj7": "GOTS认证重量(kg)：",
    "components.detail.v24ddr": "认证重量(kg)：",
    "components.detail.cn3zhy":
"提示： 如果此份原料TC上该批原料在登记到本系统前已被使用过一部分(例如从别的机构转剩余库存到ETKO的情况或者先前在ETKO的EXCEL库存表上使用过)，\r\n                请在此处填写先前已用掉的总净重；否则，此处填0“",
    "components.detail.svj7bg": "原料TC上所列的附加信息：",
    "components.detail.176wve": "未纳入成分计算的辅料明细（如适用）：",
    "components.detail.h6y2h7": "该批产品被认证的其他TC号（如适用）：",
    "components.detail.uikh4p": "纤维长度和支数（如适用）：",
    "components.detail.438pc2": "纱线支数（如适用）：",
    "components.detail.3mcui6": "布料的克重和经纬密度结构（如适用）：",
    "components.detail.m12i6i": "成衣或成品纺织品的布料克重（如适用）：",
    "components.detail.3cck2i": "原料认证重量",
    "components.detail.0514tf": "当前TC已存在，请重新输入！",
    "components.detail.1a2d2f": "少了",
    "components.detail.f23f11": "至少有一个认证标准！",
    "components.detail.12d7h8": "最多只能添加6条认证",
    "components.detail.qw2ny8": "确认关闭？",
    "components.detail.6y6728": "相同",

  "detail.gots-information.5t26ix": "测试报告：",
  "detail.gots-information.8ajb6i": "*最后加工方的英文名称：",
  "detail.gots-information.8g9amx": "这是第二个情况页面",
  "detail.gots-information.6681br": "请正确输入材料成分所含的百分比",
  "detail.gots-information.9rs5yp": "使用量超出总量，请重新输入！",
  "detail.gots-information.3o7113": "相等",
  "detail.gots-information.61d6f6": "总量平衡验证",
  "detail.gots-information.5d3my4": "component-总量平衡验证",
  "detail.gots-information.j9f332": "比例：",
  "detail.mbdetail-dialog.2p3z70": "查看MB详情",
  "detail.te-information.nn88kd": "成品的认证标准",
  "detail.te-information.frw455": "*产品图片及生产记录：",
  "detail.te-information.6xjgts": "上传测试报告：",
  "detail.te-information.7af726": "是否为组件认证：",
  "detail.te-information.matv13": "工序名称：",
  "detail.te-information.261xrb": "最后加工企业的名称和地址：",
  "detail.te-information.m4s6ko": "最后加工方的英文名称：",
  "detail.te-information.3lhz83": "最后加工方的持证许可号：",
  "detail.te-information.o89i2q": "最后加工企业的国家/地区：",
  "detail.te-information.86596g": "产出的产品的订单号或销售合同号：",
  "detail.te-information.4qx7o0": "成品货号：",
  "detail.te-information.60pe2x": "成品的产品类别：",
  "detail.te-information.i8436g": "成品的产品细节：",
  "detail.te-information.1wn57w": "成品的产品成分：",
  "detail.te-information.t64r30": "标准(标签等级)：",
  "detail.te-information.3dqg6q": "销售单位：",
  "detail.te-information.4lhqm5": "组件的认证重量：",
  "detail.te-information.k5q746": "组件附加信息",
  "detail.te-information.v3ll1e": "投入的TC原料信息",
  "detail.te-information.275fwr": "重新读取认证标准",
  "detail.te-information.sb4ubf": "最多只能添加六条信息",
  "detail.te-information.77xv56": "请填写并验证合格上一个组件的信息",
  "detail.te-information.s1d505": "添加投入原料",
  "detail.te-information.y75s6p":
      "总量平衡(MB)验证不合格，不可提交。请仔细检查及纠正投入原料的用量、生产损耗值、产出成品净重或附件辅料重量等信息，直到验证结果为confirm才可提交",
  "detail.te-information.m2354g": "才可提交",
  "detail.te-information.5j272r": "至少要有一条组件认证信息！",

  "operation.sales-unit.lon717": "+ 新建",
  "operation.sales-unit.dkv5jk": "*销售单位名称：",
  "operation.sales-unit.jzj1h8": "*是否显示：",
  "operation.sales-unit.16qx69": "状态 :",
  "operation.sales-unit.kunw4m": "开启/关闭",
  "operation.sales-unit.8d8wd9": "开启",
  "operation.sales-unit.6t89w8": "关闭",
  "operation.sales-unit.4a1zq8": "销售单位名称",
  "operation.sales-unit.709588": "创建时间",
  "operation.sales-unit.mkniku": "显示状态",
  "operation.sales-unit.7tsa85": "编辑销售单位",
  "operation.sales-unit.y14ru9": "请输入排序值",
  "operation.sales-unit.1l4rer": "开启后用户申请总量平衡(MB)时将可选此单位",
  "operation.sales-unit.2dfk58": "是否确定删除",

  "listall.zongpingheng.1k4464": "TC申请管理和批复页面",
  "listall.zongpingheng.987ga7": "TC关联的总量平衡MB列表",
  "listall.zongpingheng.u4byc8": "查看详情",
  "listall.zongpingheng.qq4fyz": "预览 |",
  "listall.zongpingheng.d82jfc": "批准",
  "listall.zongpingheng.qd80x3": "退回",
  "listall.zongpingheng.uf3q17": "MB序号",
  "listall.zongpingheng.uor80g": "生产日期",
  "listall.zongpingheng.xnh2zp": "工序名称",
  "listall.zongpingheng.l7hk31": "最后加工企业的名称和地址",
  "listall.zongpingheng.vxriqn": "产出的产品的订单号或销售合同号",
  "listall.zongpingheng.0d80t0": "成品产品类别",
  "listall.zongpingheng.u9u61y": "成品产品细节",
  "listall.zongpingheng.c22wxh": "成品产品成分",
  "listall.zongpingheng.kp71xo": "销售单位",
  "listall.zongpingheng.861onw": "产出的成品数量(以销售单位计算)",
  "listall.zongpingheng.xohsa6": "成品总净重(含产品附件但不包含材料)(kgs)",
  "listall.zongpingheng.287484": "成品所含附属材料重量(kg)",
  "listall.zongpingheng.biwa03": "附加信息",
  "listall.zongpingheng.69dh43": "组件认证",
  "listall.zongpingheng.7goxu8": "使用的原料TC信息",
  "listall.zongpingheng.k5rn2z": "查产品规格表",
  "listall.zongpingheng.32264h": "总量平衡验证结果",
  "listall.zongpingheng.lp77w6": "EYKO审批状态",
  "listall.zongpingheng.7wlqn5": "申请的成品TC编号",
  "listall.zongpingheng.21a36g": "剩余库存成品数量(以销售单位计)",
  "listall.zongpingheng.lpjhkj": "剩余库存成品净重(kgs)",
  "listall.zongpingheng.bmh110": "剩余库存所含认证成分总量(kgs)",

  'tcxxhdb': 'TC信息核对表',
  'other': '其它',


  'save_to_draft': "保存到草稿",
  'delete_draft_tips': '您确定要删除该草稿吗',
  'continue_to_fill': '继续申请',
  'saved_time': '保存时间',
  'yltcbz': '原料TC标准',
  'unsubmitted': '未提交',
  'unsubmitted_input_stocks': '未提交的原料库存',
  'other_remark' : '其他备注',
  'other_remark_1' : '修改备注',
  'unsubmitted_mass_balance': '未提交的总量平衡(MB)',
  'unsubmitted_tc_applications': '未提交的TC申请',
  'notes_to_the_tc_reviewer': '给审核员的留言',

  'import_supply_chain_companies': '导入供应链单位',
  'importing_template':'导入模板',
  'download_template': '下载模板',
  'upload_importing_file': '上传导入文件',
  'confirm_to_import': '确认导入',
  "shenpi.shnepikc.48184g_4": "已被手动扣减库存",
  "operator": "操作人",
  "email_is_error": "邮箱格式错误",
}
