import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'


// 多语言配置
import i18n from '@/language/lang'
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// VXETable
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable, {
  // 对组件内置的提示语进行国际化翻译
  i18n: (key, args) => i18n.t(key, args)
})

// 导出Excel
import XLSX from 'xlsx'
Vue.prototype.XLSX = XLSX //挂载到全局方法方便调用
// axios
import request from '@/axios/request'
Vue.prototype.$axios = request

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
