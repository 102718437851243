// 导入 Vue
import Vue from 'vue'

// 导入 vue-i18n 插件
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale' // 导入 Element 语言配置插件
import enLocale from 'element-ui/lib/locale/lang/en' // 导入 Element 英文包
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' // 导入 Element 中文包
import tkLocale from 'element-ui/lib/locale/lang/tk' // 导入 Element 中文包

const cn = require('./Chinese') //中文对应
const en =  require('./english')//英文对应
const tk =  require('./tk')//英文对应



Vue.use(VueI18n)
var language = localStorage.getItem('etko-lang') || 'en'
console.log(language)
// 创建国际化插件的实例

const i18n = new VueI18n({
  locale: language, // 设置地区，zh 表示中国地区
  // 设置地区信息
  messages: {
    'zh': {
      ...zhCN,...zhLocale,...cn
    },
    'en': {
      ...enUS,...enLocale,...en
    },
    'tk': {
      ...enUS,...enLocale,...tk
    }
  }
})

// 配置 Element 语言转换关系
locale.i18n((key, value) => i18n.t(key, value))
// Vue.use(ElementUI, {
//   i18n: (key, value) => i18n.t(key, value)
// })

export default i18n