module.exports = {
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
  startTime: 'Start Date',
  to: 'to',
  endTime: 'End Date',
  invoiceStatus: 'Tax Invoice Status',
  all: 'All',
  importantNotice: 'Notice',
  fileColumn: 'File',
  updateTime: 'Update Time',
  downloadFile: 'Download',
  invoiced: 'Invoice Issued',
  uninvoiced: 'Not yet applied',
  invoiceWaiting: 'Waiting for ETKO to check the invoice information',
  downloadBill: 'Download Proforma',
  tcCertApplyPrice: 'TC Application Fee',
  applyInvoice: 'Request Tax Invoice',
  uploadVoucher: 'Upload Bank Slip',
  reUploadVoucher: 'Re-upload Bank Slip',
  payVoucherUploadSuccess: 'Bank Slip Uploaded',
  viewBankVoucher: 'View Bank Slip',
  deductedFromAccountBalance: 'Deducted from Account Balance',
  payStatus: 'Payment Status',
  waitPay:'Not Paid',
  paid: 'Payment Received',
  waitETKOConfirm:'Waiting for ETKO to confirm the receipt',
  applyExtensionPrice: 'Fees for Scope Extension Application',
  extensionApplyTcNumber: 'Scope Certificate No. applied for Extension',
  selectDate: 'Select Date',
  reUpload: 'Re-upload',
  otherOrganizations: 'Other Certification Body',
  uploadSuccessPleaseSave: 'Uploaded successfully, please save',
  uploadSuccess: 'Uploaded successfully',
  uploadInvoiceAndRecord: 'Upload Invoice and Bank Receipts',
  uploadTransportationDoc: 'Upload Shipment Documents',
  uploadOrder:'Upload Order sheet and supplementary documents',
  uploadAppendix:'Upload Attachments',
  uploadTestReport:'Upload Lab Testing Reports：',
  checkOrder:'View Order sheet and supplementary documents',
  checkAppendix:'View Attachment',
  checkTransportationDoc: 'View Shipment Documents',
  checkInvoiceAndRecord: 'View Invoice and Bank Receipts',
  buyerOrderOrContract:'Buyer Order or Sales Contract (Upload the original copies of all the Order Numbers filled in the above table) and other needed supplementary documents:',
  pleaseUploadContract_file: 'Upload the contract signed with the subcontractor',
  pleaseUploadSc_file: 'Upload the independently certified subcontractor\'s scope certificate',
  pleaseUploadUnit_no: 'Input the independently certified subcontractor\'s licence No.',
  pleaseUploadUnit_no_1: 'Input the subcontractor\'s CB Client No. or GOTS License No.',
  pleaseInputUnit_name: 'Input the company name of the independently certified subcontractor',
  pleaseInputPercent: 'Input correct material composition %',
  pleaseInputUnit_address: 'Input the subcontractor\'s address',
  pleaseInputStep: 'Input the process types',
  pleaseInputEnglishNameOfFinalParty: 'Input last processor',
  pleaseSelectCountry: 'Please select country/region',
  pleaseInputDistrict: 'Please input town name',
  pleaseInputZipcode: 'Please input the postcode',
  pleaseSelectStandard: 'Select Certification Standard',
  pleaseSelectCert_body: 'Select the certification body of the independently certified subcontractor',
  pleaseSelectCert_sub_expire: 'Select the expiry date of the independently certified subcontractor\'s scope certificate',
  pleaseFillInAsRequired: 'Please correctly fill in as required',
  pleaseUploadOneOrder: 'Please upload the original copies of all the Order Numbers filled in the above table',
  pleaseUploadTransportationDoc: 'Uploade transport documents and delivery notes of sending unprocessed products to subcontractor',
  pleaseUploadProcessingInvoice: 'Upload subcontractor invoice',
  pleaseInput: 'Please input',
  pleaseSelectType: 'Please select a type',
  pleaseFillInformation: ' Please fill in at least one of the additional info boxes',
  pleaseFillAndCheck: 'Please make the mass balance "conformed" for the last certified component first',
  fillInformation: ' Please fill in additional info',
  extensionApplicationStatus: ' Scope Certificate Extension Application Status',
  rechargeDetails: ' Recharge Details',
  rechargeAmount: 'Recharging Amount',
  rechargeDescription: 'Recharging Description',
  recharge_record: ' Recharging Records',
  serialNumber: ' Running No.',
  bankSlip: ' Bank Slip',
  submissionTime: ' Submission Time',
  typeofInvoice: 'Invoice Type：',
  VAT_electronic_ticket: 'VAT General Invoice_electronic',
  VAT_special_electronic_ticket: 'VAT Special Invoice_electronic',
  VAT_electronic_special_invoice: 'VAT Special Invoice_electronic',
  Paper_VAT_special_invoice: 'VAT Special Invoice_Paper',
  noSCPleaseSelectAnother: 'Related scope certificate is not present, please select other standard',
  ExceedPleaseRefill: 'The quantity you inputted exceeds the total available quantity; please fill in the correct quantity',
  whenNeedPleaseUploadTestReport: 'Please upload the lab testing reports as needed',
  ifNotDisplayPleaseSubmit: 'If the Product Category, Product Detail and Material Composition that you are planning to apply TC for are not available in the drop-down list, please go to the "Scope Certificate Extension Application" moudle at the Homepage to submit your application for scope certificate extension first.' ,
  ifNonTradersPleaseProvideInvoice: 'If there is a non-certified trader involved in the transaction between the buyer and seller (your company), please provide the related invoice between the buyer and the non-certified trader and as well as the invoice between the non-certified trader and seller (your company) ',
  subcontractorScopeCertificate:'Independently certified subcontractor\'s scope certificate：',
  subcontractorInvoice:'Subcontractor invoice：',
  subcontractorTransportDoc:'Transport documents of sending processed products from subcontractor：',
  transToSubcontractorDoc:'Transport documents and delivery notes of sending unprocessed products to subcontractor：',
  calculateAffterFill:'It will be automatically calculated after you correctly fill in the net weight of certified component(s) below when you perform mass balance',
  productNumNeedSubtract:'The product quantity shall not exceed the total available quantity',
  weightOfComponent:'Certified weight in the component：',
  atLeastOneCertificationStandard: 'Please select at least one certification standard',
  englishNameOfFinalParty:'Last processor\'s English company name',
  UpToSixCertificationStandard: 'Maximum 6 standards',
  dataError: '!Data Error：Shipment No. ',
  weightErrorPleaseCorrect: 'The total net shipping weight shall not exceed the gross weight of this shipment. Please double check and correct it',
  saveSuccess: 'Successfully Saved',
  audit:'Pending for review',
  returned:'Rejected',
  ratify:'Approved',
  areyouwanttoreturned: 'Are you sure to revoke this Mass Balance record？',
  returnSuccess: 'Successfully Revoked',
  returnwarn: 'Error in Revoking',
  closeConfirm:'Confirm to close？',
  appendx: 'Attachment',
  noBill: 'No bill yet',
  invoiceApplicationReturned: 'Invoice Request was Rejected',
  consignee: 'Consignee',
  applyStatus: 'Approval Status',
  rawMaterialStatusPending: 'Pending for Review',
  rawMaterialStatusReturned: 'Rejected',
  rawMaterialStatusPass: 'Approved',
  recharge:{
    serialSn: 'Running No.',
    money: 'Amount to recharge',
    remark: 'Additional notes for this recharging',
    bankVoucher: 'Bank slip',
  },
  auditStatus: 'Approval Status',
  returnReason: 'Reason for rejection',
  submitTime: 'Submission time',
  etkoTcNumber: 'ETKO TC Number',
  dlczfbsdczxkh: 'Independently certified subcontractor\'s Licence No.',
  dlczfbsdczxkh_1: 'Subcontractor TE-ID',
  dlczfbsdczxkh_2: 'Subcontractor\'s GOTS License No.',
  dlczfbsdczxkh_3: 'Subcontractor\'s CB Client No.',
  fbsdgsmc: 'Subcontractor\'s name',
  qsrczbz: 'Please input the process types',
  qsrczbzTs: 'Please copy the processes specified in the "Process Categories" box under the facility appendix of the subcontractor\'s scope certificate. Use ";" (semicolon) to separate different processes if there are more than 1 process',
  clhlzbbhf: 'Error in material composition percentage. Please re-select it',
  dqcplhlzbym: 'The material composition percentage is already 100%',
  qsrzjmc: 'Please component detail (i.e. component name) of the certified component',
  warning: 'Warning',
  materialMismatchWarningFirst: 'The composition ',
  materialMismatchWarningTips: ' specified in input TC is not present in your claimed composition of the finished products. Please put your explanations about it for ETKO\'s manual verification.',
  entryReason: 'Please fill in your explanations',
  refresh_success: 'Refreshed successfully',
  placeholder:{
    'p1':'Select type',
    'p2':'Input keywords to search',
    'p3':'Select supply chain type',
    'p4':'Select certification standard',
    'p5':'Please select',
    'p6':'Input company\'s English name',
    'p7':'Input company\'s English address',
    'p8':'Input company\'s legal name in local language',
    'p9':'Input company\'s legal address in local language',
    'p10':'Select country/region where the company is located',
    'p11':'Select state/province where the company is located',
    'p12':'Town',
    'p13':'Input contact name',
    'p14':'Input contact phone number',
    'p15':'Input contact email',
    'p16':'Input licence number',
    'p17':'Input scope certificate number',
    'p18':'Select product category',
    'p19':'Select product detail',
    'p20':'Select product detail',
    'p21':'Input number',
    'p22':'Select certification standard of finished products',
    'p23':'Select whether it\'s component claim',
    'p24':'Select approval status',
    'p25':'Select date',
    'p26':'Select process types involved in the production and storage of the product',
    'p27':'Select last processor',
    'p28':'Input output product order No.',
    'p29':'Input output product article No.',
    'p30':'Select selling unit',
    'p31':'Input output product No. of units in selling unit',
    'p32':'Input output product Net Weight (Kgs)',
    'p33':'Input output product Supplementary Weight (kgs)',
    'p34':'Please fill in',
    'p35':'Select Standard (Label Grade)',
    'p36':'Select seller\'s company name',
    'p37':'Select non-certified trader if applicable',
    'p38':'Select buyer\'s company name',
    'p39':'Consignee address in English',
    'p40':'Select certification standard of input TC',
    'p41':'Select certification standard of scope certificate',
    'p42':'Please fill in',
    'p43':'Input TC number',
    'p44':'；Please copy the corresponding information directly from the input TC',
    'p45':'Input GOTS certified weight',
    'p46':'Input the last processor\'s licence number',
    'p47':'Input last processor\'s country/region',
    'p48':'Postcode',
    'p49':'Select material attribute',
    'p50':'Select material name',
    'p51':'Input detailed description',
    'p52':'Postcode',
    'p53':'Input detailed description of the process',
    'p54':'Upload invoice and transportation documents',
    'p55':'Upload file package',
  },
  serch:{
    's1':'Supply chain type',
    's2':'Certification Standard',
    's3':'Search',
    's4':'Reset',
    's5':'Scope certificate expiry date',
    's6':'Certified subsequent site?',
  },
  Table_Operation:{
    'delete':'Delete',
    'edit':'Edit',
    'preservation':'Save',
    'upload':'Upload',
    'see':'View',
    'Download-contract-template':'Download Subcontracted Processor Agreement template',
    'add_rows':'Add a row',
    'Payment_voucher':'Payment bank slip',
    'preview':'Preview',
    'download':'Download',
    'Upload_attachments':'Upload attachment',
    'Re_upload':'Re-upload',
    'contract_template':'Template_Subcontracted Processor Agreement.pdf',
  },
  table_state:{
    'Approval_Status':'ETKO Approval Status',
    'reviewed':'Pending for review',
    'Approved':'Approved',
    'Returned':'Rejected',
    'record':'Record(s)',
    'confirmed':'Waiting for client to confirm TC draft',
    'be_issued':'Waiting for TC release',
    'be_issued_sure':'TC Issued',
    'review_completed':'Pre-review Completed',
  },
  Public_btn:{
    'return':'Return',
    'back':'Back',
    'next':'Next',
    'Previous-step':'Previous',
    'Continue_adding':'Continue adding',
    'modify':'Modify',
    'details':'Details',
    'Upload_files':'Upload file',
    'Upload_files_click':'Click to upload',
    'Support_extension':'File Extensions Supported：.pdf .jpg...',
    'Export-to-Excel':'Export Excel',
    'cancel':'Cancel',
    'Submit':'Submit',
    'Stow':'Hide',
    'open':'Unhide',
    'buyer':'Buyer',
    'Ship':'Consignee',
    'Final_processing_party':'Last processor',
    'Non_licensed_traders':'Non-certified trader',
    'supplier':'Supplier',
    'see':'View',
    'add_push':'Add',
    'Import_add':'Import',
    'Reselect':'Re-select',
    'determine':'Confirm',
    'Confirm_modification':'Confirm modification',
    'copy_mb':'Duplicate MB',
  },
  Headers: {
    'Headers_t1': 'Home',
    'Headers_t2': 'Sub-units and Supply Chain',
    'Headers_t3': 'Products',
    'Headers_t4': 'Input Stock',
    'Headers_t5': 'Application Status Management',
    'Headers_t6': 'Billing and Payment Information',
    'Account':'My Profile',
    'sign_out':'Sing out',
    'extension':'Apply for Scope Certificate Extension',
    'push_inventory':'Register Input Stock',
    'herbaceous_sure':'Confirm TC Draft',
    'view_tc_dart':'View TC Draft',
    'Certification_Details':'TC Application Details',
    'push_mb':'Add Mass Balance',
    'Headers_t7':'Modify Mass Balance Info',
    'Headers_t8':'Save and Submit',
    'Headers_t9':'Please select a type first!',
    'Headers_t10':' Please fill in at least one of the boxes under the "additional info" section.',
  },
  home:{
    'text_01':'Enjoy your everyday!',
    'text_02':'Certified products',
    'text_03':'Input stocks',
    'text_04':'TC applications',
    'text_05':'Frequently used function',
    'text_06':'Add supply chain companies',
    'text_07':'Register Input Stock',
    'text_08':'Create Mass Balance Records',
    'text_09':'Apply for TC',
    'text_10':'Apply for Scope Certificate Extension',
    'text_11':'Unread messages',
    'text_12':'Read messages',
    'text_13':'Apply for Scope Certificate Extension',
    'text_14':'Apply for Scope Certificate Extension',
  },
  Extension:{
    'Extension_01':'Select a type',
    'Extension_02':'Select whether you need to add independently certified subcontractor(s)',
    'Extension_yes':'Yes',
    'Extension_no':'No',
    'Extension_03':'Enter information for scope certificate extension application',
    'Extension_04':'Select the scope certificate to apply for scope extension for',
    'Extension_05':'Certification standard',
    'Extension_06':'Number',
    'Extension_07':'Scope Certificate expiry date',
    'Extension_09':'Enter information of independently certified subcontractor(s) to be added',
    'Extension_10':'Add independently certified subcontractor(s) as needed',
    'Extension_11':'Licence No.',
    'Extension_63':'Sub-unit Address',
    'Extension_12':'Subcontractor\'s name',
    'Extension_13':'Subcontractor\'s Address',
    'Extension_14':'Process Categories',
    'Extension_15':'Sub-unit type',
    'Extension_16':'Country/Region',
    'Extension_17':'State/Province',
    'Extension_18':'Town',
    'Extension_19':'Postcode',
    'Extension_20':'Certification Standard',
    'Extension_21':'Contact name',
    'Extension_22':'Contact Phone No.',
    'Extension_23':'Contact Email',
    'Extension_23_1':'Business Licence No.',
    'Extension_23_2':'Number of Employees',
    'Extension_24':'Independently Certified Subcontractor\'s Certification Body',
    'Extension_25':'Scope Certificate Expiry Date of Independently Certified Subcontractor',
    'Extension_26':'Independently Certified Subcontractor\'s Scope Certificate',
    'Extension_27':'Singed Subcontracted Processor Agreement',
    'Extension_28':'Operation',
    'Extension_29':'Independently Certified Subcontractor',
    'Extension_30':'Enter certified product information',
    'Extension_31':'Add certified product(s) as needed',
    'Extension_32':'Product Category',
    'Extension_33':'Product Detail',
    'Extension_34':'Material Composition',
    'Extension_35':'Standard (Label Grade)',
    'Extension_36':'Facility No.',
    'Extension_37':'Add more certified product(s)',
    'Extension_38':'Add certified produt',
    'Extension_39':'Facilities involved production',
    'Extension_40':'Select whether it\'s a component claim',
    'Extension_41':'Standard (Label Grade)',
    'Extension_42':'Certified Component',
    'Extension_43':'Component Description',
    'Extension_44':'Component Standard (Label Grade)',
    'Extension_45':'Component Material Composition',
    'Extension_46':'Material attribute',
    'Extension_47':'Material name',
    'Extension_48':'Material content percentage',
    'Extension_49':'Add more certified component(s)',
    'Extension_50':'Upload signed application form and submit',
    'Extension_51':'Download application form to sign',
    'Extension_52':'Fees required to pay',
    'Extension_53':'Signed scope certificate extension application form',
    'Extension_54':'Payment information',
    'Extension_55':'Please arrange bank transfer asap after submitting the application, and go to the "Billing and Payment Information" interface to find the corresponding bill and upload the bank slip',
    'Extension_56':'User Specific Term',
    'Extension_57':'Material Composition',
    'Extension_58':'Enter material composition information as needed',
    'Extension_59':'Select independently certified subcontractor\'s certification body',
    'Extension_60':'Subcontractor\'s information has not yet been saved. Please save it prior to moving next step.',
    'Extension_61':'Select the scope certificate to apply for scope extension for',
    'Extension_62':'You checked "Yes" to the question of "select whether you need to add independently certified subcontractor(s)", but you haven\'t yet added any subcontractors.',
  },
  Login:{
    'login_01': 'System',
    'login_02': 'Welcome to login',
    'login_03': 'Forget passwords',
    'login_04': 'Please enter your user account',
    'login_05': 'Please enter your passwords',
    'login_06': 'Login',
    'login_07': 'Reset passwords',
    'login_08': 'Send verification code',
    'login_09': 'In case you encounter a problem to reset your passwords through your email',
    'login_10': 'please send an email to',
    'login_11': 'Please enter your login email account',
    'login_12': 'Forget user account',
    'login_13': 'Enter your 8-digitals licence number in order to find out your user account',
    'login_14': 'Seek for assisstance',
    'login_15': 'Retrieve login account',
    'login_16': 'Your login account is',
    'login_17': 'Enter new passwords',
    'login_18': 'Double confirm your new passwords',
    'login_19': 'The two passwords you typed did not match',
    'login_20': 'The link to reset your password has been sent to your username email address. Please click the link you received in your email box to reset your passwords',
    'login_21': 'Login successfully！',
  },
  Detachment:{
    'detachment_01':'My company and sub-units',
    'detachment_02':'My supply chain companies',
    'detachment_03':'My Company Informaion',
    'detachment_04':'English Company Name',
    'detachment_05':'English Address',
    'detachment_06':'Company legal name in local language',
    'detachment_07':'Company legal address in local language',
    'detachment_08':'Business Licence No.',
    'detachment_09':'ETKO Customer Service',
    'detachment_10':'Certification Status',
    'detachment_101':'Brand Name(s) owned',
    'detachment_102':'Number of Employees',
    'detachment_11':'Most Updated Scope Certificate',
    'detachment_12':'Scope Certificate No.',
    'detachment_13':'Sub-units listed in the scope certificate(s)',
    'detachment_14':'No.',
    'detachment_15':'Sub-unit name',
    'detachment_16':'Sub-unit details',
    'detachment_17':'Basic Info',
    'detachment_18':'Contact info',
    'detachment_19':'Address',
    'detachment_20':'Country/Region',
    'detachment_21':'Supply Chain Companies',
    'detachment_22':'Total',
    'detachment_23':'Add a supply chain company',
    'detachment_24':'supply chain companies',
    'detachment_25':'Company legal name in local language',
    'detachment_26':'Licence number',
    'detachment_26_4':'Licence No. for GOTS or CB Client No. for TE Scope(s)',
    'detachment_26_3':'Licence No. or CB Client No.',
    'detachment_26_2':'Licence No. for GOTS or CB Client No. for TE Scope(s)',
    'detachment_53':'Main Licensee\'s Licence No.',
    'detachment_53_1':'GOTS License No.',
    'detachment_53_2':'CB Client No.',
    'detachment_27':'Scope Certificate Standard',
    'detachment_28':'Scope Certificate number',
    'detachment_29':'Scope Certificate expiry date',
    'detachment_30':'Scope Certificate Original',
    'detachment_31':'Supply chain company details',
    'detachment_32':'Supply chain company basic info',
    'detachment_33':'Company legal address in local language',
    'detachment_34':'Town',
    'detachment_35':'Supply chain company certification status',
    'detachment_36':'Buyer\'s certification status',
    'detachment_37':'Upload scope certificate',
    'detachment_38':'Main licensee\'s English name',
    'detachment_39':'Main licensee\'s licence number',
    'detachment_40':'Scope Certificate Standard',
    'detachment_41':'Scope Certificate expiry date',
    'detachment_42':'English Address',
    'detachment_43':'Supply Chain Information',
    'detachment_44':'Additional Information',
    'detachment_45':'Buyer\'s certification status',
    'detachment_46':'Non-certified',
    'detachment_47':'Main Licensee of Scope Certificate',
    'detachment_48':'Subsequent Site in Scope Certificate',
    'detachment_49':'No need to fill in',
    'detachment_50':'If the supply chain company holds multiple scope certificates, please add the certification information for other scope certificates',
    'detachment_51':'Supplier\'s Certification Status',
    'detachment_52':'Postcode',
  },
  MyCertifiedProducts:{
    'product_01':'My Products',
    'product_02':'listed products',
  },
  Rawmaterialinventory:{
    'home_01':'My Input Stock',
    'home_02':'Input Stock',
    'home_03':'Input TC Certification Standard',
    'home_05':'Input TC Issue Date',
    'home_06':'Input Stock Registration Date',
    'home_07':'Last Review Time',
    'home_08':'Add Input Stock',
    'home_09':'Input TC NO.',
    'home_09_1':'Identification No. of the Reclaimed Waste Declaration Form',
    'home_10':'Remaining Input Stock Balance',
    'home_11':'Status',
    'home_12':'Input Stock Details',
    'home_13':'Input material Net Weight Stock Balance',
    'home_14':'View Attachment',
    'home_15':'Approval Status',
    'home_16':'Input Material Information',
    'home_17':'Input Material Purchase Invoice',
    'home_18':'Input Material Shipment Doc No.',
    'home_19':'Input Material Shipment Date',
    'home_20':'Input Material Supplier',
    'home_21':'Product No. indicated on Input TC',
    'home_22':'Input Material Order No.',
    'home_23':'Input Material Article No.',
    'home_24':'Net Weight Indicated on Input TC',
    'home_24_1':'Reclaimed Material Net Weight',
    'home_25':'Input Product Category',
    'home_26':'Input Product Detail',
    'home_27':'Input Material Composition',
    'home_28':'Input Material Certified Weight Indicated on Input TC',
    'home_29':'GOTS Other Info',
    'home_30':'Input Inventory Consumption Records',
    'home_31':'Output Product Production Date',
    'home_32':'Input TC Issue Date',
    'home_33':'Input material Net Weight Stock Balance before production(kgs)',
    'home_34':'Used Net Weight of Input Material for this lot (kgs)',
    'home_35':'Production loss (kgs)',
    'home_36':'Certified weight Converted into output products(kgs)',
    'home_37':'Input material Net Weight Stock Balance after production (kgs)',
    'home_38':'Input Stock Consumption Details',
    'home_39':'Net Weight Indicated on Input TC',
    'home_40':'Supplementary weight Indicated on Input TC',
    'home_41':'Output TC No. ',
    'home_42':'Output Product Production Date',
    'home_43':'Input TC Issue Date',
    'home_44':'Output Product Order No. ',
    'home_45':'Output Product Category',
    'home_46':'Output Product Details',
    'home_47':'Output Product Composition',
    'home_48':'Used Net Weight of Input Material for this lot (kgs)',
    'home_49':'Output TC Issue Date',
    'home_50':'Output product remaining stock quantity in selling unit after sales',
    'home_51':'Total Net Weight of output product remaining stock after sales (Kgs)',
    'home_52':'Total Certified Weight in output product remaining stock after sales (Kgs)',
    'home_53':'Used Net Weight before registration in the ETKO TC System ',
    'home_54':'Input TC No.',
    'home_55':'Product No.',
    'home_56':'Product No. indicated on Input TC',
    'home_57':'Close',
  },
  New_inventory:{
    'inventory_01':'Raw material type',
    'inventory_02':'Fill in raw material info',
    'inventory_03':'Completed',
    'inventory_04':'Please select the scheme owner of the raw material certification standard(s)',
    'inventory_05':'Type I',
    'inventory_06':'TE\'s standards include GRS, RCS, OCS, RDS, RWS, RMS & RAS.',
    'inventory_07':'Type II',
    'inventory_08':'The Global Organic Textile Standard was developed and published by the GOTS International Working Group IWG, which consists of the International Natural Textiles Association (IVN, Germany), the Organic Cotton Association of Japan (JOCA), the Organic Trade Association of the United States (OTA) and the Soil Association of the United Kingdom (SA)',
    'inventory_09':'Was the input TC issued by ETKO?',
    'inventory_09_1':'Is your company a first processor?',
    'inventory_10':'Input TC Number',
    'inventory_11':'Seller\'s company name',
    'inventory_12':'Input TC Info',
    'inventory_13':'Input TC certification standard',
    'inventory_14':'View TC attachment',
    'inventory_15':'Input TC seller\'s info',
    'inventory_16':'Seller\'s company English name',
    'inventory_17':'Seller\'s company English address',
    'inventory_18':'Key Shipment Info listed on the Input TC',
    'inventory_18_1':'Reclaimed Waste Shipment Information',
    'inventory_19':'Shipment No.',
    'inventory_20':'Shipment Date',
    'inventory_21':'Shipment Document No.',
    'inventory_22':'Invoice No.',
    'inventory_23':'Gross Shipping Weight',
    'inventory_24':'Attachments',
    'inventory_25':'Input Material Shipment Documents',
    'inventory_26':'Input Material Purchase Invoice and Bank Transaction Records',
    'inventory_27':'Product Info listed on the Input TC',
    'inventory_27_1':'Reclaimed Material Information',
    'inventory_28':'Product No.',
    'inventory_29':'Used Net Weight before registration in this system',
    'inventory_30':'Input TC and Input material testing reports',
    'inventory_30_1':'Reclaimed Waste Declaration Form and Supplier Agreement',
    'inventory_31':'Upload product pictures and production records',
    'inventory_31_2':'Upload Input TC and required testing reports',// 修正
    'inventory_31_3':'Upload Reclaimed Waste Declaration Form and Supplier Agreement',// 修正
    'inventory_32':'Seller\'s Info on the Input TC',
    'inventory_32_1':'Reclaimed Waste Supplier',
    'inventory_33':'Select company',
    'inventory_34':'Please add supplier Information as needed',
    'inventory_35':'Total Certified Weight',
    'inventory_35_2':'Component Certified Weight',
    'inventory_36':'Select Supplier',
    'inventory_37':'Confirm Selection',
    'inventory_38':'Key Shipment Info listed on the Input TC',
    'inventory_38_1':'Reclaimed Waste Shipment Information',
    'inventory_39':'Shipment No.',
    'inventory_40':'Please fill in the corresponding Shipment No. indicated in Box 9 on the Input TC',
    'inventory_41':'Input shipment document No.',
    'inventory_42':'Input invoice number',
    'inventory_43':'Input gross shipping weight',
    'inventory_44':'Upload Input Material Shipment Documents',
    'inventory_45':'Input Material Purchase Invoice and Bank Transaction Records',
    'inventory_46':'Please fill in the corresponding Product No. indicated in Box 10 on the Input TC',
    'inventory_47':'Please input the corresponding Order No. indicated in Box 10 on the Input TC',
    'inventory_48':'Please input the corresponding Article No. indicated in Box 10 on the Input TC',
    'inventory_49':'Please input the corresponding Net Shipping Weight indicated in Box 10 on the Input TC',
    'inventory_50':'Please input the corresponding Supplementary Weight indicated in Box 10 on the Input TC',
    'inventory_51':'Please input the corresponding Product Category indicated in Box 10 on the Input TC',
    'inventory_52':'Please input the corresponding Product Detail indicated in Box 10 on the Input TC',
    'inventory_53':'Please input the corresponding Material Composition indicated in Box 10 on the Input TC',
    'inventory_54':'Please input the corresponding Certified Weight indicated in Box 10 on the Input TC',
    'inventory_55':'Please input the Used Net Weight before registration in this system',
    'inventory_56':'View Scope Certificate',
    'inventory_57':'GOTS Certified Weight',
    'inventory_58':'Recycled content % included',
    'inventory_59':'Sustainably Sourced Content % included',
    'inventory_60':'Submitted Successfully',
    'inventory_61':'Please wait for ETKO\'s review',
    'inventory_62':'Product Info listed on the Input TC',
    'inventory_62_1':'Reclaimed Material Information',
    'inventory_63':'Input Material Shipment Documents were not uploaded',
    'inventory_64':'Input Material Purchase Invoice and Bank Transaction Records were not uploaded',
  },
  Applicationstatus:{
    Total_balance:{
      'Total_01':'Mass Balance and Output Product Stock',
      'Total_02':'Output Product Certification Standard',
      'Total_03':'Production Date',
      'Total_04':'Component Claim',
      'Total_05':'Add Mass Balance Info',
      'Total_06':'MB Code',
      'Total_07':'Process(es) involved ',
      'Total_08':'Last Processor',
      'Total_09':'Output Product Order No.',
      'Total_10':'Output Product Article No.',
      'Total_11':'Output Product Category',
      'Total_12':'Output Product Detail',
      'Total_13':'Output Product Composition',
      'Total_14':'Selling Unit',
      'Total_15':'Output Product No. of units',
      'Total_16':'Output Product Net Weight',
      'Total_17':'Output Product Supplementary Weight',
      'Total_18':'Product pictures and production records',
      'Total_19':'MB Verification Result',
      'Total_20':'Output TC No. applied',
      'Total_21':'Remaining stock of output products in selling unit',
      'Total_22':'Net Weight of remaining stock of output products',
      'Total_23':'Net Weight of remaining stock of output products (kgs)',
      'Total_24':'View Component Claim Details',
      'Total_25':'Component No.',
      'Total_26':'Component Detail',
      'Total_27':'Component Material Composition',
      'Total_28':'Component Net Weight',
      'Total_29':'Component Supplementary Weight',
      'Total_30':'Input Material Composition',
      'Total_31':'Details of used inputs',
      'Total_32':'View details of used inputs',
      'Total_33':'Order No. on Input TC',
      'Total_34':'Input material Net Weight Stock Balance before production(kgs)',
      'Total_35':'Used Net Weight of Input Material for this lot(kgs)',
      'Total_36':'Production loss (kgs)',
      'Total_37':'Production loss %',
      'Total_38':'Certified weight Converted into output products(kgs)',
      'Total_39':'Shipment No.',
      'Total_40':'Order No. on Output TCs.',
      'Total_41':'Additional Info',
      'Total_42':'Trims excluded from the material composition (if applicable):',
      'Total_43':'Associated TC(s) certified to other TE standard(s) (if applicable):',
      'Total_44':'For fibers, the fiber length in mm and fiber fineness in applicable unit (if applicable):',
      'Total_45':'For yarns, the yarn count (if applicable):',
      'Total_46':'For fabrics, the fabric GSM and fabric construction (if applicable):',
      'Total_47':'For finished textile products, the fabric GSM for all fabrics with claimed material (if applicable):',
      'Total_48':'Component Certified Weight',
      'Total_49':'Close',

    },
    tc_Apply:{
      'Apply_01':'TC Application Status',
      'Apply_02':'TC Application Date',
      'Apply_03':'Apply For TC',
      'Apply_04':'ETKO TC No.',
      'Apply_05':'TC Certification Standard',
      'Apply_06':'Seller\'s company name and address',
      'Apply_07':'Details of Certified Products',
      'Apply_08':'Total Gross Weight',
      'Apply_09':'Total Net Weight',
      'Apply_10':'Total Certified Weight',
      'Apply_11':'TC Draft Confirmed?',
      'Apply_12':'Already applied for revisions',
      'Apply_13':'Not Confirmed',
      'Apply_14':'Confirmed',
      'Apply_15':'Payment Status',
      'Apply_16':'Waiting for ETKO to confirm the receipt',
      'Apply_17':'Payment received',
      'Apply_18':'Not paid',
      'Apply_19':'Review Details',
      'Apply_20':'Draft TC/Official TC',
      'Apply_21':'Confirm TC Draft',
      'Apply_22':'Request revisions to TC draft',
      'Apply_23':'Upload bank slip',
      'Apply_24':'Details of certified products',
      'Apply_25':'Please confirm if you have double checked the TC draft contents. If you surely need a revision to the TC draft, please fill in the reasons in detail:',
      'Apply_26':'View TC Draft',
      'Apply_27':'Reasons for revision',
      'Apply_28':'Please fill in the reasons in details',
      'Apply_29':'Please upload the bank slip according to the billing amount',
      'Apply_30':'View TC Draft',
      'Apply_31':'Please fill in additional declarations (if any) that you want to indicate on this TC',
    },
    TC_herbaceous:{
      'herbaceous_01':'Are you sure to confirm the TC draft? If you haven\'t checked the TC draft, please double check it prior to your confirmation.',
      'herbaceous_02':'Confirm TC Draft',
      'herbaceous_03':'Order Information',
      'herbaceous_04':'Certification standard applied',
      'herbaceous_05':'Seller\'s Info for the certified products',
      'herbaceous_06':'Selling on behalf of',
      'herbaceous_07':'Scope Certificate No.',
      'herbaceous_08':'Licence No.',
      'herbaceous_09':'Non-certified trader',
      'herbaceous_10':'Buyer\'s Info for the certified products',
      'herbaceous_11':'Buying on behalf of',
      'herbaceous_12':'Shipment Info',
      'herbaceous_13':'Shipment Date',
      'herbaceous_14':'Shipment Document No.',
      'herbaceous_15':'Gross Shipping Weight (Kgs)',
      'herbaceous_16':'Consignee',
      'herbaceous_17':'Upload Sales Invoice and Bank receipts',
      'herbaceous_18':'View Sales Invoice and Bank receipts',
      'herbaceous_19':'View Shipment Documents',
      'herbaceous_20':'Certified Product Info',
      'herbaceous_21':'Output Product Order No.',
      'herbaceous_22':'Current stock of output products in selling unit',
      'herbaceous_23':'Net Weight of current stock of output products (kgs)',
      'herbaceous_24':'Certified Weight in current stock of output products(kgs)',
      'herbaceous_25':'Output Product Quantity (in selling unit) applied for TC',
      'herbaceous_26':'Output Product Net Weight applied for TC (Kgs)',
      'herbaceous_27':'Certified Weight in the output products applied for TC (Kgs)',
      'herbaceous_28':'Certified Component Info',
      'herbaceous_29':'View Certified Component Info',
      'herbaceous_30':'Declarations by Seller of Certified Products',
      'herbaceous_31':'The certified product(s) covered in this TC Application have been outsourced to a subcontractor:',
      'herbaceous_32':'Other declarations by Seller of Certified Products',
      'herbaceous_33':'Declarations by Seller of Certified Products',
      'herbaceous_34':'Certification Standard(s) of this TC Application',
      'herbaceous_35':'Shipment No.',
      'herbaceous_36':'Supplementary Weight in the output products applied for TC (Kgs)',
    },
    MB_pop:{
      'mbpop_01':'Output Product Standard Type',
      'mbpop_02':'Enter Input Material Info',
      'mbpop_03':'Completed',
      'mbpop_04':'Output Product Shipment Info',
      'mbpop_04_1':'Is it Dropship?',
      'mbpop_05':'Output Product Certification Standard(s)',
      'mbpop_06':'Product pictures and production records',
      'mbpop_07':'Details of Output Products',
      'mbpop_08':'Component Claim?',
      'mbpop_09':'Last Processor', 
      'mbpop_10':'Please fill in the exact percentage for each material',
      'mbpop_11':'Output Product No. of units',
      'mbpop_12':'in selling unit',
      'mbpop_13':'Including trims but excluding packaging (kgs)',
      'mbpop_14':'Output Product Supplementary Weight (kg)',
      'mbpop_15':'Total weight of Non-claimed trims',
      'mbpop_16':'and accessories(excluding packaging) excluded from the material composition',
      'mbpop_17':'a. Trims excluded from the material composition (if applicable). b. Associated TC(s) certified to other TE standard(s) (if applicable). c.For fibers, the fiber length in mm and fiber fineness in applicable unit (if applicable). d.For yarns, the yarn count (if applicable). e.For fabrics, the fabric GSM and fabric construction (if applicable). f.For finished textile products, the fabric GSM for all fabrics with claimed material (if applicable).',
      'mbpop_18':'Please fill in the used input material information',
      'mbpop_19':'Please add one or more input materials based on your actual usage',
      'mbpop_20':'Add input material used',
      'mbpop_21':'Mass Balance Verification',
      'mbpop_22':'Please select from the registered input stock list, and fill in the used net weight and actual process loss',
      'mbpop_23':'Add input materials used',
      'mbpop_24':'Recycled Content converted into output products',
      'mbpop_25':'Sustainably Sourced Content converted into output products',
      'mbpop_26':'GOTS Certified Weight',
      'mbpop_27':'Recycled Content',
      'mbpop_28':'Last Processor\'s Licence No.',
      'mbpop_28_1':'CB Client No.',
      'mbpop_29':'Last Processor\'s country/region',
      'mbpop_30':'Sustainably Sourced Content',
    },
    TC_pop:{
      'tcpop_01':'Certification Info',
      'tcpop_02':'Shipment and Certified Product Info',
      'tcpop_03':'Completed',
      'tcpop_04':'Certification Standard(s) of this TC Application',
      'tcpop_05':'Automatically generated',
      'tcpop_06':'Buyer\'s company name and address',
      'tcpop_07':'If the buyer is not available in the drop-down list, you click',
      'tcpop_08':'to add it and then re-select it',
      'tcpop_09':'Consignee Address',
      'tcpop_10':'(Change Order No.)',
      'tcpop_11':'Add it and then re-select it',
      'tcpop_12':'Add it and then re-select it',
      'tcpop_13':'Add it and then re-select it',
    }
  },
  bill:{
    TC_bill:{
      'tcbill_01':'TC Bills',
      'tcbill_02':'TC Certification Standard',
      'tcbill_03':'Payment Status',
      'tcbill_04':'Applicant\'s Licence No.',
      'tcbill_05':'Applicant Company Name',
      'tcbill_06':'TC Certification Standard',
      'tcbill_07':'TC Application Date',
      'tcbill_08':'No. of Products included',
      'tcbill_09':'TC Reviewer',
      'tcbill_10':'Download TC Bill',
      'tcbill_11':'Amount of Bill',
      'tcbill_12':'No. of Products included',
      'tcbill_13':'No. of Products included',
      'tcbill_14':'No. of Products included',
    },
    SC_ill:{
      'scbill_01':'SC Extension Bills',
      'scbill_02':'Scope Certificate Standard',
      'scbill_03':'Date of Scope Certificate Extension Application',
      'scbill_04':'Scope Certificate No.',
      'scbill_05':'Date of Scope Certificate Extension Application',
      'scbill_06':'Quantity of Independently Certified Subcontractors added',
      'scbill_07':'Date of Scope Certificate Extension Application',
      'scbill_08':'Date of Scope Certificate Extension Application',
    },
    public:{
      'pub_01':'Bills',
      'pub_02':'Payment received (deducted from recharged account)',
      'pub_03':'Payment received',
      'pub_04':'Not Paid',
      'pub_05':'Waiting for ETKO to confirm the receipt',
      'pub_06':'Tax Invoice Status',
      'pub_07':'Request Tax Invoice',
      'pub_08':'View Tax Invoice',
      'pub_09':'View Bank Slip',
      'pub_10':'Tax Invoice Info',
      'pub_11':'Company name billed',
      'pub_11_2':'Address of billed company',
      'pub_12':'Tax Certificate No. of billed company',
      'pub_13':'Tel. of billed company',
      'pub_14':'Bank name of billed company',
      'pub_15':'Account No. of billed company',
      'pub_16':'Invoice subject',
      'pub_17':'Invoiced amount',
      'pub_18':'Information of Invoice Recipient',
      'pub_19':'Recipient of Invoice',
      'pub_19_1':'Email to receive e-Invoice',
      'pub_20':'Invoice Recipient\'s phone',
      'pub_21':'Invoice Recipient\'s address',
      'pub_22':'Tax Invoice Info',
      'pub_23':'Tax Invoice Status',
      'pub_24':'Not yet applied',
      'pub_25':'Reason for rejection',
      'pub_26':'Type of Tax Invoice',
      'pub_27':'Tax Invoice_Special',
      'pub_28':'Invoice Issued',
      'pub_29':'Invoice Request was Rejected',
      'pub_30':'Please re-submit it after revision',
      'pub_31':'Tax Invoice No.',
      'pub_32':'Invoice attachment',
      'pub_33':'Courier Info',
      'pub_34':'Courier Tracking No.',
      'pub_35':'Courier services company',
      'pub_36':'Courier info inquiry',
      'pub_37':'Invoice Request was Rejected',
      'pub_38':'Download Proforma',
      'pub_39':'Reason for rejection：',
      'pub_40':'Account recharging record',
      'pub_41':'Attachment',
      'pub_42':'No bill yet',
      'pub_43':'Applicant Licence No.',
      'pub_44':'Applicant Company Name',
      'pub_45':'ETKO TC No.',
      'pub_46':'TC Certification Standard',
      'pub_47':'TC Application Date',
      'pub_48':'Buyer\'s name & Address',
      'pub_49':'Total Gross Weight',
      'pub_50':'Total Net Weight',
      'pub_51':'Total Certified Weight',
      'pub_52':'No. of Products included',
      'pub_53':'Component Claim?',
      'pub_54':'Reviewer',
      'pub_55':'Last Review Time',
      'pub_56':'Amount Billed',
      'pub_57':'Payment Status',
      'pub_58':'Payment received (deducted from recharged account)',
      'pub_59':'Payment received',
      'pub_60':'Not paid',
      'pub_61':'Waiting for ETKO to confirm the receipt',
      'pub_62':'TC Bills',
      'pub_63':'TC Bills.xlsx',
      'pub_64':'Applicant Company Name',
      'pub_65':'Applicant Company Name',
      'pub_66':'Scope Certificate No.',
      'pub_67':'Scope Certificate Standard(s)',
      'pub_68':'Date of Scope Certificate Extension Application',
      'pub_69':'Quantity of Independently Certified Subcontractors added',
      'pub_70':'Billed Amount',
      'pub_71':'Scope Certificate Extension Bills',
      'pub_72':'SC Extension Bills.xlsx',
    }
  },
  userInfor:{
    'user_01':'My Profile',
    'user_01_1':'Manage sub-accounts',
    'user_01_2':'Add sub-account',
    'user_01_3':'Passwords',
    'user_01_4':'Valid',
    'user_01_5':'Invalid',
    'user_01_6':'Whether to change the status',
    'user_02':'Basic Configration',
    'user_03':'Basic Info',
    'user_04':'Contact Us',
    'user_05':'Account recharges and expenditures',
    'user_06':'Main Contact Email',
    'user_07':'User Name',
    'user_08':'Contact phone',
    'user_09':'Account Remaining Sum',
    'user_10':'User Security',
    'user_11':'Change passwords',
    'user_12':'User Account Picture',
    'user_13':'Change your photo',
    'user_14':'Update Information',
    'user_15':'Old passwords',
    'user_16':'New passwords',
    'user_17':'Confirm passwords',
    'user_18':'ETKO Default Contact Info',
    'user_19':'Contact email',
    'user_20':'Customer Service contact info',
    'user_21':'Contact Name',
    'user_22':'Running No.',
    'user_23':'Description',
    'user_24':'Transcation Type',
    'user_25':'Transcation Amount',
    'user_26':'Account Remaining Sum',
    'user_27':'Related TC No. or SC Extension',
    'user_28':'Transcation Time',
  },
  renZhengTwo: {
    'rz_01':'Fill in the products you want to extend in your scope certificate：',
    'rz_02':'Product Category',
    'rz_03':'Product Detail',
    'rz_04':'Material Composition',
    'rz_05':'Standard (Label Grade)',
    'rz_06':'Facility Numbers',
    'rz_07':'Operate',
    'rz_08':'Save',
    'rz_09':'Cancel',
    'rz_10':'Edit',
    'rz_11':'Delete',
    'rz_12':'Successfully Saved！',
    'rz_13':'Please add one or more products as needed',
  },
  application: {
    'ac_01':'Download and sign the application form',
    'ac_02':'Save the scope extension application',
    'ac_03':'PDF Preview',
    'ac_05':'Successfully Uploaded',
  },
  gotsInfo: {
    'gi_01':'Upload lab testing reports：',
    'gi_02':'If there are accessories/trims on product, OEKO-TEX 100 Certificate or Test Report according to GOT2.4.16 shall be provided, and the relevant purchasing documents of the compliant accessories should be provided as well',
    'gi_03':'Last Processor\'s company name：',
    'gi_04':'If the Product Category, Product Detail and Material Composition that you are planning to apply TC for are not available in the drop-down list, please go to the "Scope Certificate Extension Application" moudle at the Homepage to submit your application for scope certificate extension first.',
    'gi_05':'Trims excluded from the material composition (if applicable)：',
    'gi_06':'Associated TC(s) certified to other standard(s) (if applicable)：',
    'gi_07':'For fibers, the fiber length in mm and fiber fineness in applicable unit (if applicable)：',
    'gi_08':'For yarns, the yarn count (if applicable)：',
    'gi_09':'For fabrics, the fabric GSM and fabric construction (if applicable)：',
    'gi_10':'For finished textile products, the fabric GSM for all fabrics with claimed material (if applicable)：',
    'gi_11':'Please fill in the correct percentage for each material',
    'gi_12':'The quantity you inputted exceeds the total available quantity; please fill in the correct quantity！',
    'gi_13':'Please fill in the last processor\'s English company name',
  },
  teInfo: {
    'ti_01':'Upload lab testing reports：',
    'ti_02':'Please upload lab testing reports as needed',
    'ti_03':'Last processor\'s English company name：',
    'ti_04':'If the Product Category, Product Detail and Material Composition that you are planning to apply TC for are not available in the drop-down list, please go to the "Scope Certificate Extension Application" moudle at the Homepage to submit your application for scope certificate extension first.',
    'ti_05':'It will be automatically calculated after you correctly fill in the net weight for each certified component',
    'ti_06':'Trims excluded from the material composition (if applicable)：',
    'ti_07':'Associated TC(s) certified to other standard(s) (if applicable)：',
    'ti_08':'For fibers, the fiber length in mm and fiber fineness in applicable unit (if applicable)：',
    'ti_09':'For yarns, the yarn count (if applicable)：',
    'ti_10':'For fabrics, the fabric GSM and fabric construction (if applicable)：',
    'ti_11':'For finished textile products, the fabric GSM for all fabrics with claimed material (if applicable)：',
    'ti_12':'Fill in Additional Info',
    'ti_13':'Component Additional Info',
    'ti_14':'Component Certified Weight：',
    'ti_15':'Please fill in the correct percentage for each material',
    'ti_16':'Re-load the certification standard(s)',
    'ti_17':'At least one certification standard!',
    'ti_18':'You can add maximum 6 items',
    'ti_19':'The quantity you inputted exceeds the total available quantity; please fill in the correct quantity!',
    'ti_20':'Confirm to Close?',
    'ti_21':'Please make the mass balance "conformed" for the last certified component first',
    'ti_22':'It is not permitted to submit if the MB Verification Result is "Not Conformed". Please double check and make necessary corrections to the used input amount, process loss, output product net weight and/or supplementary weight until the verification result is "Conformed".',
    'ti_23':'Percentage:',
    'ti_24':'You need to complete the certified component info!',
    'ti_25':'Please fill in the last processor\'s company name',
    'ti_26':'Delete',
    'ti_27':'If there are accessories/trims on product, OEKO-TEX 100 Certificate or Test Report according to GOT2.4.16 shall be provided, and the relevant purchasing documents of the compliant accessories should be provided as well.',
  },
  changePassword: {
    'cp_01':'The two passwords you entered are different. Please re-enter them.',
    'cp_02':'Modified successfully, please login again！',
  },
  walletDetails: {
    'wd_01':'Account Remaining Sum',
    'wd_02':'Related TC No. or SC Extension',
    'wd_03':'Income and expenditure details.xlsx',
  },
  personIndex: {
    'pi_01': 'User Name',
    'pi_02': 'Contact Name',
    'pi_03': 'Recharge',
    'pi_04': 'Recharging notes(if needed):',
    'pi_05': 'Recharging Amount',
    'pi_06': 'Upload Bank Slip:',
    'pi_07': 'Select File',
    'pi_08': 'View Bank Slip',
    'pi_09': 'Submit recharging request',
    'pi_10': 'Cancel',
    'pi_11': 'Please fill in amount',
    'pi_12': 'Upload Bank Slip',
    'pi_13': 'Successfully submitted, waiting for confirmation.',
    'pi_14': 'Error',
    'pi_15': 'Successfully Uploaded',
    'pi_16': 'Successfully Modified',
  },
  rawmaterialinventoryDetail: {
    'rd_01':'Certification of the organic material used for the products listed complies with USDA NOP rules:',
    'rd_02':'Town and Postcode',
    'rd_03':'Seller\'s Licence No. on the Input TC：',
    'rd_03_2':'CB Client No.：',
    'rd_04':'Seller\'s Scope Certificate No. on the Input TC：',
    'rd_05':'Certification Body of the Input TC：',
    'rd_06':'Details',
    'rd_07':'View Input TC',
    'rd_08':'Certified Weight on Input TC',
    'rd_09':'Operate',
    'rd_10':'Input Product No. of units：',
    'rd_11':'Production Date：',
    'rd_12':'Standard (Label Grade)：',
    'rd_13':'GOTS Certified Weight(kg)：',
    'rd_14':'Certified Weight(kg)：',
    'rd_15':'Tips: If this input material was already partially used before registerred in this TC system (e.g., this input material stock was migrated from other CB or used before in ETKO\'s EXCEL stock reports).',
    'rd_16':'Please fill in the net weight used before; if this is totally new input stock that has never been used, please fill in 0',
    'rd_17':'Additional Info listed in Input TC：',
    'rd_17_1':'Additional Info：',
    'rd_18':'Trims excluded from the material composition (if applicable):',
    'rd_19':'Associated TC(s) certified to other standard(s) (if applicable):',
    'rd_20':'For fibers, the fiber length in mm and fiber fineness in applicable unit (if applicable):',
    'rd_21':'For yarns, the yarn count (if applicable):',
    'rd_22':'For fabrics, the fabric GSM and fabric construction (if applicable):',
    'rd_23':'For finished textile products, the fabric GSM for all fabrics with claimed material (if applicable):',
    'rd_23_1':'Other additional info (if applicable):',
    'rd_24':'Company English Name',
    'rd_25':'Company English Address',
    'rd_26':'Company Legal Name in local language',
    'rd_27':'Company Legal Address in local language',
    'rd_28':'Contact name',
    'rd_29':'Contact phone',
    'rd_30':'Contact email',
    'rd_31':'Licence No.',
    'rd_32':'This TC No. was already registered before. Please fill in the correct TC No.！',
    'rd_33':'Please select at least one certification standard!',
    'rd_34':'You may add maximum 6 items',
    'rd_35':'Confirm to Close?',
    'rd_36':'Please enter',
    'rd_37':'Please select',
    'rd_38':'Select Date',
    'rd_38_1':'Not Applicable',
    'rd_38_2':'N/A',
    'rd_39':'Select the Seller\'s company of the Input TC',
    'rd_40':'Scope Certificate Standard',
    'rd_41':'Input Product No. of units：',
    'rd_42':'Please fill in Input Product No. of units',
    'rd_43':'Production Date：',
    'rd_44':'Select Date',
    'rd_45':'Standard (Label Grade)：',
    'rd_46':'Please select Standard (Label Grade)',
    'rd_47':'GOTS Certified Weight(kg)：',
    'rd_48':'Certified Weight(kg)：',
    'rd_48_1':'Reclaimed content weight(kg)：',
    'rd_49':'Click to View',
    'rd_50':'Too few',
    'rd_51':'Same',
  },
  rawmaterialinventoryIndex:{
    'ri_01': 'Start Date',
    'ri_02': 'End Date',
    'ri_03': 'to',
    'ri_04': 'Input Stock Registration records',
    'ri_05': 'Stock',
    'ri_06': 'Input material Net Weight Stock Balance (kgs)',
    'ri_07': 'Input Stock Code：',
    'ri_08': 'Inventory Consumption Records',
    'ri_09': 'No.',
    'ri_10': 'Input TC No.',
    'ri_11': 'Input TC Issue Date',
    'ri_12': 'Input Material Supplier',
    'ri_13': 'Input Material Order No.',
    'ri_14': 'Order No. on Output TCs.：',
    'ri_15': 'Input Material Article No.',
    'ri_16': 'Input Product Category',
    'ri_17': 'Input Product Detail',
    'ri_18': 'Input Material Composition',
    'ri_19': 'Net Weight Indicated on Input TC',
    'ri_19': 'Net Weight Indicated on Input TC',
    'ri_20': 'Supplementary weight Indicated on Input TC',
    'ri_21': 'Input Material Certified Weight Indicated on Input TC',
    'ri_22': 'Input material Net Weight Stock Balance before production(kgs)',
    'ri_23': 'Production Date of Output Products',
    'ri_24': 'Output Product Order No. ',
    'ri_25': 'Output Product Category',
    'ri_26': 'Output Product Detail',
    'ri_27': 'Output Product Article No.',
    'ri_28': 'Output Product Composition',
    'ri_29': 'Used Net Weight of Input Material for this lot(kgs)',
    'ri_30': 'Production loss',
    'ri_31': 'Certified weight Converted into output products',
    'ri_32': 'Input material Net Weight Stock Balance after production(kgs)',
    'ri_33': 'Output TC No. (Issue Date)',
    'ri_33_1': 'This is Subject',
    'ri_34': 'Output TC No：',
    'ri_35': 'Production Date of Output Products：',
    'ri_36': 'Output Product Order No.:',
    'ri_37': 'Output Product Category：',
    'ri_38': 'Output Product Detail：',
    'ri_39': 'Output Product Composition：',
    'ri_40': 'Used Net Weight of Input Material for this lot(kgs)：',
    'ri_41': 'Production loss：',
    'ri_42': 'Certified weight Converted into output products：',
    'ri_43': 'Input material Net Weight Stock Balance after production(kgs)：',
    'ri_44': 'Output TC No. Issue Date：',
    'ri_45': 'Output product remaining stock quantity in selling unit after sales：',
    'ri_46': 'Total Net Weight of output product remaining stock after sales (Kgs)：',
    'ri_47': 'Total Certified Weight in output product remaining stock after sales(kgs)：',
    'ri_48': 'Input TC No.',
    'ri_49': 'Input Material Shipment Doc No.',
    'ri_50': 'Input Material Supplier',
    'ri_51': 'Product No. indicated on Input TC',
    'ri_52': 'Input Material Order No.',
    'ri_53': 'Input Material Article No.',
    'ri_54': 'Net Weight Indicated on Input TC',
    'ri_55': 'Supplementary weight Indicated on Input TC',
    'ri_56': 'No.',
    'ri_57': 'Input TC No.',
    'ri_58': 'Input Material Order No.',
    'ri_59': 'Input Material Article No.',
    'ri_60': 'Output Product Order No. ',
    'ri_61': 'Output TC No.',
    'ri_62': 'Input Stock related attachment.zip',
    'ri_63': 'Successful!',
    'ri_64': 'Input Inventory Consumption Records.xlsx',
    'ri_64_1': 'Input Stock List_exports.xlsx',
    'ri_65': 'Attachment',
  },
  newInventory: {
    'ni_01': 'Edit Input Stock',
    'ni_02': 'Please select type！',
    'ni_03': 'Details of Input Stock',
  },
  buyer: {
    'buyer_01': 'Main Licensee\'s English name',
    'buyer_02': 'Main',
    'buyer_03': 'Certification Body',
    'buyer_04': 'Successfully Saved!',
    'buyer_05': 'Are you sure to delete this data?',
  },
  scCertInfo: {
    'sci_01':'Certification Info',
    'sci_02':'Certification Status：',
    'sci_03':'Main Licensee\'s English name',
    'sci_03_1':'Main Licensee\'s TE-ID',
    'sci_04':'Main Licensee\'s ',
    'sci_05':'Certification Body',
    'sci_06':'Are you sure to delete this data?',
    'sci_07':'Scope Certificate No.',
    'sci_08':'CB Client No.',
    'sci_09':'This is Subject',
    'sci_10':'Scope Certificate No.：',
    'sci_11':'to',
    'sci_12':'Start Date',
    'sci_13':'End Date',
    'sci_14':'Buyer',
    'sci_15':'Last Processor',
    'sci_16':'Supplier',
    'sci_17':'Contact：',
    'sci_18':'Buyer;',
    'sci_19':'Supplier;',
    'sci_20':'Consignee;',
    'sci_21':'Non-certified Trader;',
    'sci_22':'Non-certified',
    'sci_23':'Main Licensee of Scope Certificate',
    'sci_24':'Subsequent site listed on Scope Certificate',
    'sci_25':'Main Licensee\'s English name',
    'sci_26':'Scope Certificate',
    'sci_26_1':'Scope Certificates.zip',
    'sci_27':'Preview',
    'sci_28':'Buyer',
    'sci_29':'Supplier',
    'sci_30':'Consignee',
    'sci_31':'Non-certified Trader',
    'sci_32':'Company English Name',
    'sci_33':'Company English Address',
    'sci_34':'Company Legal Name in Local Language',
    'sci_35':'Company Legal Address in Local Language',
    'sci_36':'Contact Name',
    'sci_37':'Contact phone',
    'sci_38':'Contact email',
    'sci_39':'Licence No.',
    'sci_40':'Supply_chain_exports.xlsx',
    'sci_41':'No.',
    'sci_42':'Input TC No.',
    'sci_43':'Input TC Certification Standard',
    'sci_44':'Input TC Issue Date',
    'sci_45':'Input Stock Registration Time',
    'sci_46':'Last Review Time',
    'sci_47':'Input material Net Weight Stock Balance',
    'sci_48':'Status',
    'sci_49':'My Products',
    'sci_50':'My Products.xlsx',
  },
  subsidiaryIndex: {
    'si_01':'Edit Supply Chain Company',
    'si_02':'to',
    'si_03':'Start Date',
    'si_04':'End Date',
    'si_05':'TC Application Date',
    'si_06':'Yes',
    'si_07':'NO',
    'si_08':'N/A',
    'si_09':'Input TC No. used',
    'si_10':'TC Withdrawn',
    'si_11':'Withdrawal reason：',
    'si_12':'Not paid',
    'si_13':'View Official TC',
    'si_14':'Revoke',
    'si_15':'Modify',
    'si_16':'Upload Bank Slip',
    'si_17':'Re-upload Bank Slip',
    'si_18':'View Certified Component Details',
    'si_19':'Details of Certified Products',
    'si_20':'Details of Certified Components',
    'si_21':'PDF Preview',
    'si_22':'Output TC No.',
    'si_23':'Input TC No.',
    'si_23_1':'Output Product Order No.',
    'si_23_2':'Output Product Article No.',
    'si_24':'Pending for Review',
    'si_25':'Rejected',
    'si_26':'Pre-review Completed',
    'si_27':'Waiting for client to confirm TC draft',
    'si_28':'Waiting for TC release',
    'si_29':'TC Issued',
    'si_29_1':'Additional notes',
    'si_30':'Bank Slip Uploaded Successfully',
    'si_31':'Confirm to Close？',
    'si_32':'Are you sure to revoke this TC application?',
    'si_33':'Tips',
    'si_34':'Confirm',
    'si_35':'Cancel',
    'si_36':'Did you double check the TC draft and are you sure to confirm it?',
    'si_37':'Successfully Uploaded',
    'si_38':'Draft TC/Official TC',
    'si_39':'No Bills yet',
    'si_40':'TC Application Details',
    'si_41':'未Invoice and Bank Transaction Records were not uploaded',
    'si_42':'Shipment Documents were not uploaded',
    'si_43':'View Details',
    'si_44':'Remaining stock of output products in selling unit',
    'si_45':'Net Weight of remaining stock of output products(KG)',
    'si_46':'Certified Weight in remaining stock of output products(kgs)',
    'si_47':'Delete',
  },
  TC_application: {
    'TCe_01':'Apply for Scope Certificate Extension',
    'TCe_02_2':'Submitted Scope Certificate Extension Applications（',
    'TCe_02':'Saved Scope Certificate Extension Application（',
    'TCe_03':'items）:',
    'TCe_04':'Applicant Company Name',
    'TCe_05':'Scope Certificate No.',
    'TCe_06':'Scope Certificate Standard',
    'TCe_07':'Saved Date',
    'TCe_07_1':'Date of Scope Certificate Extension Application',
    'TCe_08':'Quantity of Independently Certified Subcontractors added',
    'TCe_09':'Application Status',
    'TCe_10':'Not Submitted',
    'TCe_11':'Operate',
    'TCe_12':'Continue Application',
    'TCe_13':'Application Submitted',
    'TCe_14':'Pre-review Completed',
    'TCe_15':'Renewed Scope Certificated Issued',
    'TCe_16':'Rejected',
    'TCe_17':'Operate',
    'TCe_18':'Modify',
    'TCe_19':'Revoke',
    'TCe_20':'Application attachment.zip',
    'TCe_21':'Download Attachment',
    'TCe_22':'Buyer Order or Sales Contract (Upload the original copies of all the Order Numbers filled in the above table) and other needed supplementary documents：',
    'TCe_22_1':'If there is a non-certified trader involved in the trade between the buyer and the seller, please provide both the PO (or sales contract) between the buyer and the non-certified trader and the PO (or sales contract) between the non-certified trader and the seller',
    'TCe_23':'View Order sheet and supplementary documents',
    'TCe_24':'View Order sheet and supplementary documents',
    'TCe_25':'View Order sheet and supplementary documents',
    'TCe_26':'View Order sheet and supplementary documents',
    'TCe_27':'View Order sheet and supplementary documents',
    'TCe_28':'Subcontractor\'s scope certificate (applicable for independently certified subcontractor)：',
    'TCe_29':'View Attachment',
    'TCe_30':'Transport documents and delivery notes of sending unprocessed products to subcontractor：',
    'TCe_31':'Subcontractor invoice：',
    'TCe_32':'Transport documents of sending processed products from subcontractor：',
    'TCe_33':'Consignee',
    'TCe_34':'深圳-518000',
    'TCe_35':'广东省',
    'TCe_36':'中国',
    'TCe_37':'Process',
    'TCe_38':'xxxxx企业',
    'TCe_39':'类别说明',
    'TCe_40':'细节说明',
    'TCe_41':'材料成分说明',
    'TCe_42':'Additional Info',
    'TCe_43':'Have you double checked the details on the TC draft and confirmed the accuracy?',
    'TCe_44':'Tips',
    'TCe_45':'Confirm',
    'TCe_46':'Cancel',
    'TCe_47':'Confirmation Cancelled',
    'TCe_48':'Confirm to Close？',
    'TCe_49':'Successfully Uploaded',
    'TCe_50':'Input TC No.',
    'TCe_51':'Product No. indicated on Input TC',
    'TCe_52':'Product No.',
    'TCe_53':'MB Code',
    'TCe_54':'Output Product Order No. ',
    'TCe_55':'Output Product Article No.',
    'TCe_56':'Related Output TC No.',
    'TCe_62':'Are you sure to revoke this MB?',
    'TCe_63':'Revoked Successfully',
    'TCe_64':'Error in Revoking',
    'TCe_65':'Attachment',
    'TCe_66':'No bill yet',
    'TCe_67':'Tips',
    'TCe_68':'You have an auto-saved record that has not been submitted. Do you want to continue from the unfinished record?',
    'TCe_69':'No',
    'TCe_70':'Yes',
  },
  'switchingLanguagesSucceeded': 'Switching multiple languages succeeded Procedure',
  'please_select_product_category':'Please select a product category',
  'please_select_product_details':'Please select product details',
  'please_select_certification_standard':'Please select the certification standard of the product (label level)',
  'please_select_location':'Please select the location of the product',
  'save_successfully': 'Save successfully',
  'proportion: ':'Proportion:',
  'top_up':'Top up',
  'additional_information_content':'Additional information content',
  'mb_no':'MB number',
  'order_sn':'The order number or sales contract number of the product produced',
  'product_no':'Product number',
  'material_tc_no':'Associated product TC number',
  'return_extension_application':'Are you sure to revoke this application?',
  'tips':'prompt',
  'confirm':'Confirm',
  'cancel':'Cancel',
  'delete_success':'Deleted successfully!',
  'confirm_close':'Do you want to close?',
  'addition':'attachment',
  'no_bill':'No bill',
  'PDF_preview':'PDF preview',
  'component_additional_information':' Component additional information',
  'read_authentication_criteria_again':'Read the authentication criteria again',
  'please_enter_english_name':'Please enter the English name of the final processing party',
  'please_firstly_select_the_input_TC_certification_standard' : 'Please firstly select the input TC certification standard',
  'remark': 'Remark',
  'requireModify': 'Require to modify',
  'buyerScNumber': 'Buyer\'s Scope Certificate number',//
  'buyerName': 'Buyer name',
  'etko_bank_account_info': 'ETKO Bank Account Info',
  'sfjjsq': 'Do you request rush application',
  'sfjjsq-header': 'Rush Application',
  'sfjjsq-tips': 'A surcharge will incur if you request ruch application',

  "shenpi.shenpitc.xzczzs": "View Cancelled TC",

  "components.detail.74lq1y": "*Certification of the organic material used for the products listed complies with USDA NOP rules：",
  "components.detail.072962": "View Attachments",
  "components.detail.3cy3ox": "*Input TC Seller's Licence No.：",
  "components.detail.15y4n0": "*Input TC Seller's Scope Certificate No.：",
  "components.detail.gmcg38": "*Certification Body of Input TC：",
  "components.detail.0x5156": "Details",
  "components.detail.da2yth": "Input Material Product No. of units：",
  "components.detail.48fx0f": "Production Date：",
  "components.detail.n7n31n": "Standard (Label Grade)：",
  "components.detail.g10oj7": "GOTS Certified Weight (KG)：",
  "components.detail.v24ddr": "Certified Weight (KG)：",
  "components.detail.cn3zhy":
      "Tips: If this input material was already partially used before registerred in this TC system (e.g., this input material stock was migrated from other CB or used before in ETKO's EXCEL stock reports).",
  "components.detail.svj7bg": "Additional Info listed in Input TC：",
  "components.detail.176wve": "Trims excluded from the material composition (if applicable):",
  "components.detail.h6y2h7": "Associated TC(s) certified to other standard(s) (if applicable):",
  "components.detail.uikh4p": "For fibers, the fiber length in mm and fiber fineness in applicable unit (if applicable):",
  "components.detail.438pc2": "For yarns, the yarn count (if applicable):",
  "components.detail.3mcui6": "For fabrics, the fabric GSM and fabric construction (if applicable):",
  "components.detail.m12i6i": "For finished textile products, the fabric GSM for all fabrics with claimed material (if applicable):",
  "components.detail.3cck2i": "Certified Weight on Input TC",
  "components.detail.0514tf": "This TC No. was already registered before. Please fill in the correct TC No.！",
  "components.detail.1a2d2f": "missing",
  "components.detail.f23f11": "Please select at least one certification standard!",
  "components.detail.12d7h8": "Please select at least one certification standard!",
  "components.detail.qw2ny8": "Confirm to Close?",
  "components.detail.6y6728": "Same",

  "detail.gots-information.5t26ix": "Lab testing reports：",
  "detail.gots-information.8ajb6i": "*Last processor：",
  "detail.gots-information.8g9amx": "This is the page for 2nd situation",
  "detail.gots-information.6681br": "Please fill in the correct percentage for each material",
  "detail.gots-information.9rs5yp": "The quantity you inputted exceeds the total available quantity; please fill in the correct quantity！",
  "detail.gots-information.3o7113": "Equal",
  "detail.gots-information.61d6f6": "Mass Balance Verification",
  "detail.gots-information.5d3my4": "Component-Mass Balance Verification",
  "detail.gots-information.j9f332": "Percentage：",
  "detail.mbdetail-dialog.2p3z70": "View MB Details",
  "detail.te-information.nn88kd": "Output Product Certification Standard(s)",
  "detail.te-information.frw455": "*Product pictures and production records：",
  "detail.te-information.6xjgts": "Upload lab testing reports：",
  "detail.te-information.7af726": "Component Claim?：",
  "detail.te-information.matv13": "Process categories：",
  "detail.te-information.261xrb": "Last Processor：",
  "detail.te-information.m4s6ko": "Last processor's English Name：",
  "detail.te-information.3lhz83": "Last processor's Licence No.：",
  "detail.te-information.o89i2q": "Last processor's country/region：",
  "detail.te-information.86596g": "Output Product Order No.：",
  "detail.te-information.4qx7o0": "Output Product Article No.：",
  "detail.te-information.60pe2x": "Output Product Category：",
  "detail.te-information.i8436g": "Output Product Detail：",
  "detail.te-information.1wn57w": "Output Product Composition：",
  "detail.te-information.t64r30": "Standard (Label Grade)：",
  "detail.te-information.3dqg6q": "Selling Unit：",
  "detail.te-information.4lhqm5": "Component Certified Weight：",
  "detail.te-information.k5q746": "Component Additional Info",
  "detail.te-information.v3ll1e": "Used Input TC Info",
  "detail.te-information.275fwr": "Re-load the certification standard(s)",
  "detail.te-information.sb4ubf": "You can add maximum 6 items",
  "detail.te-information.77xv56": "Please make the mass balance \"conformed\" for the last certified component first",
  "detail.te-information.s1d505": "Add input material used",
  "detail.te-information.y75s6p": "It is not permitted to submit if the MB Verification Result is \"Not Conformed\". Please double check and make necessary corrections to the used input amount, process loss, output product net weight and/or supplementary weight until the verification result is \"Conformed\".",
  "detail.te-information.m2354g": "",
  "detail.te-information.5j272r": "You need to complete the certified component info！",

  "operation.sales-unit.lon717": "+ Add",
  "operation.sales-unit.dkv5jk": "*Selling Unit Name：",
  "operation.sales-unit.jzj1h8": "*Visible：",
  "operation.sales-unit.16qx69": "Status:",
  "operation.sales-unit.kunw4m": "Open/Closed",
  "operation.sales-unit.8d8wd9": "Open",
  "operation.sales-unit.6t89w8": "Close",
  "operation.sales-unit.4a1zq8": "Selling Unit Name",
  "operation.sales-unit.709588": "Creation Time",
  "operation.sales-unit.mkniku": "Visible Status",
  "operation.sales-unit.7tsa85": "Edit Selling Unit",
  "operation.sales-unit.y14ru9": "Please input ranking No.",
  "operation.sales-unit.1l4rer": "If it's opened, clients can select it when making mass balance",
  "operation.sales-unit.2dfk58": "Are you sure to delete it?",

  "listall.zongpingheng.1k4464": "TC Application Review and Management",
  "listall.zongpingheng.987ga7": "Mass Balance related to this TC Application",
  "listall.zongpingheng.u4byc8": "View Details",
  "listall.zongpingheng.qq4fyz": "Preview |",
  "listall.zongpingheng.d82jfc": "Approve",
  "listall.zongpingheng.qd80x3": "Reject",
  "listall.zongpingheng.uf3q17": "MB Code",
  "listall.zongpingheng.uor80g": "Production Date",
  "listall.zongpingheng.xnh2zp": "Process Types",
  "listall.zongpingheng.l7hk31": "Last Processor",
  "listall.zongpingheng.vxriqn": "Output Product Order No.",
  "listall.zongpingheng.0d80t0": "Output Product Category",
  "listall.zongpingheng.u9u61y": "Output Product Detail",
  "listall.zongpingheng.c22wxh": "Output Product Composition",
  "listall.zongpingheng.kp71xo": "Selling Unit",
  "listall.zongpingheng.861onw": "Output Product No. of units",
  "listall.zongpingheng.xohsa6": "Output Product Net Weight (kgs)",
  "listall.zongpingheng.287484": "Output Product Supplementary Weight (kg)",
  "listall.zongpingheng.biwa03": "Output Product Additional Info",
  "listall.zongpingheng.69dh43": "Component Claim Flag",
  "listall.zongpingheng.7goxu8": "Details of Used Inputs",
  "listall.zongpingheng.k5rn2z": "View Product Spec",
  "listall.zongpingheng.32264h": "MB Verification Result",
  "listall.zongpingheng.lp77w6": "ETKO Approval Status",
  "listall.zongpingheng.7wlqn5": "Output TC No. applied",
  "listall.zongpingheng.21a36g": "Remaining stock of output products in selling unit",
  "listall.zongpingheng.lpjhkj": "Net Weight of remaining stock of output products (KGS)",
  "listall.zongpingheng.bmh110": "Certified Weight in remaining stock of output products (KGS)",

  'tcxxhdb': 'TC Information Checking Sheet',
  'other': 'Other',

  'save_to_draft': "Save Draft",
  'delete_draft_tips': '您确定要删除该草稿吗',
  'continue_to_fill': 'Continue filling out',
  'saved_time': 'Saved Time',
  'yltcbz': 'Input TC Standard',
  'unsubmitted': 'Unsubmitted',
  'unsubmitted_input_stocks': 'Unsubmitted Input Stocks',
  'other_remark' : 'Other Notes',
  'other_remark_1' : 'Modify Notes',
  'unsubmitted_mass_balance': 'Unsubmitted Mass Balance',
  'unsubmitted_tc_applications': 'Unsubmitted TC Applications',

  'notes_to_the_tc_reviewer': 'Notes to the TC Reviewer',

  'import_supply_chain_companies': 'Import Supply Chain Companies',
  'importing_template':' Importing Template',
  'download_template': 'Download Template',
  'upload_importing_file': 'Upload Importing File',
  'confirm_to_import': 'Confirm to Import',
  "shenpi.shnepikc.48184g_4": "Inventory has been manually deducted",
  "operator": "Operators",
  "email_is_error": "Mailbox format is wrong",
}
