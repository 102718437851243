<template>
  <el-container>

    <el-main v-if="show == 1" class="flex flex-center">
      <p class="text">ETKO TC{{ $t(`Login.login_01`) }}</p>
      <div class="login-card">
        <div class="login-title" style="display: flex;align-items: center;justify-content: space-between;">
          <p style="text-align: left;font-weight: 600;">{{ $t(`Login.login_02`) }}</p>
          <!--          <p style="text-align: left;font-weight: 400; font-size: 10px;color: deepskyblue;" @click="show = 4">-->
          <!--            {{ $t(`Login.login_12`) }}</p>-->
          <!--          <p style="text-align: left;font-weight: 400; font-size: 10px;color: deepskyblue;" @click="show = 2">-->
          <!--            {{ $t(`Login.login_03`) }}</p>-->

        </div>
        <div class="login-form-container">
          <!-- 账号密码 -->
          <div>
            <el-input v-model="new_password" type="password" :placeholder="$t(`Login.login_17`)"></el-input>
          </div>
          <div>
            <el-input v-model="re_new_password" type="password" :placeholder="$t(`Login.login_18`)"></el-input>
          </div>
          <!-- 图形验证码 -->
          <!-- <div style="margin-top: 20px">
              <el-input size="medium" v-model="yanzheng" placeholder="请输入校验码"></el-input>
              <div>
                  <img src="" alt="captcha" style=" height:50px;margin-top: 5px;" />
              </div>
          </div> -->
          <!-- 登录按钮 -->
          <div>
            <p v-if="errorShow" style="color: red;font-size: 10px;">{{ $t(`Login.login_19`) }}</p>
            <el-button v-loading="loading" type="info" style="width: 100%;" @click="changePassword">
              {{ $t(`Login.login_07`) }}
            </el-button>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  name: 'Admine01Login',
  data() {
    return {
      userName: '',
      password: '',
      // yanzheng:'',
      loading: '',
      show: 1,
      EmailName: '',
      contactEmail: "",
      etko_number: "",
      query_account: '',
      etko_contact_email: "",
      re_new_password: '',
      new_password: "",
      errorShow: false
    }
  },
  mounted() {
    // console.log(this.$route)
    if (this.$route.query.account) {
      this.userName = this.$route.query.account
    }
  },
  methods: {
    login() {
      this.$axios.post('/cust/login', {
        username: this.userName,
        password: this.password
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: this.$t(`Login.login_21`),
            type: 'success',
            duration: 1000
          });
          let token = res.result.token;
          let id = res.result.id;
          let child_id = res.result.child_id;
          localStorage.setItem('et-token', token)
          localStorage.setItem('et-id', id)
          localStorage.setItem('et-child-id', child_id)
          this.$store.commit('setChildId', child_id)
          setTimeout(() => {
            this.$router.push({
              path: '/index'
            })
          }, 500)
        } else {
          this.$message.error(res.message);
        }
      }).catch(err => {
        console.log(err);
        this.$message.error(err.data.message);
      })
    },
    changePassword() {
      if (this.new_password != this.re_new_password) {
        this.errorShow = true;
        return;
      }

      this.$axios.post('/resetPassword', {
        email: this.userName,
        newPassword: this.new_password
      }).then((res) => {
        this.$message({
          message: res.message,
          type: 'success'
        });

        setTimeout(() => {
          this.$router.push({
            path: '/login'
          })
        }, 500)
      })
    },
    queryAccount() {
      this.$axios.get('/queryAccount?etko_number=' + this.etko_number).then((res) => {
        this.query_account = res.result.email
      })
    },
    // 重置密码---验证码
    loginOut() {
      this.show = 3
      // this.$axios.post('/resetEmail', {
      //   email: this.EmailName
      // }).then(res => {
      //   console.log(res);
      // })

      this.$axios.get('/queryContactEmail?account=' + this.EmailName).then((res) => {
        console.log(res)
        this.contactEmail = res.result.etko_email
      })

    }
  }
}
</script>

<style scoped>
.text {
  font-size: 20px;
  font-weight: 600;
  color: deepskyblue;
  position: absolute;
  margin-left: -1vw;
  top: 24vh;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 40px;

}

.logo-img {
  width: 180px;
  height: 55px;
}

.logo-title {
  color: #4A4A4A;
  /* margin-left: -20px; */
  font-size: 18px;
}

.login-card {
  width: 280px;
  box-shadow: 0 0 30px #e5edf6;
  padding: 20px;
  margin-top: 10vh;
}

.login-title {
  /* text-align: center; */
  color: #4A4A4A;
  /* display: flex; */
}

.login-form-container {
  /*padding: 0 20px 20px 20px;*/
}

.login-form-container > div {
  margin-top: 10px;
}

.flex {
  margin-top: 20vh;
  display: flex !important;
}

.flex-center {
  align-items: center;
  justify-content: center;
}
</style>
