import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    childId:
      localStorage.getItem("et-child-id") == 0
        ? localStorage.getItem("et-id") || 0
        : localStorage.getItem("et-child-id"),
  },
  getters: {},
  mutations: {
    setChildId(state, id) {
      state.childId = id;
    },
    clearChildId(state) {
      state.childId = 0;
      localStorage.removeItem("et-child-id");
    },
  },
  actions: {},
  modules: {},
});
