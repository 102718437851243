import axios from 'axios';
import router from '@/router/index'
import {Message} from 'element-ui'
// console.log(process.env.NODE_ENV)
// let baseURL = '/api_my';
let baseURL = '/api/'; // 开发的路径
if (process.env.NODE_ENV === 'production') {
	baseURL = window.location.origin
}
// 分身1
let default_axios = axios.create({
    baseURL
});

default_axios.interceptors.request.use((res) => {

    let lang = 'en-us'
    // console.log(localStorage.getItem('etko-lang'))
    if( localStorage.getItem('etko-lang') === 'zh' ) {
        lang = 'zh-cn'
    }

    // 语言
    res.headers['think-lang'] = lang
    // 在请求头里加 token
    res.headers['token'] = localStorage.getItem('et-token')
    res.headers['uuid'] = localStorage.getItem('et-id')
    res.headers['child-id'] = localStorage.getItem('et-child-id')
    // console.log(res)
    return res
}, (err) => {

    return Promise.reject(err)

})
// 响应拦截
default_axios.interceptors.response.use((res) => {

    // console.log(res.data);
    if (res.data.code == 100001) {
        localStorage.removeItem('et-token')
        localStorage.removeItem('et-id')
        localStorage.removeItem('et-child-id')
        router.push({
            path: '/login'
        })
        // Message({
        //     message: "登录失效，请重新登录",
        //     type: 'error'
        // })
    }
    return res.data

}, (err) => {

    return Promise.reject(err)

});
export default default_axios
