<template>
    <el-container>

        <el-main v-if="show == 1" class="flex flex-center">
            <p class="text">ETKO TC {{ $t(`Login.login_01`) }}</p>
            <div class="login-card">
                <div class="login-title" style="display: flex;align-items: center;justify-content: space-between;">
                    <p style="text-align: left;font-weight: 600;">{{ $t(`Login.login_02`) }}</p>
                    <el-dropdown @command="onCommand">
                        <div class="el-dropdown-link" style="cursor:pointer; display: flex; align-items: center ">
                            <el-link type="primary" v-if="$i18n.locale === 'zh'">中文</el-link>
                            <el-link type="primary" v-else-if="$i18n.locale === 'tk'">Türkiye</el-link>
                            <el-link type="primary" v-else>English</el-link>
                            <i class="el-icon-arrow-down el-icon--right" ></i>
                        </div>

                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="useZh">中文</el-dropdown-item>
                            <el-dropdown-item command="useEn">English</el-dropdown-item>
                            <el-dropdown-item command="useTk">Türkiye</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="login-form-container">
                    <!-- 账号密码 -->
                    <div>
                        <el-input v-model="userName" :placeholder="$t(`Login.login_04`)"></el-input>
                    </div>
                    <div>
                        <el-input v-model="password" type="password" :placeholder="$t(`Login.login_05`)"></el-input>
                    </div>
                    <!-- 图形验证码 -->
                    <!-- <div style="margin-top: 20px">
                        <el-input size="medium" v-model="yanzheng" placeholder="请输入校验码"></el-input>
                        <div>
                            <img src="" alt="captcha" style=" height:50px;margin-top: 5px;" />
                        </div>
                    </div> -->
                    <!-- 登录按钮 -->
                    <div>
                        <el-button v-loading="loading" type="info" style="width: 100%;" @click="login">
                            {{ $t(`Login.login_06`) }}
                        </el-button>
                    </div>
                </div>
                <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                    <p style="text-align: left;font-weight: 400; font-size: 10px;color: deepskyblue;" @click="show = 4">
                        {{ $t(`Login.login_12`) }}</p>
                    <p style="text-align: left;font-weight: 400; font-size: 10px;color: deepskyblue;" @click="show = 2">
                        {{ $t(`Login.login_03`) }}</p>
                </div>
            </div>
        </el-main>
        <el-main v-if="show == 2" class="flex flex-center">
            <p class="text">ETKO TC{{ $t(`Login.login_01`) }}</p>
            <div class="login-card">
                <div class="login-title" style="padding: 2vh 0;">
                    <el-button type="info" size="mini" style="font-size: 15px;" icon="el-icon-back" circle
                               @click="show = 1"></el-button>
                    <span style="text-align: center;font-weight: 600; margin-left: 1vw;">{{
                        $t(`Login.login_07`)
                        }}</span>
                </div>
                <div class="login-form-container">
                    <!-- 账号密码 -->
                    <div>
                        <el-input v-model="EmailName" :placeholder="$t(`Login.login_11`)"
                                  @change="queryContactEmail"></el-input>
                    </div>
                    <div>
                        <el-button v-loading="loading" type="info" style="width: 100%;" @click="loginOut">
                            {{ $t(`Login.login_08`) }}
                        </el-button>
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 5vh;" v-if="contactEmail != ''">
                        <p style="font-size: 10px;color: grey;">{{ $t(`Login.login_09`) }}</p>
                        <p style="font-size: 10px;color: grey;">{{ $t(`Login.login_10`) }}{{ contactEmail }}{{
                            $t(`Login.login_14`)
                            }}</p>
                    </div>
                </div>
            </div>
        </el-main>
        <el-main v-if="show == 3" class="flex flex-center">
            <p class="text">ETKO TC{{ $t(`Login.login_01`) }}</p>
            <div class="login-card">
                <div class="login-title" style="padding: 2vh 0;">
                    <el-button type="info" size="mini" style="font-size: 15px;" icon="el-icon-back" circle
                               @click="show = 1"></el-button>
                    <span style="text-align: center;font-weight: 600; margin-left: 1vw;">{{
                        $t(`Login.login_07`)
                        }}</span>
                </div>
                <div class="login-form-container">
                    <!-- 账号密码 -->
                    <div style="height: 5vh;">
                        <!-- <el-input v-model="userName" placeholder="请输入您的登录邮箱"></el-input> -->
                    </div>
                    <div>
                        <!--            <el-button v-loading="loading" type="info" style="width: 100%;">{{ $t(`Login.login_08`) }}-->
                        <!--            </el-button>-->
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 5vh;">
                        <p style="font-size: 10px;color: grey;">{{ $t(`Login.login_20`) }}</p>
                    </div>
                </div>
            </div>
        </el-main>
        <el-main v-if="show == 4" class="flex flex-center">
            <p class="text">ETKO TC{{ $t(`Login.login_01`) }}</p>
            <div class="login-card">
                <div class="login-title" style="padding: 2vh 0;">
                    <el-button type="info" size="mini" style="font-size: 15px;" icon="el-icon-back" circle
                               @click="show = 1"></el-button>
                    <span style="text-align: center;font-weight: 600; margin-left: 1vw;">{{
                        $t(`Login.login_12`)
                        }}</span>
                </div>
                <div class="login-form-container">
                    <!-- 账号密码 -->
                    <div style="height: 5vh;">
                        <el-input v-model="etko_number" :placeholder=" $t(`Login.login_13`)"></el-input>
                    </div>
                    <div>
                        <el-button v-loading="loading" type="info" style="width: 100%;" v-if="!query_account"
                                   @click="queryAccount">
                            {{ $t(`Login.login_15`) }}
                        </el-button>
                        <p v-if="query_account">{{ $t(`Login.login_16`) }} {{ query_account }}</p>
                    </div>
                    <div style="width: 100%;text-align: center;margin-top: 5vh;" v-if="contactEmail != ''">
                        <p style="font-size: 10px;color: grey;">{{ $t(`Login.login_09`) }}</p>
                        <p style="font-size: 10px;color: grey;">{{ $t(`Login.login_10`) }}{{ contactEmail }}{{
                            $t(`Login.login_14`)
                            }}</p>
                    </div>
                </div>
            </div>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'Admine01Login',
    data() {
        return {
            userName: '',
            password: '',
            // yanzheng:'',
            loading: '',
            show: 1,
            EmailName: '',
            contactEmail: "",
            etko_number: "",
            query_account: '',
            etko_contact_email: "",
        }
    },
    mounted() {

    },
    methods: {
        onCommand(command){
            switch(command){
                case 'useZh':
                    this.$i18n.locale = 'zh'
                    localStorage.setItem("etko-lang",'zh')
                    break
                case 'useEn':
                    this.$i18n.locale = 'en'
                    localStorage.setItem("etko-lang",'en')
                    break;
                case 'useTk':
                  this.$i18n.locale = 'tk'
                  localStorage.setItem("etko-lang",'tk')
                  break;
            }
        },
        login() {
            this.$axios.post('/cust/login', {
                username: this.userName,
                password: this.password
            }).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        message: this.$t(`Login.login_21`),
                        type: 'success',
                        duration: 1000
                    });
                    let token = res.result.token;
                    let id = res.result.id;
                    let child_id = res.result.child_id
                    localStorage.setItem('et-token', token)
                    localStorage.setItem('et-id', id)
                    localStorage.setItem('et-child-id', child_id)
                    if(child_id == 0){
                        this.$store.commit('setChildId', id)
                    }else{
                        this.$store.commit('setChildId', child_id)
                    }
                    setTimeout(() => {
                        this.$router.push({
                            path: '/index'
                        })
                    }, 500)
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                this.$message.error(err.data.message);
            })
        },
        queryAccount() {
            this.$axios.get('/queryAccount?etko_number=' + this.etko_number).then((res) => {
                this.query_account = res.result.email
            })
        },
        queryContactEmail(data) {
            this.$axios.get('/queryContactEmail?account=' + data).then((res) => {
                this.contactEmail = res.result.etko_email
            })
        },
        // 重置密码---验证码
        loginOut() {
            this.show = 3
            this.$axios.post('/resetEmail', {
                email: this.EmailName
            }).then(res => {
                console.log(res);
            })

            this.$axios.get('/queryContactEmail?account=' + this.EmailName).then((res) => {
                this.contactEmail = res.result.etko_email
            })

        }
    }
}
</script>
<style>
html {
    background: #FFFFFF;
}
</style>
<style scoped>

.text {
    font-size: 20px;
    font-weight: 600;
    color: deepskyblue;
    position: absolute;
    margin-left: -1vw;
    top: 24vh;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 40px;

}

.logo-img {
    width: 180px;
    height: 55px;
}

.logo-title {
    color: #4A4A4A;
    /* margin-left: -20px; */
    font-size: 18px;
}

.login-card {
    width: 280px;
    box-shadow: 0 0 30px #e5edf6;
    padding: 20px;
    margin-top: 10vh;
}

.login-title {
    /* text-align: center; */
    color: #4A4A4A;
    /* display: flex; */
}

.login-form-container {
    /*padding: 0 20px 20px 20px;*/
}

.login-form-container > div {
    margin-top: 10px;
}

.flex {
    margin-top: 20vh;
    display: flex !important;
}

.flex-center {
    align-items: center;
    justify-content: center;
}
</style>
