module.exports = {
    delete: 'sil',
    yes: 'evet',
    no: 'hayir',
    startTime: 'Baslangic Saati',
    to: 'Hedef',
    endTime: 'Bitis Zamani',
    invoiceStatus: 'Fatura Durumu',
    all: 'Hepsi',
    importantNotice: 'Bilgilendirme',
    fileColumn: 'Dosya',
    updateTime: 'Update Time',
    downloadFile: 'Indir',
    invoiced: 'Faturalandirildi',
    uninvoiced: 'Faturalandirilmadi',
    invoiceWaiting: 'Fatura Bilgileri Kontrol Ediliyor',
    downloadBill: 'Taslak Faturayi Indir',
    tcCertApplyPrice: 'TC Basvuru Ucreti',
    applyInvoice: 'Fatura Talep Et',
    uploadVoucher: 'Banka Makbuzunu Yukle',
    reUploadVoucher: 'Banka Makbuzunu Yeniden Yukle',
    payVoucherUploadSuccess: 'Banka Makbuzu Basariyla Yuklendi',
    viewBankVoucher: 'Banka Makbuzunu Goruntule',
    deductedFromAccountBalance: 'Hesap Bakiyesinden Dusuldu',
    payStatus: 'Odeme Durumu',
    waitPay: 'Odenmedi',
    paid: 'Odeme Alindi',
    waitETKOConfirm: 'ETKO onayı bekleniyor',
    applyExtensionPrice: 'Kapsam Genisletme Basvuru Ucreti',
    extensionApplyTcNumber: 'Ürün Sertifika No.',
    selectDate: 'Tarih Secin',
    reUpload: 'Yeniden Yukle',
    otherOrganizations: 'Diger Sertifikasyon Kuruluslari',
    uploadSuccessPleaseSave: 'Basariyla yuklendi, lutfen kaydedin',
    uploadSuccess: 'Basariyla yuklendi',
    uploadInvoiceAndRecord: 'Fatura ve Banka Makbuzlarini Yukle',
    uploadTransportationDoc: 'Tasima Belgelerini Yukle',
    uploadOrder: 'Satin Alma Siparisini Yukle (PO)',
    uploadAppendix: 'Ekleri Yukle',
    uploadTestReport: 'Lab Test Raporlarini Yukle：',
    checkOrder: 'Satin Alma Siparisini Goruntule (PO)',
    checkAppendix: 'Ekleri Goruntule',
    checkTransportationDoc: 'Tasima Belgelerini Goruntule',
    checkInvoiceAndRecord: 'Fatura ve Banka Makbuzlarini Goruntule',
    buyerOrderOrContract: 'Alici Siparisi veya Satis Sozlesmesi (yukaridaki tabloda doldurulan tum Siparis Numaralarinin orijinal kopyalarini yukleyin):',
    pleaseUploadContract_file: 'Fason Sozlesmesini Yükle',
    pleaseUploadSc_file: 'Bagimsiz sertifikali taseronun kapsam sertifikasini yukleyin',
    pleaseUploadUnit_no: 'Bagimsiz sertifikali taseronun lisans numarasini girin',
    pleaseUploadUnit_no_1: 'Bagimsiz Taşeron CB Müşteri Numarası Or GOTS Lisans NO',
    pleaseInputUnit_name: 'Bagimsiz sertifikali taseronun sirket adini girin',
    pleaseInputPercent: 'Dogru malzeme bilesimini % olarak girin',
    pleaseInputUnit_address: 'Taseronun adresini girin',
    pleaseInputStep: 'Islem turlerini girin',
    pleaseInputEnglishNameOfFinalParty: 'Son islemciyi girin',
    pleaseSelectCountry: 'Ulke/Bolge secin',
    pleaseInputDistrict: 'Sehir adini girin',
    pleaseInputZipcode: 'Posta kodunu girin',
    pleaseSelectStandard: 'Sertifikasyon Standardini Secin',
    pleaseSelectCert_body: 'Bagimsiz sertifikali taseronun sertifikasyon kurulusunu secin',
    pleaseSelectCert_sub_expire: 'Bagimsiz sertifikali taseronun kapsam sertifikasinin son kullanma tarihini secin',
    pleaseFillInAsRequired: 'Lutfen dogru ve gerektigi sekilde doldurun',
    pleaseUploadOneOrder: 'Lutfen yukaridaki tabloda doldurulan tum Siparis Numaralarinin orijinal kopyalarini yukleyin',
    pleaseUploadTransportationDoc: 'Islenmemis urunlerin(hammaddelerin) taserona gonderimi icin tasima belgelerini ve teslim notlarini yukleyin',
    pleaseUploadProcessingInvoice: 'Taseron faturasini yukleyin',
    pleaseInput: 'Lutfen giriş yapın',
    pleaseSelectType: 'Lutfen bir tur secin',
    pleaseFillInformation: 'Lutfen ek bilgi kutularindan en az birini dogru sekilde doldurun',
    pleaseFillAndCheck: 'Lutfen once son sertifikali bilesen icin kutlesel dengeyi "uygun" olarak yapin',
    fillInformation: 'Ek bilgileri yaziniz',
    extensionApplicationStatus: 'Kapsam Sertifikasi Genisletme Basvuru Durumu',
    rechargeDetails: 'Bakiye Detaylari',
    rechargeAmount: 'Bakiye Yukleme Miktari',
    rechargeDescription: 'Bakiye Yukleme Aciklamasi',
    recharge_record: 'Bakiye Yukleme Detayları',
    serialNumber: 'Seri Numarasi',
    bankSlip: 'Banka Makbuzu',
    submissionTime: 'Gonderim Zamani',
    typeofInvoice: 'Fatura Turu：',
    VAT_electronic_ticket: 'KDV Genel Faturasi Elektronik',
    VAT_special_electronic_ticket: 'KDV Ozel Faturasi Elektronik',
    VAT_electronic_special_invoice: 'KDV Ozel Faturasi Elektronik',
    Paper_VAT_special_invoice: 'KDV Ozel Faturasi Kagit',
    noSCPleaseSelectAnother: 'Ilgili kapsam sertifikasi mevcut degil, lutfen baska bir standardi secin',
    ExceedPleaseRefill: 'Girdiginiz miktar toplam mevcut miktari geciyor; lutfen dogru miktari doldurun',
    whenNeedPleaseUploadTestReport: 'Lab test raporlarini yukleyin',
    ifNotDisplayPleaseSubmit: 'Basvurmayi dusundugunuz Urun Kategorisi, Urun Detayi ve Malzeme Bilesimi  listede mevcut degilse, lutfen Ana Sayfa\'daki "Kapsam Sertifikasi Genisletme Basvurusu" modulune giderek kapsam sertifikasi genisletme basvurunuzu yapin.',
    ifNonTradersPleaseProvideInvoice: 'Alici ve satici (sirketiniz) arasinda sertifikali olmayan bir tuccarin yer aldigi islem varsa, lutfen alici ile sertifikali olmayan tuccar arasindaki ilgili faturayi ve ayni sekilde sertifikali olmayan tuccar ile satici (sirketiniz) arasindaki faturayi saglayin.',
    subcontractorScopeCertificate: 'Bagimsiz sertifikali taseronun kapsam sertifikasi：',
    subcontractorInvoice: 'Taseron faturasi：',
    subcontractorTransportDoc: 'Taseron tarafindan islenmis urunlerin gonderilmesine iliskin tasima belgeleri：',
    transToSubcontractorDoc: 'Islenmemis urunlerin taserona gonderilmesine iliskin tasima belgeleri ve teslim notlari：',
    calculateAffterFill: 'Toplam agirlik, balans tablosu dogru sekilde doldurduktan sonra otomatik olarak hesaplanir',
    productNumNeedSubtract: 'Urun miktari toplam mevcut miktari asmamalidir',
    weightOfComponent: 'Bilesenin sertifikali agirligi：',
    atLeastOneCertificationStandard: 'Lutfen en az bir sertifikasyon standardi secin',
    englishNameOfFinalParty: 'Son İslemci  Sirketin Adi',
    UpToSixCertificationStandard: 'En fazla 6 standarda kadar',
    dataError: '!Veri Hatasi: İrsaliye No. ',
    weightErrorPleaseCorrect: 'Toplam net sevkiyat agirligi bu gonderinin brut agirligini asmamalidir. Lutfen kontrol edip duzeltin',
    saveSuccess: 'Basariyla Kaydedildi',
    audit: 'Inceleme icin bekleniyor',
    returned: 'Reddedildi',
    ratify: 'Onaylandi',
    areyouwanttoreturned: 'Bu balans(kütle denkliği) kaydini iptal etmek istediginizden emin misiniz?',
    returnSuccess: 'Basariyla Iptal Edildi',
    returnwarn: 'Iptal Edilirken Hata Olustu',
    closeConfirm: 'Kapatmak icin onayliyor musunuz?',
    appendx: 'Ek',
    noBill: 'Henuz fatura yok',
    invoiceApplicationReturned: 'Fatura Talebi Reddedildi',
    consignee: 'Alici',
    applyStatus: 'Onay Durumu',
    rawMaterialStatusPending: 'Incelenmeyi Bekliyor',
    rawMaterialStatusReturned: 'Reddedildi',
    rawMaterialStatusPass: 'Onaylandi',
    recharge: {
        serialSn: 'Sira No.',
        money: 'Yukleme Miktari',
        remark: 'Bu yukleme icin ek notlar',
        bankVoucher: 'Banka dekontu',
    },
    auditStatus: 'Onay Durumu',
    returnReason: 'Reddetme Nedeni',
    submitTime: 'Gonderim Zamani',
    etkoTcNumber: 'ETKO TC Numarasi',
    dlczfbsdczxkh: 'Bagimsiz onayli alt yuklenici lisans numarasi',
    dlczfbsdczxkh_1: 'Taşeron TE-ID',
    dlczfbsdczxkh_2: 'Taşeron GOTS Lisans NO',
    dlczfbsdczxkh_3: 'Taşeron CB Müşteri Numarası',
    fbsdgsmc: 'Alt yuklenici adi',
    qsrczbz: 'Lutfen islem turlerini girin',
    qsrczbzTs: 'Lutfen alt yuklenicinin kapsam belgesinin tesis ekindeki "Islem Processleri" kutusunda belirtilen islemleri kopyalayin. Birden fazla islem varsa ";" (noktali virgul) ile ayirin',
    clhlzbbhf: 'Mal bilesimi yuzdesinde hata. Lutfen yeniden secin',
    dqcplhlzbym: 'Mal bilesimi yuzdesi zaten %100',
    qsrzjmc: 'Lutfen onayli bilesenin ayrintilarini (ornegin, bilesen adi) girin',
    warning: 'Uyari',
    materialMismatchWarningFirst: 'belirtilen bilesimdeki giris TC',
    materialMismatchWarningTips: 'talep ettiginiz bitmis urun bilesiminde bulunmamaktadir. Lutfen manuel dogrulama icin aciklamalarinizi girin',
    entryReason: 'Aciklamalarinizi girin',
    refresh_success: 'Basariyla yenilendi',
    placeholder: {
        'p1': 'Bilgi Secin',
        'p2': 'Arama icin anahtar kelimeler girin',
        'p3': 'Tedarik zincirindeki konumunu secin',
        'p4': 'Standart Secin',
        'p5': 'Lutfen Seciniz',
        'p6': 'Sirketin adini girin',
        'p7': 'Sirketin adresini girin',
        'p8': 'Sirketin yerel dilde yasal adini girin',
        'p9': 'Sirketin yerel dilde yasal adresini girin',
        'p10': 'Sirketin bulundugu ulke/bolgeyi secin',
        'p11': 'Sirketin bulundugu eyalet/il\'i secin',
        'p12': 'Mahalle',
        'p13': 'Yetkili adini girin',
        'p14': 'İletisim telefon numarasini girin',
        'p15': 'Iletisim e-postasini girin',
        'p16': 'Lisans numarasini girin',
        'p17': 'Kapsam belgesi numarasini girin',
        'p18': 'Urun kategorisini secin',
        'p19': 'Urun ayrintisini secin',
        'p20': 'Urun ayrintisini secin',
        'p21': 'Numara girin',
        'p22': 'Bitmis urunlerin onaylama standardini secin',
        'p23': 'Bilesen talebi olup olmadigini secin',
        'p24': 'Onay durumunu secin',
        'p25': 'Tarihi secin',
        'p26': 'Urunun uretimi ve depolanmasinda yer alan islem turlerini secin',
        'p27': 'Son islemciyi secin',
        'p28': 'Cikis urunu siparis numarasini girin',
        'p29': 'Cikis urunu makale numarasini girin',
        'p30': 'Satis birimini secin',
        'p31': 'Satis birimindeki urun miktarini girin',
        'p32': 'Cikis urunu Net Agirligini (Kg) girin',
        'p33': 'Cikis urunu Ek Agirligini (Kg) girin',
        'p34': 'Lutfen doldurun',
        'p35': 'Standart secin (Etiket Sinifi)',
        'p36': 'Saticinin sirket adini secin',
        'p37': 'Gecerli oldugunda onayli olmayan tuccari secin',
        'p38': 'Alicinin sirket adini secin',
        'p39': 'Alicinin adresini girin',
        'p40': 'Giris TC\'nin onaylama standardini secin',
        'p41': 'Kapsam belgesinin onaylama standardini secin',
        'p42': 'Lutfen doldurun',
        'p43': 'TC numarasini girin',
        'p44': ';Lutfen input TC\'den ilgili bilgileri dogrudan kopyalayin',
        'p45': 'GOTS onayli agirligi girin',
        'p46': 'Son islemcinin lisans numarasini girin',
        'p47': 'Son islemcinin ulke/bolgesini girin',
        'p48': 'Posta kodu',
        'p49': 'Malzeme ozelligini secin',
        'p50': 'Malzeme adini secin',
        'p51': 'Detayli aciklamayi girin',
        'p52': 'Posta kodu',
        'p53': 'Islemle ilgili detayli aciklamayi girin',
        'p54': 'Fatura ve tasima belgelerini yukleyin',
        'p55': 'Dosya paketini yukleyin',
    },
    serch: {
        's1': 'Tedarik Zincirindeki Konumu',
        's2': 'Sertifikasyon standardi',
        's3': 'Ara',
        's4': 'Sifirla',
        's5': 'Kapsam Sertifikasi Gecerlilik Tarihi',
        's6': 'Onayli alt yuklenici mi?',
    },
    Table_Operation: {
        'delete': 'Sil',
        'edit': 'Duzenle',
        'preservation': 'Kaydet',
        'upload': 'Yukle',
        'see': 'Goruntule',
        'Download-contract-template': 'Alt Yuklenici Anlasma Sablonunu Indir',
        'add_rows': 'Satir ekle',
        'Payment_voucher': 'Odeme banka dekontu',
        'preview': 'Onizleme',
        'download': 'Indir',
        'Upload_attachments': 'Ek yukle',
        'Re_upload': 'Yeniden yukle',
        'contract_template': 'Sablon_Alt Yuklenici Anlasmasi.pdf',
    },
    table_state: {
        'Approval_Status': 'ETKO Onay Durumu',
        'reviewed': 'Inceleme icin bekliyor',
        'Approved': 'Onaylandi',
        'Returned': 'Reddedildi',
        'record': 'Kayit(lar)',
        'confirmed': 'Musterinin TC taslagini onaylamasi bekleniyor',
        'be_issued': 'TC yayinlanmasi bekleniyor',
        'be_issued_sure': 'TC Yayinlandi',
        'review_completed': 'On inceleme tamamlandi',
    },
    Public_btn: {
        'return': 'Geri don',
        'back': 'Geri',
        'next': 'Ileri',
        'Previous-step': 'Onceki adim',
        'Continue_adding': 'Eklemeye devam et',
        'modify': 'Duzenle',
        'details': 'Detaylar',
        'Upload_files': 'Dosya yukle',
        'Upload_files_click': 'Yuklemek icin tiklayin',
        'Support_extension': 'Desteklenen dosya uzantilari: .pdf .jpg...',
        'Export-to-Excel': 'Excel\'e aktar',
        'cancel': 'Iptal',
        'Submit': 'Gonder',
        'Stow': 'Gizle',
        'open': 'Ac',
        'buyer': 'Alici',
        'Ship': 'Teslimat Adresi',
        'Final_processing_party': 'Son islemci',
        'Non_licensed_traders': 'Onayli olmayan tuccar',
        'supplier': 'Tedarikci',
        'see': 'Goruntule',
        'add_push': 'Ekle',
        'Import_add': 'Ice aktar',
        'Reselect': 'Yeniden sec',
        'determine': 'Onayla',
        'Confirm_modification': 'Duzenlemeyi onayla',
        'copy_mb': 'Yinelenen MB',
    },
    Headers: {
        'Headers_t1': 'Ana Sayfa',
        'Headers_t2': 'Alt Birimler ve Tedarik Zinciri',
        'Headers_t3': 'Urunlerim',
        'Headers_t4': 'Girdi Stogum',
        'Headers_t5': 'Basvuru Durumu Yonetimi',
        'Headers_t6': 'Fatura ve Odeme Bilgileri',
        'Account': 'Profilim',
        'sign_out': 'Cikis yap',
        'extension': 'Kapsam Belgesi Değişiklik Başvurusu',
        'push_inventory': 'Girdi Stogu Kaydi',
        'herbaceous_sure': 'TC Taslagini Onayla',
        'view_tc_dart': 'TC Taslagini Goruntule',
        'Certification_Details': 'TC Basvuru Detaylari',
        'push_mb': 'Kutle Dengesi Ekle',
        'Headers_t7': 'Kutle Dengesi Bilgisini Duzenle',
        'Headers_t8': 'Kaydet ve Gonder',
        'Headers_t9': 'Lutfen once bir tur secin!',
        'Headers_t10': ' "Ek bilgiler" bolumundeki kutulardan en az birini doldurunuz.',
    },
    home: {
        'text_01': 'Mutlu ve huzurlu günler dileriz.',
        'text_02': 'Onayli urunler',
        'text_03': 'Girdi stoklari',
        'text_04': 'TC basvurulari',
        'text_05': 'Sik kullanilan islevler',
        'text_06': 'Tedarik zinciri sirketleri ekle',
        'text_07': 'Girdi Stogu Kaydi',
        'text_08': 'Kutle Dengesi Kayitlari Olustur',
        'text_09': 'TC basvurusu yap',
        'text_10': 'Kapsam Belgesi Değişiklik Başvurusu',
        'text_11': 'Okunmamis mesajlar',
        'text_12': 'Okunan mesajlar',
        'text_13': 'Kapsam Belgesi Değişiklik Başvurusu',
        'text_14': 'Kapsam Belgesi Değişiklik Başvurusu',
    },
    Extension: {
        'Extension_01': 'Bir tur secin',
        'Extension_02': 'Bagimsiz olarak sertifikali alt yuklenici(ler) eklemenin gerekip gerekmedigini secin',
        'Extension_yes': 'Evet',
        'Extension_no': 'Hayir',
        'Extension_03': 'Kapsam belgesi uzatma basvurusu icin bilgileri girin',
        'Extension_04': 'Kapsam uzatimi icin basvurulacak kapsam belgesini secin',
        'Extension_05': 'Sertifikasyon standardi',
        'Extension_06': 'Numara',
        'Extension_07': 'Kapsam belgesi son kullanma tarihi',
        'Extension_09': 'Eklenmesi gereken bagimsiz olarak sertifikali alt yuklenicilerin bilgilerini girin',
        'Extension_10': 'Ihtiyac duyulmasi halinde bagimsiz olarak sertifikali alt yuklenici(ler) ekleyin',
        'Extension_11': 'Alt Yuklenici Lisans Numarasi',
        'Extension_63':'alt birim adresi',
        'Extension_12': 'Alt Yuklenici Adi',
        'Extension_13': 'Alt Yuklenici Adresi',
        'Extension_14': 'Islem Processleri',
        'Extension_15': 'Alt birim tipi',
        'Extension_16': 'Ulke/Bolge',
        'Extension_17': 'Eyalet/Il',
        'Extension_18': 'Sehir',
        'Extension_19': 'Posta Kodu',
        'Extension_20': 'Sertifikasyon Standardi',
        'Extension_21': 'Yetkili adi',
        'Extension_22': 'Iletisim Telefon Numarasi',
        'Extension_23': 'Iletisim E-posta',
        'Extension_23_1': 'Kuruluş Kayıt Kimliği',
        'Extension_23_2': 'Çalışan Sayısı',
        'Extension_24': 'Bagimsiz olarak sertifikali alt yuklenicinin Sertifikasyon Kurumu',
        'Extension_25': 'Bagimsiz olarak sertifikali alt yuklenicinin Kapsam Sertifikasi Gecerlilik Tarihi',
        'Extension_26': 'Bagimsiz olarak sertifikali alt yuklenicinin Kapsam Sertifikasi',
        'Extension_27': 'Imzali Alt Yuklenici Islemci Anlasmasi',
        'Extension_28': 'Islem',
        'Extension_29': 'Bagimsiz olarak sertifikali alt yuklenici',
        'Extension_30': 'Sertifikali urun bilgilerini girin',
        'Extension_31': 'Ihtiyac duyulmasi halinde sertifikali urun(ler) ekleyin',
        'Extension_32': 'Urun Kategorisi',
        'Extension_33': 'Urun Detayi',
        'Extension_34': 'Malzeme Bilesimi',
        'Extension_35': 'Standard (Etiket Sinifi)',
        'Extension_36': 'Musteri Numarasi',
        'Extension_37': 'Daha fazla sertifikali urun ekleyin',
        'Extension_38': 'Sertifikali urun ekle',
        'Extension_39': 'Uretimde yer alan tesisler',
        'Extension_40': 'Bir bilesen iddiasi olup olmadigini secin',
        'Extension_41': 'Standard (Etiket Sinifi)',
        'Extension_42': 'Sertifikali Bilesen',
        'Extension_43': 'Bilesen Aciklamasi',
        'Extension_44': 'Bilesen Standardi (Etiket Sinifi)',
        'Extension_45': 'Bilesen Malzeme Bilesimi',
        'Extension_46': 'Malzeme ozelligi',
        'Extension_47': 'Malzeme adi',
        'Extension_48': 'Malzeme icerik yuzdesi',
        'Extension_49': 'Daha fazla sertifikali bilesen ekleyin',
        'Extension_50': 'Imzali basvuru formunu yukleyin ve gonderin',
        'Extension_51': 'Imzalanacak basvuru formunu indirin',
        'Extension_52': 'Odenmesi gereken ucretler',
        'Extension_53': 'Imzali kapsam belgesi uzatma basvuru formu',
        'Extension_54': 'Odeme bilgileri',
        'Extension_55': 'Basvuruyu gonderdikten sonra banka transferini hemen duzenleyin ve "Fatura ve Odeme Bilgileri" bolumune giderek ilgili faturayi bulup banka dekontunu yukleyin',
        'Extension_56': 'Kullanici Ozel Kosulu',
        'Extension_57': 'Malzeme Bilesimi',
        'Extension_58': 'Ihtiyac duyulmasi halinde malzeme bilesimi bilgilerini girin',
        'Extension_59': 'Bagimsiz olarak sertifikali alt yuklenicinin sertifikasyon kurumunu secin',
        'Extension_60': 'Alt yuklenici bilgisi henuz kaydedilmedi. Ilerlemek icin once kaydedin.',
        'Extension_61': 'Kapsam uzatimi icin basvurulacak kapsam belgesini secin',
        'Extension_62': '"Bagimsiz olarak sertifikali alt yuklenici(ler) eklemek gerekip gerekmedigi" sorusuna "Evet" yanitini isaretlediniz, ancak henuz herhangi bir alt yuklenici eklemediniz.',
    },
    Login: {
        'login_01': 'Sistem',
        'login_02': 'Giris ekranına hos geldiniz',
        'login_03': 'Sifreyi unuttum',
        'login_04': 'Kullanici adınızı girin',
        'login_05': 'Sifrenizi girin',
        'login_06': 'Giris yap',
        'login_07': 'Sifreyi sifirla',
        'login_08': 'Dogrulama kodunu gonder',
        'login_09': 'E-posta yoluyla sifrenizi sifirlama konusunda sorun yasiyorsaniz',
        'login_10': 'Lutfen bir e-posta gonderin',
        'login_11': 'Giris e-posta hesabinizi girin',
        'login_12': 'Kullanici hesabini unut',
        'login_13': 'Kullanici hesabinizi bulmak icin 8 basamakli lisans numaranizi girin',
        'login_14': 'Yardim iste',
        'login_15': 'Giris hesabiniz',
        'login_16': 'Yeni sifrenizi girin',
        'login_17': 'Yeni sifrenizi tekrar girin',
        'login_18': 'Girdiginiz sifreler eslesmiyor',
        'login_19': 'Sifrenizi sifirlamak icin baglanti kullanici adi e-posta adresinize gonderildi. Sifrenizi sifirlamak icin maildeki baglantiya tiklayin.',
        'login_20': 'Basariyla giris yapildi!',
        'login_21': 'Giris basarili!',
    },
    Detachment: {
        'detachment_01': 'Sirketim ve alt birimlerim',
        'detachment_02': 'Tedarik Zinciri Şirketleri',
        'detachment_03': 'Sirket Bilgilerim',
        'detachment_04': 'Sirket Adi',
        'detachment_05': 'Adresi',
        'detachment_06': 'Yerel dilde sirketin adi',
        'detachment_07': 'Yerel dilde sirketin adresi',
        'detachment_08': 'Kuruluş Kayıt Kimliği',
        'detachment_09': 'ETKO Yetkili Personel',
        'detachment_10': 'Sertifikasyon Durumu',
        'detachment_101':'Sahip olunan Marka Ad(lar)ı',
        'detachment_102':'Çalışan Sayısı',
        'detachment_11': 'En Guncel Kapsam Sertifikasi',
        'detachment_12': 'Kapsam Sertifikası No.',
        'detachment_13': 'Kapsam belgesinde listelenen alt birimler',
        'detachment_14': 'No.',
        'detachment_15': 'Alt birim adi',
        'detachment_16': 'Alt birim detaylari',
        'detachment_17': 'Temel Bilgiler',
        'detachment_18': 'Iletisim bilgileri',
        'detachment_19': 'Adres',
        'detachment_20': 'Ulke/Bolge',
        'detachment_21': 'Tedarik Zinciri Şirketleri',
        'detachment_22': 'Toplam',
        'detachment_23': 'Tedarik zinciri sirketi ekle',
        'detachment_24': 'Tedarik Zinciri Şirketleri',
        'detachment_25': 'Yerel dilde sirketin yasal adi',
        'detachment_26': 'Lisans numarasi',
        'detachment_26_4': 'GOTS Lisans No. Veya CB Müşteri Numarası',
        'detachment_26_3': 'Lisans No. Veya CB Müşteri Numarası',
        'detachment_26_2': 'GOTS Lisans NO or CB Müşteri Numarası',
        'detachment_53':'Ana Lisans Sahibinin Lisans Numarası',
        'detachment_53_1':'GOTS Lisans NO',
        'detachment_53_2':'CB Müşteri Numarası',
        'detachment_27': 'Kapsam Sertifikasinin Standardi',
        'detachment_28': 'Kapsam Sertifikasinin numarasi',
        'detachment_29': 'Kapsam Sertifikasininsona erme tarihi',
        'detachment_30': 'Kapsam Sertifikasinin Orijinali',
        'detachment_31': 'Tedarik zinciri sirketi detaylari',
        'detachment_32': 'Tedarik zinciri sirketi temel bilgileri',
        'detachment_33': 'Yerel dilde sirketin yasal adresi',
        'detachment_34': 'Mahalle',
        'detachment_35': 'Tedarik zinciri sirketi sertifikasyon durumu',
        'detachment_36': 'Alicinin sertifikasyon durumu',
        'detachment_37': 'Kapsam belgesi yukle',
        'detachment_38': 'Ana lisans sahibinin adi',
        'detachment_39': 'Ana lisans sahibinin lisans numarasi',
        'detachment_40': 'Kapsam Sertifikasinin Standardi',
        'detachment_41': 'Kapsam Sertifikasinin Geçerlilik Tarihi',
        'detachment_42': 'Adresi',
        'detachment_43': 'Tedarik Zinciri Bilgileri',
        'detachment_44': 'Ek Bilgiler',
        'detachment_45': 'Alicinin sertifikasyon durumu',
        'detachment_46': 'Sertifikali degil',
        'detachment_47': 'Kapsam Sertifikasi Ana Lisans Sahibi',
        'detachment_48': 'Kapsam Sertifikasi Sonraki Site',
        'detachment_49': 'Doldurulmasi gerekmez',
        'detachment_50': 'Tedarik zinciri sirketi birden fazla kapsam belgesine sahipse, diger kapsam belgeleri icin sertifikasyon bilgilerini ekleyin',
        'detachment_51': 'Tedarikcinin Sertifikasyon Durumu',
        'detachment_52': 'Posta kodu',
    },
    MyCertifiedProducts: {
        'product_01': 'Urunlerim',
        'product_02': 'Listelenen urunler',
    },
    Rawmaterialinventory: {
        'home_01': 'Gelen Urün/Input Stogum',
        'home_02': 'Gelen Urün/Input Stok',
        'home_03': 'Gelen Urün/Input Kaynak TC Sertifikasyon Standardı',
        'home_05': 'Gelen Urün/Input TC Verilis Tarihi',
        'home_06': 'Gelen Urün/Input Stok Kayit Tarihi',
        'home_07': 'Son Inceleme Zamani',
        'home_08': 'Gelen Urün/Input Stok Ekle',
        'home_09': 'Gelen Urün/Input TC NO.',
        'home_09_1': 'Geri Kazanılmış Atık Bildirim Formu Numarası',
        'home_10': 'Kalan Giris Stok Bakiyesi',
        'home_11': 'Durum',
        'home_12': 'Gelen Urün/Input Stok Detaylari',
        'home_13': 'Gelen Urün/Input Net Agirlik Stok Bakiyesi',
        'home_14': 'Ekleri Goruntule',
        'home_15': 'Onay Durumu',
        'home_16': 'Gelen Urün/Input Bilgileri',
        'home_17': 'Gelen Urün/Input Satin Alma Faturasi',
        'home_18': 'Gelen Urün/Input Sevkiyat Belge No.',
        'home_19': 'Gelen Urün/Input Sevkiyat Tarihi',
        'home_20': 'Gelen Urün Tedarikcisi',
        'home_21': 'Gelen Urün/Input TC\'de belirtilen Urun No.',
        'home_22': 'Gelen Urün/Input Malzeme Siparis No.',
        'home_23': 'Gelen Urün/Input Malzeme Madde No.',
        'home_24': 'Gelen Urün/Input TC\'de belirtilen Net Agirlik',
        'home_24_1': 'Geri Kazanılmış Malzeme Net Ağırlığı',
        'home_25': 'Gelen Urün/Input Urun Kategorisi',
        'home_26': 'Gelen Urün/Input Urun Detayi',
        'home_27': 'Gelen Urün/Input Malzeme Kompozisyonu',
        'home_28': 'Gelen Urün/Input TC\'de belirtilen Sertifikali Agirlik',
        'home_29': 'GOTS Diger Bilgiler',
        'home_30': 'Gelen Urün/Input Stok Tuketim Kayitlari',
        'home_31': 'Cikis Urun Uretim Tarihi',
        'home_32': 'Gelen Urün/Input TC Verilis Tarihi',
        'home_33': 'Uretim oncesi Giris malzeme Net Agirlik Stok Bakiyesi (kg)',
        'home_34': 'Bu parti icin kullanilan Giris Malzemesinin Net Agirligi (kg)',
        'home_35': 'Fire (kg)',
        'home_36': 'Sertifikali agirlik Cikti urunlerine donusturuldu (kg)',
        'home_37': 'Uretim sonrasi Giris Malzeme Net Agirlik Stok Miktarı (kg)',
        'home_38': 'Gelen Urün/Input Stok Tuketim Detaylari',
        'home_39': 'Gelen Urün/Input TC\'de belirtilen Net Agirlik',
        'home_40': 'Gelen Urün/Input TC\'de belirtilen Ek Agirlik',
        'home_41': 'Cikis TC No. ',
        'home_42': 'Cikis Urun Uretim Tarihi',
        'home_43': 'Gelen Urün/Input TC Verilis Tarihi',
        'home_44': 'Cikis Urun Siparis No. ',
        'home_45': 'Cikis Urun Kategorisi',
        'home_46': 'Cikis Urun Detayi',
        'home_47': 'Cikis Urun Kompozisyonu',
        'home_48': 'Bu parti icin kullanilan Giris Malzemesinin Net Agirligi (kg)',
        'home_49': 'Cikis TC Verilis Tarihi',
        'home_50': 'Satistan sonra cikis urunu stok miktari',
        'home_51': 'Satistan sonra kalan cikis urunu toplam net agirlik (kg)',
        'home_52': 'Satistan sonra kalan cikis urunu toplam sertifikali agirlik (kg)',
        'home_53': 'ETKO TC Sistemi\'nde kayittan once kullanilan Net Agirlik ',
        'home_54': 'Gelen Urün/Input TC No.',
        'home_55': 'Urun No.',
        'home_56': 'Gelen Urün/Input TC\'de belirtilen Urun No.',
        'home_57': 'Kapat',
    },
    New_inventory: {
        'inventory_01': 'Hammadde turu',
        'inventory_02': 'Hammadde bilgilerini doldurun',
        'inventory_03': 'Tamamlandi',
        'inventory_04': 'Lutfen hammadde sertifikasyon standardinin sema sahibini secin',
        'inventory_05': 'Tip I',
        'inventory_06': 'TE\'nin standartlari GRS, RCS, OCS, RDS, RWS, RMS ve RAS icerir.',
        'inventory_07': 'Tip II',
        'inventory_08': 'Global Organik Tekstil Standardi (GOTS), Uluslararasi Dogal Tekstil Birligi (IVN, Almanya), Japonya Organik Pamuk Birligi (JOCA), Amerika Birlesik Devletleri Organik Ticaret Birligi (OTA) ve Birlesik Krallik Toprak Birligi (SA) tarafindan gelistirilmis ve yayinlanmistir.',
        'inventory_09': 'Input TC Etko tarafindan mi hazirlandi?',
        'inventory_09_1': 'Firmanız ilk işlemci mi?',
        'inventory_10': 'Gelen Urün/Input TC Numarasi',
        'inventory_11': 'Saticinin sirket adi',
        'inventory_12': 'TC Bilgisi',
        'inventory_13': 'Kaynak TC Sertifikasyon Standardı',
        'inventory_14': 'TC ekini goruntuleyin',
        'inventory_15': 'TC saticisinin bilgisini girin',
        'inventory_16': 'Satici sirketin adi',
        'inventory_17': 'Satici sirketin adresi',
        'inventory_18': 'TC uzerinde listelenen Ana Sevkiyat Bilgileri',
        'inventory_18_1': 'Geri Kazanılmış Atık Nakliye Bilgisi',
        'inventory_19': 'Sevkiyat No.',
        'inventory_20': 'Sevkiyat Tarihi',
        'inventory_21': 'Sevkiyat Belge No.',
        'inventory_22': 'Fatura No.',
        'inventory_23': 'Brut Gonderi Agirligi',
        'inventory_24': 'Ekler',
        'inventory_25': 'Hammadde Sevkiyat Belgeleri',
        'inventory_26': 'Hammadde Satin Alma Faturasi ve Banka Islem Kayitlari',
        'inventory_27': 'TC\'de listelenen Urun Bilgisi',
        'inventory_27_1': 'Geri Kazanılmış Malzeme Bilgileri',
        'inventory_28': 'Urun No.',
        'inventory_29': 'Bu sistemde kaydedilmeden once kullanilan Net Agirlik',
        'inventory_30': 'Kaynak TC ve Test Raporu',
        'inventory_30_1': 'Geri Kazanılmış Atık Bildirim Formu ve Tedarikçi Anlaşması',
        'inventory_31': 'Ürün resimlerini ve üretim kayıtlarını yükleyin',
        'inventory_31_2':'Giriş TC ve gerekli test raporlarını yükleyin',
        'inventory_31_3':'Giriş Geri Kazanılmış Atık Bildirim Formu ve Tedarikçi Anlaşması',
        'inventory_32': 'TC\'deki Satici Bilgileri',
        'inventory_32_1': 'Geri Kazanılmış Atık Tedarikçisi',
        'inventory_33': 'Sirket secin',
        'inventory_34': 'Gerekirse tedarikci bilgileri ekleyin',
        'inventory_35': 'Toplam Sertifikali Agirlik',
        'inventory_35_2': 'Bilesen Sertifikali Agirlik',
        'inventory_36': 'Tedarikci Sec',
        'inventory_37': 'Secimi Onayla',
        'inventory_38': 'TC uzerinde listelenen Ana Sevkiyat Bilgileri',
        'inventory_38_1': 'Geri Kazanılmış Atık Nakliye Bilgisi',
        'inventory_39': 'Sevkiyat No.',
        'inventory_40': 'Lutfen TC\'deki 9. Kutuda belirtilen ilgili Sevkiyat No.\'yu doldurun',
        'inventory_41': 'Giris sevkiyat belgesi No.',
        'inventory_42': 'Giris fatura numarasi',
        'inventory_43': 'Giris brut sevkiyat agirligi',
        'inventory_44': 'Hammadde Sevkiyat Belgelerini Yukleyin',
        'inventory_45': 'Hammadde Satin Alma Faturasi ve Banka Islem Kayitlarini Yukleyin',
        'inventory_46': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Urun No.\'yu doldurun',
        'inventory_47': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Siparis No.\'yu doldurun',
        'inventory_48': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Makale No.\'yu doldurun',
        'inventory_49': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Net Sevkiyat Agirligi\'ni doldurun',
        'inventory_50': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Ek Agirlik\'yi doldurun',
        'inventory_51': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Urun Kategorisi\'ni doldurun',
        'inventory_52': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Urun Detayi\'ni doldurun',
        'inventory_53': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Malzeme Kompozisyonu\'nu doldurun',
        'inventory_54': 'Lutfen TC\'deki 10. Kutuda belirtilen ilgili Sertifikali Agirligi doldurun',
        'inventory_55': 'Bu sistemde kayittan once kullanilan Net Agirligi girin',
        'inventory_56': 'Kapsam Sertifikasini Goruntule',
        'inventory_57': 'GOTS Sertifikali Agirlik',
        'inventory_58': 'Geridonusturulmus icerik % olarak dahil edildi',
        'inventory_59': 'Surdurulebilir Kaynakli Icerik % olarak dahil edildi',
        'inventory_60': 'Basariyla Gonderildi',
        'inventory_61': 'Lutfen ETKO\'nun incelemesini bekleyin',
        'inventory_62': 'TC\'de listelenen Urun Bilgisi',
        'inventory_62_1': 'Geri Kazanılmış Malzeme Bilgileri',
        'inventory_63': 'Hammadde Sevkiyat Belgeleri yuklenmedi',
        'inventory_64': 'Hammadde Satin Alma Faturasi ve Banka Islem Kayitlari yuklenmedi',
    },
    Applicationstatus: {
        Total_balance: {
            'Total_01': 'Kutle Denkligi ve Cikti Urun Stogu',
            'Total_02': 'Cikti Urunu Sertifikasyon Standardi',
            'Total_03': 'Uretim Tarihi',
            'Total_04': 'Bilesen Talebi',
            'Total_05': 'Toplam Dengesi Bilgisi Ekle',
            'Total_06': 'DB Kodu',
            'Total_07': 'Dahil Olan Surec(ler)',
            'Total_08': 'Son Islemci',
            'Total_09': 'Cikti Urunu Siparis No.',
            'Total_10': 'Çıktı ürün Artikel No.',
            'Total_11': 'Cikti Urunu Kategorisi',
            'Total_12': 'Cikti Urunu Detayi',
            'Total_13': 'Cikti Urunu Kompozisyonu',
            'Total_14': 'Satis Birimi',
            'Total_15': 'Cikti Urunu Birim Sayisi',
            'Total_16': 'Cikti Urunu Net Agirlik',
            'Total_17': 'Cikti Urunu Ek Agirlik',
            'Total_18': 'Ürün resimleri ve üretim kayıtları',
            'Total_19': 'MB Dogrulama Sonucu',
            'Total_20': 'Uygulanan Cikti TC No.',
            'Total_21': 'Satis birimindeki cikti urunlerinin kalan stogu',
            'Total_22': 'Kalan cikti urunlerinin net agirligi',
            'Total_23': 'Kalan cikti urunlerinin net agirligi (kg)',
            'Total_24': 'Bilesen Talebi Detaylarini Goruntule',
            'Total_25': 'Bilesen No.',
            'Total_26': 'Bilesen Detayi',
            'Total_27': 'Bilesen Malzeme Kompozisyonu',
            'Total_28': 'Bilesen Net Agirlik',
            'Total_29': 'Bilesen Ek Agirlik',
            'Total_30': 'Girdi Malzeme Kompozisyonu',
            'Total_31': 'Kullanilan girdilerin detaylari',
            'Total_32': 'Kullanilan girdilerin detaylarini goruntule',
            'Total_33': 'Girdi TC\'sindeki Siparis No.',
            'Total_34': 'Uretim oncesi Girdi Malzeme Net Agirlik Stogu (kg)',
            'Total_35': 'Bu lot icin kullanilan Girdi Malzeme Net Agirligi (kg)',
            'Total_36': 'Uretim kaybi (kg)',
            'Total_37': 'Uretim kaybi %',
            'Total_38': 'Sertifikali agirlik Cikti Urunlerine donusturuldu (kg)',
            'Total_39': 'Sevkiyat No.',
            'Total_40': 'Cikti Urunu Siparis No.',
            'Total_41': 'Ek Bilgiler',
            'Total_42': 'Malzeme kompozisyonundan haric tutulan suslemeler (varsa):',
            'Total_43': 'Diger TE standartlarina sertifikali ilgili TC(ler) (varsa):',
            'Total_44': 'Lifler icin, uygulanabilir birimde lif uzunlugu ve lif inceligi (varsa):',
            'Total_45': 'Iplikler icin, iplik numarasi (varsa):',
            'Total_46': 'Kumaslar icin, kumas GSM ve kumas yapisi (varsa):',
            'Total_47': 'Bitmis tekstil urunleri icin, talep edilen malzemeye sahip tum kumaslarin kumas GSM\'si (varsa):',
            'Total_48': 'Bilesen Sertifikali Agirlik',
            'Total_49': 'Kapat',

        },
        tc_Apply: {
            'Apply_01': 'TC Basvuru Durumu',
            'Apply_02': 'TC Basvuru Tarihi',
            'Apply_03': 'TC Basvurusu Yap',
            'Apply_04': 'ETKO TC No.',
            'Apply_05': 'Kaynak TC Sertifikasyon Standardı',
            'Apply_06': 'Saticinin sirket adi ve adresi',
            'Apply_07': 'Sertifikali Urunlerin Detaylari',
            'Apply_08': 'Toplam Brut Agirlik',
            'Apply_09': 'Toplam Net Agirlik',
            'Apply_10': 'Toplam Sertifikali Agirlik',
            'Apply_11': 'TC Taslagi Onaylandi mi?',
            'Apply_12': 'Duzeltmeler icin zaten basvuruldu',
            'Apply_13': 'Onaylanmadi',
            'Apply_14': 'Onaylandi',
            'Apply_15': 'Odeme Durumu',
            'Apply_16': 'ETKO tarafindan ödeme alindiginin onaylanmasi bekleniyor',
            'Apply_17': 'Odeme alindi',
            'Apply_18': 'Odenmedi',
            'Apply_19': 'Inceleme Detaylari',
            'Apply_20': 'Taslak TC/Resmi TC',
            'Apply_21': 'TC Taslagini Onayla',
            'Apply_22': 'TC taslagi icin duzeltme istegi',
            'Apply_23': 'Banka dekontu yukle',
            'Apply_24': 'Sertifikali urunlerin detaylari',
            'Apply_25': 'TC taslagini kontrol ettiginizden emin olun. Eger TC taslaginda duzeltme yapmaniz gerekiyorsa, lutfen nedenleri detayli bir sekilde doldurun:',
            'Apply_26': 'TC Taslagini Goruntule',
            'Apply_27': 'Duzeltme nedenleri',
            'Apply_28': 'Lutfen nedenleri detayli bir sekilde doldurun',
            'Apply_29': 'Lutfen fatura tutarina gore banka dekontunu yukleyin',
            'Apply_30': 'TC Taslagini Goruntule',
            'Apply_31':'Lütfen bu TC üzerinde belirtmek istediğiniz herhangi bir ek beyanı doldurun (varsa).',
        },
        TC_herbaceous: {
            'herbaceous_01': 'TC taslagini onaylamak istediginize emin misiniz? TC taslagini kontrol etmediyseniz, onaylamadan once lutfen kontrol ediniz.',
            'herbaceous_02': 'TC Taslagini Onayla',
            'herbaceous_03': 'Siparis Bilgisi',
            'herbaceous_04': 'Basvurulan Sertifikasyon Standardi',
            'herbaceous_05': 'Sertifikali urunler icin Saticinin Bilgileri',
            'herbaceous_06': 'Adina satis yapiliyor',
            'herbaceous_07': 'Kapsam Sertifikasi No.',
            'herbaceous_08': 'Lisans No.',
            'herbaceous_09': 'Sertifikali olmayan tuccar',
            'herbaceous_10': 'Sertifikali urunler icin Alicinin Bilgileri',
            'herbaceous_11': 'Adina satin aliniyor',
            'herbaceous_12': 'Sevkiyat Bilgisi',
            'herbaceous_13': 'Sevkiyat Tarihi',
            'herbaceous_14': 'Sevkiyat Belge No.',
            'herbaceous_15': 'Brut Sevkiyat Agirligi (Kg)',
            'herbaceous_16': 'Teslimat Adresi',
            'herbaceous_17': 'Satis Faturasi ve Banka Makbuzlarini Yukle',
            'herbaceous_18': 'Satis Faturasi ve Banka Makbuzlarini Goruntule',
            'herbaceous_19': 'Sevkiyat Belgelerini Goruntule',
            'herbaceous_20': 'Sertifikali Urun Bilgisi',
            'herbaceous_21': 'Cikis Urunu Siparis No.',
            'herbaceous_22': 'Satis birimindeki mevcut stok miktari',
            'herbaceous_23': 'Mevcut stoktaki cikis urunlerinin Net Agirligi (Kg)',
            'herbaceous_24': 'Mevcut stoktaki cikis urunlerindeki Sertifikali Agirligi (Kg)',
            'herbaceous_25': 'TC icin basvurulan Cikis Urunu Miktari (satis biriminde)',
            'herbaceous_26': 'TC icin basvurulan Cikis Urunu Net Agirligi (Kg)',
            'herbaceous_27': 'TC icin basvurulan cikis urunlerindeki Sertifikali Agirlik (Kg)',
            'herbaceous_28': 'Sertifikali Bilesen Bilgisi',
            'herbaceous_29': 'Sertifikali Bilesen Bilgisini Goruntule',
            'herbaceous_30': 'Sertifikali Urunlerin Saticisi Tarafindan Beyanlar',
            'herbaceous_31': 'Bu başvuruda bulunan sertifikalı ürünler, Fason işlem gördü mü ?:',
            'herbaceous_32': 'Sertifikali Urunlerin Saticisi Tarafindan Diger Beyanlar',
            'herbaceous_33': 'Sertifikali Urunlerin Saticisi Tarafindan Beyanlar',
            'herbaceous_34': 'Bu TC Basvurusunun Sertifikasyon Standardi(lari)',
            'herbaceous_35': 'Sevkiyat No.',
            'herbaceous_36': 'TC icin basvurulan cikis urunlerindeki Ek Agirlik (Kg)',
        },
        MB_pop: {
            'mbpop_01': 'Cikis Urunu Standardi',
            'mbpop_02': 'Girdi Malzeme Bilgilerini Girin',
            'mbpop_03': 'Tamamlandi',
            'mbpop_04': 'Cikis Urunu Sevkiyat Bilgisi',
            'mbpop_04_1': 'Dropship/Stoksuz Satış mı?',
            'mbpop_05': 'Cikis Urunu Sertifikasyon Standart(lar)i',
            'mbpop_06': 'Ürün resimleri ve üretim kayıtları',
            'mbpop_07': 'Cikis Urunu Detaylari',
            'mbpop_08': 'Parçalı Ürün (Component Claim)',
            'mbpop_09': 'Son Islemci',
            'mbpop_10': 'Lutfen her malzeme icin yuzde doldurun',
            'mbpop_11': 'Cikis Urunu Birim Miktari',
            'mbpop_12': 'satis biriminde',
            'mbpop_13': 'Ambalaj haric trimler dahil (kg)',
            'mbpop_14': 'Cikis Urunu Ek Agirlik (kg)',
            'mbpop_15': 'Talep edilmeyen trimlerin toplam agirligi',
            'mbpop_16': 've malzemeden dislanan aksesuarlar (ambalaj haric)',
            'mbpop_17': 'a. Malzeme kompozisyonundan cikarilan trimler (varsa). b. Diger TE standartlarina sertifikali ilgili TC\'ler (varsa). c. Elyaf icin mm cinsinden elyaf uzunlugu ve elyaf incelemesi (varsa). d. Iplikler icin iplik numarasi (varsa). e. Kumaslar icin kumas GSM ve kumas yapisi (varsa). f. Bitmis tekstil urunleri icin talep edilen tum kumaslarin kumas GSM degeri (varsa).',
            'mbpop_18': 'Kullanilan girdi malzeme bilgilerini doldurun',
            'mbpop_19': 'Gercek kullanima dayanarak bir veya daha fazla girdi malzemesi ekleyin',
            'mbpop_20': 'Kullanilan girdi malzemesi ekle',
            'mbpop_21': 'Kitle Dengesi Dogrulama',
            'mbpop_22': 'Kayitli girdi stok listesinden secin ve kullanilan net agirligi ve gercek islem kaybini doldurun',
            'mbpop_23': 'Kullanilan girdi malzemelerini ekle',
            'mbpop_24': 'Cikis urunlerine donusturulen geri donusumlu icerik',
            'mbpop_25': 'Cikis urunlerine donusturulen surdurulebilir kaynakli icerik',
            'mbpop_26': 'GOTS Sertifikali Agirlik',
            'mbpop_27': 'Geri Donusumlu Icerik',
            'mbpop_28': 'Son Islemcinin Lisans No.',
            'mbpop_28_1': 'CB Musteri Numarasi',
            'mbpop_29': 'Son Islemcinin ulkesi/bolgesi',
            'mbpop_30': 'Surdurulebilir Kaynakli Icerik',
        },
        TC_pop: {
            'tcpop_01': 'Sertifikasyon Bilgisi',
            'tcpop_02': 'Sevkiyat ve Sertifikali Urun Bilgisi',
            'tcpop_03': 'Tamamlandi',
            'tcpop_04': 'Bu TC Basvurusunun Sertifikasyon Standart(lar)i',
            'tcpop_05': 'Otomatik olarak olusturuldu',
            'tcpop_06': 'Alicinin sirket adi ve adresi',
            'tcpop_07': 'Alici listede mevcut degilse,tıklayın',
            'tcpop_08': 'Eklemek icin tiklayin ve tekrar secin',
            'tcpop_09': 'Alıcı Adresi',
            'tcpop_10': '(Siparis No. Degistir)',
            'tcpop_11': 'Bunu ekleyin ve tekrar secin',
            'tcpop_12': 'Bunu ekleyin ve tekrar secin',
            'tcpop_13': 'Bunu ekleyin ve tekrar secin',
        }
    },
    bill: {
        TC_bill: {
            'tcbill_01': 'TC Faturalari',
            'tcbill_02': 'Kaynak TC Sertifikasyon Standardı',
            'tcbill_03': 'Odeme Durumu',
            'tcbill_04': 'Basvuru Sahibinin Lisans No.',
            'tcbill_05': 'Basvuru Sahibi Sirket Adi',
            'tcbill_06': 'Kaynak TC Sertifikasyon Standardı',
            'tcbill_07': 'TC Basvuru Tarihi',
            'tcbill_08': 'Dahil Edilen Urun Sayisi',
            'tcbill_09': 'TC Inceleyici',
            'tcbill_10': 'TC Faturasini Indir',
            'tcbill_11': 'Fatura Tutari',
            'tcbill_12': 'Dahil Edilen Urun Sayisi',
            'tcbill_13': 'Dahil Edilen Urun Sayisi',
            'tcbill_14': 'Dahil Edilen Urun Sayisi',
        },
        SC_ill: {
            'scbill_01': 'SC Uzatma Faturalari',
            'scbill_02': 'Kapsam Sertifikasi Standardi',
            'scbill_03': 'Kapsam Sertifikasi Uzatma Basvuru Tarihi',
            'scbill_04': 'Kapsam Sertifikasi No.',
            'scbill_05': 'Kapsam Sertifikasi Uzatma Basvuru Tarihi',
            'scbill_06': 'Bagimsiz Olarak Sertifikalandirilan Alt Isveren Sayisi',
            'scbill_07': 'Kapsam Sertifikasi Uzatma Basvuru Tarihi',
            'scbill_08': 'Kapsam Sertifikasi Uzatma Basvuru Tarihi',
        },
        public: {
            'pub_01': 'Faturalar',
            'pub_02': 'Odeme alindi (hesaptan dusuldu)',
            'pub_03': 'Odeme alindi',
            'pub_04': 'Odenmedi',
            'pub_05': 'Fatura ETKO tarafindan onaylanmayi bekleniyor',
            'pub_06': 'Vergi Faturasi Durumu',
            'pub_07': 'Vergi Faturasi Talep Et',
            'pub_08': 'Vergi Faturasini Goruntule',
            'pub_09': 'Banka Makbuzu Goruntule',
            'pub_10': 'Vergi Faturasi Bilgisi',
            'pub_11': 'Faturalandirilan Sirket Adi',
            'pub_11_2':'Faturalandırılan şirketin adresi',
            'pub_12': 'Faturalandirilan sirketin Vergi Kimlik No.',
            'pub_13': 'Faturalandirilan sirketin telefonu',
            'pub_14': 'Faturalandirilan sirketin banka adi',
            'pub_15': 'Faturalandirilan sirketin hesap numarasi',
            'pub_16': 'Fatura konusu',
            'pub_17': 'Faturalandirilan tutar',
            'pub_18': 'Fatura Alicisi Bilgisi',
            'pub_19': 'Fatura Alicisi',
            'pub_19_1': 'E-Fatura almak için e-posta adresi',
            'pub_20': 'Fatura Alicisinin telefonu',
            'pub_21': 'Fatura Alicisinin adresi',
            'pub_22': 'Vergi Faturasi Bilgisi',
            'pub_23': 'Vergi Faturasi Durumu',
            'pub_24': 'Henuz basvurulmadi',
            'pub_25': 'Reddedilme nedeni',
            'pub_26': 'Vergi Faturasi Turu',
            'pub_27': 'Ozel Vergi Faturasi',
            'pub_28': 'Fatura Duzenlendi',
            'pub_29': 'Fatura Talebi Reddedildi',
            'pub_30': 'Duzeltme yapildiktan sonra tekrar gonderiniz',
            'pub_31': 'Vergi Faturasi No.',
            'pub_32': 'Fatura eki',
            'pub_33': 'Kargo Bilgisi',
            'pub_34': 'Kargo Takip No.',
            'pub_35': 'Kargo Hizmetleri sirketi',
            'pub_36': 'Kargo bilgi sorgulama',
            'pub_37': 'Fatura Talebi Reddedildi',
            'pub_38': 'Proforma\'yi Indir',
            'pub_39': 'Reddetme nedeni:',
            'pub_40': 'Hesap yenileme kaydi',
            'pub_41': 'Eklenti',
            'pub_42': 'Henuz fatura yok',
            'pub_43': 'Basvuru Sahibi Lisans No.',
            'pub_44': 'Basvuru Sahibi Sirket Adi',
            'pub_45': 'ETKO TC No.',
            'pub_46': 'Kaynak TC Sertifikasyon Standardı',
            'pub_47': 'TC Basvuru Tarihi',
            'pub_48': 'Alicinin adi ve adresi',
            'pub_49': 'Toplam Brut Agirlik',
            'pub_50': 'Toplam Net Agirlik',
            'pub_51': 'Toplam Sertifikali Agirlik',
            'pub_52': 'Dahil Edilen Urun Sayisi',
            'pub_53': 'Bilesen Talebi?',
            'pub_54': 'Inceleyici',
            'pub_55': 'Son Inceleme Zamani',
            'pub_56': 'Faturalandirilan Tutar',
            'pub_57': 'Odeme Durumu',
            'pub_58': 'Odeme alindi (hesaptan dusuldu)',
            'pub_59': 'Odeme alindi',
            'pub_60': 'Odenmedi',
            'pub_61': 'ETKO tarafindan alinmasi onaylanmayi bekleniyor',
            'pub_62': 'TC Faturalari',
            'pub_63': 'TC Faturalari.xlsx',
            'pub_64': 'Basvuru Sahibi Sirket Adi',
            'pub_65': 'Basvuru Sahibi Sirket Adi',
            'pub_66': 'Kapsam Sertifikasi No.',
            'pub_67': 'Kapsam Sertifikasi Standardi',
            'pub_68': 'Kapsam Sertifikasi Uzatma Basvuru Tarihi',
            'pub_69': 'Bagimsiz Olarak Sertifikalandirilan Alt Isveren Sayisi',
            'pub_70': 'Faturalandirilan Tutar',
            'pub_71': 'Kapsam Sertifikasi Uzatma Faturalari',
            'pub_72': 'SC Uzatma Faturalari.xlsx',
        }
    },
    userInfor: {
        'user_01': 'Profilim',
        'user_01_1': 'Alt hesapları yönet',
        'user_01_2': 'Bir alt hesap ekle',
        'user_01_3': 'Sifre',
        'user_01_4': 'Geçerli',
        'user_01_5': 'Geçersiz',
        'user_01_6': 'Durumun değiştirilip değiştirimemesi',
        'user_02': 'Temel Yapilandirma',
        'user_03': 'Temel Bilgi',
        'user_04': 'Iletisim',
        'user_05': 'Hesap Yenileme ve Harcamalar',
        'user_06': 'Ana Iletisim E-postasi',
        'user_07': 'Kullanici Adi',
        'user_08': 'Iletisim Numarası',
        'user_09': 'Kalan Hesap Bakiyesi',
        'user_10': 'Kullanici Guvenligi',
        'user_11': 'Sifre Degistirme',
        'user_12': 'Kullanici Hesap Resmi',
        'user_13': 'Fotografinizi Degistirin',
        'user_14': 'Bilgileri Guncelle',
        'user_15': 'Eski Sifre',
        'user_16': 'Yeni Sifre',
        'user_17': 'Sifreyi Onayla',
        'user_18': 'ETKO Varsayilan Iletisim Bilgileri',
        'user_19': 'Iletisim E-postasi',
        'user_20': 'Musteri Hizmetleri Iletisim Bilgileri',
        'user_21': 'Yetkili Adi',
        'user_22': 'Sira No.',
        'user_23': 'Aciklama',
        'user_24': 'Islem Turu',
        'user_25': 'Islem Tutari',
        'user_26': 'Kalan Hesap Bakiyesi',
        'user_27': 'Ilgili TC No. veya SC Uzatma',
        'user_28': 'Islem Zamani',
    },
    renZhengTwo: {
        'rz_01': 'Kapsam sertifikasi uzatmak istediginiz urunleri doldurun:',
        'rz_02': 'Urun Kategorisi',
        'rz_03': 'Urun Detayi',
        'rz_04': 'Malzeme Bilesimi',
        'rz_05': 'Standart (Etiket Sinifi)',
        'rz_06': 'Musteri Numaralari',
        'rz_07': 'Islem',
        'rz_08': 'Kaydet',
        'rz_09': 'Iptal',
        'rz_10': 'Duzenle',
        'rz_11': 'Sil',
        'rz_12': 'Basariyla Kaydedildi!',
        'rz_13': 'Lutfen ihtiyac duydugunuzdan daha fazla urun ekleyin.',
    },
    application: {
        'ac_01': 'Basvuru formunu indirip imzalayin',
        'ac_02': 'Kapsam uzatma basvurusunu kaydedin',
        'ac_03': 'PDF Onizleme',
        'ac_05': 'Basariyla Yuklendi',
    },
    gotsInfo: {
        'gi_01': 'Laboratuvar test raporlarini yukleyin:',
        'gi_02': 'Urunde aksesuar/detaylar varsa, OEKO-TEX 100 Sertifikasi veya GOT2.4.16\'ya gore Test Raporu saglanmali ve uygun aksesuarlarin ilgili satin alma belgeleri de sunulmalidir.',
        'gi_03': 'Son Islemcinin sirket adi:',
        'gi_04': 'Basvuru yapmayi planladiginiz TC icin uygun olmayan Urun Kategorisi, Urun Detayi ve Malzeme Bilesimi varsa, lutfen ilk olarak Ana Sayfadaki "Kapsam Belgesi Değişiklik Başvurusu" modulune giderek kapsam sertifikasi uzatma basvurunuzu yapin.',
        'gi_05': 'Malzeme bilesiminden haric tutulan aksesuarlar (varsa):',
        'gi_06': 'Diger standarda sahip iliskili TC(ler) (varsa):',
        'gi_07': 'Lifler icin mm cinsinden lif uzunlugu ve uygun birim cinsinden lif inceligi (varsa):',
        'gi_08': 'Iplikler icin iplik numarasi (varsa):',
        'gi_09': 'Kumaslar icin kumas GSM ve kumas yapisi (varsa):',
        'gi_10': 'Bitmis tekstil urunleri icin tum malzemelerle ilgili kumas GSM (varsa):',
        'gi_11': 'Her malzeme icin dogru yuzdeyi doldurun.',
        'gi_12': 'Girdiginiz miktar, toplam kullanilabilir miktari asiyor; lutfen dogru miktari doldurun!',
        'gi_13': 'Lutfen son islemcinin Sirket adini doldurun.',
    },
    teInfo: {
        'ti_01': 'Laboratuvar test raporlarini yukleyin:',
        'ti_02': 'Ihtiyac duyulmasi halinde laboratuvar test raporlarini yukleyin',
        'ti_03': 'Son islemcinin Sirket adi:',
        'ti_04': 'Basvuru yapmayi planladiginiz TC icin uygun olmayan Urun Kategorisi, Urun Detayi ve Malzeme Bilesimi varsa, lutfen ilk olarak Ana Sayfadaki "Kapsam Belgesi Değişiklik Başvurusu" modulune giderek kapsam sertifikasi uzatma basvurunuzu yapin.',
        'ti_05': 'Her sertifikali bilesen icin net agirligi dogru sekilde doldurduktan sonra otomatik olarak hesaplanir',
        'ti_06': 'Malzeme bilesiminden haric tutulan trimler (varsa):',
        'ti_07': 'Diger standarda sahip iliskili TC(ler) (varsa):',
        'ti_08': 'Lifler icin mm cinsinden lif uzunlugu ve uygun birim cinsinden lif inceligi (varsa):',
        'ti_09': 'Iplikler icin iplik numarasi (varsa):',
        'ti_10': 'Kumaslar icin kumas GSM ve kumas yapisi (varsa):',
        'ti_11': 'Bitmis tekstil urunleri icin tum malzemelerle ilgili kumas GSM (varsa):',
        'ti_12': 'Ek Bilgileri Doldurun',
        'ti_13': 'Bilesen Ek Bilgileri',
        'ti_14': 'Bilesen Sertifikali Agirligi:',
        'ti_15': 'Lutfen her malzeme icin dogru yuzdeyi doldurun.',
        'ti_16': 'Sertifikasyon standardlarini yeniden yukleyin',
        'ti_17': 'En az bir sertifikasyon standardi gereklidir!',
        'ti_18': 'En fazla 6 oge ekleyebilirsiniz',
        'ti_19': 'Girdiginiz miktar, toplam kullanilabilir miktari asiyor; lutfen dogru miktari doldurun!',
        'ti_20': 'Kapatmak icin onayliyor musunuz?',
        'ti_21': 'Lutfen once son onayli bilesen icin kutlesel dengeyi "uygun" olarak belirleyin',
        'ti_22': 'MB Dogrulama Sonucu "Uygun Degil" ise gonderim yapilamaz. Kullanilan giris miktari, islem kaybi, cikti urun net agirligi ve/veya ek agirlik konularinda gerekli duzeltmeleri yapmak icin kontrol edin ve dogrulama sonucu "Uygun" oldugunda gonderim yapin.',
        'ti_23': 'Yuzde:',
        'ti_24': 'Sertifikali bilesen bilgilerini tamamlamaniz gerekmektedir!',
        'ti_25': 'Lutfen son islemcinin sirket adini doldurun',
        'ti_26': 'Sil',
        'ti_27': 'Urunde aksesuar varsa, OEKO-TEX 100 Sertifikasi veya GOT2.4.16\'ya gore Test Raporu saglanmali ve uygun aksesuarlarin ilgili satin alma belgeleri de sunulmalidir.',
    },
    changePassword: {
        'cp_01': 'Girdiginiz iki parola farkli. Lutfen yeniden girin.',
        'cp_02': 'Basariyla degistirildi, lutfen tekrar giris yapin!',
    },
    walletDetails: {
        'wd_01': 'Hesap Bakiyesi',
        'wd_02': 'Ilgili TC No. veya Kapsam Sertifikasi Uzatma',
        'wd_03': 'Gelir ve gider detaylari.xlsx',
    },
    personIndex: {
        'pi_01': 'Kullanici Adi',
        'pi_02': 'Yetkili Adi',
        'pi_03': 'Yukleme',
        'pi_04': 'Yukleme notlari (gerekiyorsa):',
        'pi_05': 'Yukleme Miktari:',
        'pi_06': 'Banka Makbuzu Yukle:',
        'pi_07': 'Dosya Sec',
        'pi_08': 'Banka Makbuzunu Goruntule',
        'pi_09': 'Sarj talebini gonder',
        'pi_10': 'Iptal',
        'pi_11': 'Lutfen miktari doldurun',
        'pi_12': 'Banka Makbuzunu Yukle',
        'pi_13': 'Basariyla gonderildi, onay bekleniyor.',
        'pi_14': 'Hata',
        'pi_15': 'Basariyla Yuklendi',
        'pi_16': 'Basariyla Degistirildi',
    },
    rawmaterialinventoryDetail: {
        'rd_01': 'Listelenen urunler icin kullanilan organik malzemenin sertifikasyonu USDA NOP kurallarina uygundur:',
        'rd_02': 'Sehir ve Posta Kodu',
        'rd_03': 'Giris TC\'sinde Saticinin Lisans No.：',
        'rd_03_2': 'CB Müşteri Numarası：',
        'rd_04': 'Giris TC\'sinde Saticinin Kapsam Sertifikasi No.：',
        'rd_05': 'Giris TC\'sinin Sertifikasyon Kurulu：',
        'rd_06': 'Detaylar',
        'rd_07': 'Giris TC\'sini Goruntule',
        'rd_08': 'Giris TC\'sindeki Sertifikali Agirlik',
        'rd_09': 'Islem yap',
        'rd_10': 'Giris Urunu Miktari (birim)：',
        'rd_11': 'Uretim Tarihi：',
        'rd_12': 'Standard (Etiket Sinifi)：',
        'rd_13': 'GOTS Sertifikali Agirlik(kg)：',
        'rd_14': 'Sertifikali Agirlik(kg)：',
        'rd_15': 'Ipucu: Bu giris malzemesi, bu TC sisteminde kaydedilmeden once kismen kullanilmis olabilir (ornegin, bu giris malzemesi stoku diger CB\'den tasinmis olabilir veya ETKO\'nun EXCEL stok raporlarinda kullanilmis olabilir).',
        'rd_16': 'Lutfen onceki kullanilan net agirligi doldurun; eger bu tamamen yeni bir giris stoku ise ve hic kullanilmamissa, lutfen 0 doldurun',
        'rd_17': 'Giris TC\'sinde listelenen Ek Bilgiler：',
        'rd_17_1': 'Ek Bilgi：',
        'rd_18': 'Malzeme bilesiminden haric tutulan trimler (varsa):',
        'rd_19': 'Diger standarda sahip iliskili TC(ler) (varsa):',
        'rd_20': 'Lifler icin mm cinsinden lif uzunlugu ve uygun birim cinsinden lif inceligi (varsa):',
        'rd_21': 'Iplikler icin iplik numarasi (varsa):',
        'rd_22': 'Kumaslar icin kumas GSM ve kumas yapisi (varsa):',
        'rd_23': 'Bitmis tekstil urunleri icin tum malzemelerle ilgili kumas gramajı (varsa):',
        'rd_23_1': 'Diğer ek bilgiler (varsa):',
        'rd_24': 'Sirketin Adi',
        'rd_25': 'Sirketin Adresi',
        'rd_26': 'Yerel dilde sirketin yasal adi',
        'rd_27': 'Yerel dilde sirketin yasal adresi',
        'rd_28': 'Iletisim adi',
        'rd_29': 'Iletisim telefonu',
        'rd_30': 'Iletisim e-postasi',
        'rd_31': 'Lisans No.',
        'rd_32': 'Bu TC No. daha onceden kaydedilmistir. Lutfen dogru TC No. girin！',
        'rd_33': 'Lutfen en az bir sertifikasyon standardi secin!',
        'rd_34': 'En fazla 6 oge ekleyebilirsiniz',
        'rd_35': 'Kapatmak icin onayla?',
        'rd_36': 'Lutfen girin',
        'rd_37': 'Lutfen secin',
        'rd_38': 'Tarih Sec',
        'rd_38_1': 'Uygulanabilir Değil',
        'rd_38_2': 'Uygulanamaz',
        'rd_39': 'Giris TC\'sinin Saticisini secin',
        'rd_40': 'Kapsam Sertifikasi Standardi',
        'rd_41': 'Giris Urunu Miktari (birim)：',
        'rd_42': 'Lutfen Giris Urunu Miktarini girin',
        'rd_43': 'Uretim Tarihi：',
        'rd_44': 'Tarih Sec',
        'rd_45': 'Standard (Etiket Sinifi)：',
        'rd_46': 'Lutfen Standard (Etiket Sinifi) secin',
        'rd_47': 'GOTS Sertifikali Agirlik(kg)：',
        'rd_48': 'Sertifikali Agirlik(kg)：',
        'rd_48_1': 'Geri Kazanılmış İçerik Ağırlığı(kg)：',
        'rd_49': 'Goruntulemek icin tiklayin',
        'rd_50': 'Cok az',
        'rd_51': 'Ayni',
    },
    rawmaterialinventoryIndex: {
        'ri_01': 'Baslangic Tarihi',
        'ri_02': 'Bitis Tarihi',
        'ri_03': 'ile',
        'ri_04': 'Giris Stok Kayit Kayitlari',
        'ri_05': 'Stok',
        'ri_06': 'Giris malzemesi Net Agirlik Stok Bakiyesi (kg)',
        'ri_07': 'Giris Stok Kodu：',
        'ri_08': 'Envanter Tuketim Kayitlari',
        'ri_09': 'No.',
        'ri_10': 'Girdi TC No.',
        'ri_11': 'Girdi TC Islem Tarihi',
        'ri_12': 'Girdi Malzeme Tedarikcisi',
        'ri_13': 'Girdi Malzeme Siparis No.',
        'ri_14': 'Cikis Urun Siparis No. ：',
        'ri_15': 'Girdi Malzeme Makale No.',
        'ri_16': 'Giris Urun Kategorisi',
        'ri_17': 'Giris Urun Detayi',
        'ri_18': 'Giris Malzeme Bilesimi',
        'ri_19': 'Giris TC\'de Belirtilen Net Agirlik',
        'ri_19': 'Giris TC\'de Belirtilen Net Agirlik',
        'ri_20': 'Giris TC\'de Belirtilen Ek Agirlik',
        'ri_21': 'Giris TC\'de Belirtilen Giris Malzeme Sertifikali Agirlik',
        'ri_22': 'Uretim oncesi giris malzemesi Net Agirlik Stok Bakiyesi(kg)',
        'ri_23': 'Cikis Urunlerinin Uretim Tarihi',
        'ri_24': 'Cikis Urun Siparis No.',
        'ri_25': 'Cikis Urun Kategorisi',
        'ri_26': 'Cikis Urun Detayi',
        'ri_27': 'Cikis Urun Artikel No.',
        'ri_28': 'Cikis Urun Bilesimi',
        'ri_29': 'Bu lot icin kullanilan Giris Malzemesinin Net Agirligi(kg)',
        'ri_30': 'Uretim kaybi',
        'ri_31': 'Sertifikali agirlik Cikis Urunlerine donusturuldu',
        'ri_32': 'Uretimden sonra giris malzemesi Net Agirlik Stok Bakiyesi(kg)',
        'ri_33': 'Cikis TC No. (Islem Tarihi)',
        'ri_33_1': 'Bu, Konu',
        'ri_34': 'Cikis TC No：',
        'ri_35': 'Cikis Urunlerinin Uretim Tarihi：',
        'ri_36': 'Cikis Urun Siparis No.:',
        'ri_37': 'Cikis Urun Kategorisi：',
        'ri_38': 'Cikis Urun Detayi：',
        'ri_39': 'Cikis Urun Bilesimi：',
        'ri_40': 'Bu lot icin kullanilan Giris Malzemesinin Net Agirligi(kg)：',
        'ri_41': 'Uretim kaybi：',
        'ri_42': 'Sertifikali agirlik Cikis Urunlerine donusturuldu：',
        'ri_43': 'Uretimden sonra giris malzemesi Net Agirlik Stok Bakiyesi(kg)：',
        'ri_44': 'Cikis TC No. Islem Tarihi：',
        'ri_45': 'Satistan sonra cikis urunu kalan stok miktari (satis birimi)：',
        'ri_46': 'Satistan sonra cikis urunu kalan toplam Net Agirlik (kg)：',
        'ri_47': 'Satistan sonra cikis urunu kalan toplam sertifikali agirlik (kg)：',
        'ri_48': 'Giris TC No.',
        'ri_49': 'Giris Malzeme Sevk Dokuman No.',
        'ri_50': 'Giris Malzeme Tedarikcisi',
        'ri_51': 'Giris TC\'de Belirtilen Urun No.',
        'ri_52': 'Giris Malzeme Siparis No.',
        'ri_53': 'Giris Malzeme Makale No.',
        'ri_54': 'Giris TC\'de Belirtilen Net Agirlik',
        'ri_55': 'Giris TC\'de Belirtilen Ek Agirlik',
        'ri_56': 'No.',
        'ri_57': 'Giris TC No.',
        'ri_58': 'Giris Malzeme Siparis No.',
        'ri_59': 'Giris Malzeme Makale No.',
        'ri_60': 'Cikis Urun Siparis No. ',
        'ri_61': 'Cikis TC No.',
        'ri_62': 'Giris Stok ile ilgili ek.zip',
        'ri_63': 'Basarili!',
        'ri_64': 'Giris Envanter Tuketim Kayitlari.xlsx',
        'ri_64_1': 'Giris Stok Listesi_exports.xlsx',
        'ri_65': 'Ek',
    },
    newInventory: {
        'ni_01': 'Giris Stokunu Duzenle',
        'ni_02': 'Lutfen tur secin!',
        'ni_03': 'Giris Stok Detaylari',
    },
    buyer: {
        'buyer_01': 'Ana Lisans Sahibinin adi',
        'buyer_02': 'Ana',
        'buyer_03': 'Sertifikasyon Kurumu',
        'buyer_04': 'Basariyla Kaydedildi!',
        'buyer_05': 'Bu veriyi silmek istediginizden emin misiniz?',
    },
    scCertInfo: {
        'sci_01': 'Sertifika Bilgisi',
        'sci_02': 'Sertifika Durumu:',
        'sci_03': 'Ana Lisans Sahibinin adi',
        'sci_03_1': 'Ana Lisans Sahibinin TE-Kimliği',
        'sci_04': 'Ana',
        'sci_05': 'Sertifikasyon Kurumu',
        'sci_06': 'Bu veriyi silmek istediginizden emin misiniz?',
        'sci_07': 'Kapsam Sertifika No.',
        'sci_08': 'CB Müşteri Numarası',
        'sci_09': 'Bu, Konu',
        'sci_10': 'Kapsam Sertifika No.：',
        'sci_11': '-',
        'sci_12': 'Baslangic Tarihi',
        'sci_13': 'Bitis Tarihi',
        'sci_14': 'Alici',
        'sci_15': 'Son Islemci',
        'sci_16': 'Tedarikci',
        'sci_17': 'Iletisim:',
        'sci_18': 'Alici;',
        'sci_19': 'Tedarikci;',
        'sci_20': 'Teslimat Adresi;',
        'sci_21': 'Sertifikali Olmayan Ticaretci;',
        'sci_22': 'Sertifikali Olmayan',
        'sci_23': 'Kapsam Sertifikasinin Ana Lisans Sahibi',
        'sci_24': 'Kapsam Sertifikasinda Listelenen Sonraki Site',
        'sci_25': 'Ana Lisans Sahibinin adi',
        'sci_26': 'Kapsam Sertifikasi',
        'sci_26_1': 'Kapsam Sertifikalari.zip',
        'sci_27': 'Onizleme',
        'sci_28': 'Alici',
        'sci_29': 'Tedarikci',
        'sci_30': 'Teslimat Adresi',
        'sci_31': 'Sertifikali Olmayan Alsatci',
        'sci_32': 'Sirket Adi',
        'sci_33': 'Sirket Adresi',
        'sci_34': 'Yerel Dilde Sirket Hukuki Adi',
        'sci_35': 'Yerel Dilde Sirket Hukuki Adresi',
        'sci_36': 'Iletisim Adi',
        'sci_37': 'Iletisim Telefonu',
        'sci_38': 'Iletisim E-postasi',
        'sci_39': 'Lisans No.',
        'sci_40': 'Tedarik Zinciri Disa Aktarimlari.xlsx',
        'sci_41': 'No.',
        'sci_42': 'Giris TC No.',
        'sci_43': 'Giris Kaynak TC Sertifikasyon Standardı',
        'sci_44': 'Giris TC Islem Tarihi',
        'sci_45': 'Giris Stok Kayit Zamani',
        'sci_46': 'Son Inceleme Zamani',
        'sci_47': 'Giris malzemesi Net Agirlik Stok Bakiyesi',
        'sci_48': 'Durum',
        'sci_49': 'Kendi Urunlerim',
        'sci_50': 'Kendi Urunlerim.xlsx',
    },
    subsidiaryIndex: {
        'si_01': 'Tedarik Zinciri Sirketini Duzenle',
        'si_02': '-',
        'si_03': 'Baslangic Tarihi',
        'si_04': 'Bitis Tarihi',
        'si_05': 'TC Basvuru Tarihi',
        'si_06': 'Evet',
        'si_07': 'HAYIR',
        'si_08': 'Yok',
        'si_09': 'Kullanilan Giris TC No.',
        'si_10': 'TC Iptal Edildi',
        'si_11': 'Iptal Nedeni:',
        'si_12': 'Odenmedi',
        'si_13': 'Resmi TC\'yi Goruntule',
        'si_14': 'Geri Al',
        'si_15': 'Duzenle',
        'si_16': 'Banka Makbuzu Yukle',
        'si_17': 'Banka Makbuzunu Yeniden Yukle',
        'si_18': 'Sertifikali Bilesen Ayrintilarini Goruntule',
        'si_19': 'Sertifikali Urun Ayrintilari',
        'si_20': 'Sertifikali Bilesen Ayrintilari',
        'si_21': 'PDF Onizleme',
        'si_22': 'Cikti TC No.',
        'si_23': 'Giris TC No.',
        'si_23_1': 'Çıktı Ürün Sipariş No.',
        'si_23_2': 'Çıktı Ürün Madde No.',
        'si_24': 'Inceleme icin Bekliyor',
        'si_25': 'Reddedildi',
        'si_26': 'On Inceleme Tamamlandi',
        'si_27': 'Musterinin TC taslagini onaylamasi bekleniyor',
        'si_28': 'TC yayinlanmasi bekleniyor',
        'si_29': 'TC Verildi',
        'si_29_1': 'Ek notlar',
        'si_30': 'Banka Makbuzu Basariyla Yuklendi',
        'si_31': 'Kapatmak icin onayliyor musunuz?',
        'si_32': 'Bu TC basvurusunu geri almak istediginizden emin misiniz?',
        'si_33': 'Ipuclari',
        'si_34': 'Onayla',
        'si_35': 'Iptal',
        'si_36': 'TC taslagini cift kontrol ettiniz mi ve onaylamak istediginizden emin misiniz?',
        'si_37': 'Basariyla Yuklendi',
        'si_38': 'Taslak TC/Resmi TC',
        'si_39': 'Henuz Fatura Yok',
        'si_40': 'TC Basvuru Detaylari',
        'si_41': 'Fatura ve Banka Islem Kayitlari Yuklenmedi',
        'si_42': 'Gonderi Belgeleri Yuklenmedi',
        'si_43': 'Ayrintilari Goruntule',
        'si_44': 'Satis birimindeki cikti urunlerin kalan stoku',
        'si_45': 'Kalan stok urunlerin Net Agirligi (KG)',
        'si_46': 'Kalan stok urunlerin Sertifikali Agirligi (kgs)',
        'si_47': 'Geri Al',
    },
    TC_application: {
        'TCe_01': 'Kapsam Belgesi Değişiklik Başvurusu',
        'TCe_02_2':'Sunulan Kapsam Sertifikası Uzatma Başvuruları（',
        'TCe_02': 'Kaydedilen Scope Sertifikasi Uzatma Basvurusu (',
        'TCe_03': 'madde):',
        'TCe_04': 'Basvuran Sirket Adi',
        'TCe_05': 'Scope Sertifikasi No.',
        'TCe_06': 'Scope Sertifikasi Standardi',
        'TCe_07': 'Kaydedilen Tarih',
        'TCe_07_1': 'Scope Sertifikasi Uzatma Basvurusu Tarihi',
        'TCe_08': 'Eklenen Bagimsiz Sertifikali Taseronlarin Sayisi',
        'TCe_09': 'Basvuru Durumu',
        'TCe_10': 'Gonderilmemis',
        'TCe_11': 'Isle',
        'TCe_12': 'Basvuruya Devam Et',
        'TCe_13': 'Basvuru Gonderildi',
        'TCe_14': 'On Inceleme Tamamlandi',
        'TCe_15': 'Yenilenmis Scope Sertifikasi Verildi',
        'TCe_16': 'Geri Gonderildi',
        'TCe_17': 'Isle',
        'TCe_18': 'Duzenle',
        'TCe_19': 'Geri Gonder',
        'TCe_20': 'Basvuru Ekleri.zip',
        'TCe_21': 'Ek Dosyalari Indir',
        'TCe_22': 'Orjinal Urun Siparisleri Kopyasi:',
        'TCe_22_1': 'Alici ve satici arasindaki ticarette sertifikasiz bir tuccar bulunuyorsa, lutfen alici ile sertifikasiz tuccar arasindaki PO (veya satis sozlesmesi) ve sertifikasiz tuccar ile satici arasindaki PO (veya satis sozlesmesi) bilgilerini saglayin',
        'TCe_23': 'Cikis Urun Siparisinin Orijinal Kopyasini Goruntule',
        'TCe_24': 'Cikis Urun Siparisinin Orijinal Kopyasini Goruntule',
        'TCe_25': 'Cikis Urun Siparisinin Orijinal Kopyasini Goruntule',
        'TCe_26': 'Cikis Urun Siparisinin Orijinal Kopyasini Goruntule',
        'TCe_27': 'Cikis Urun Siparisinin Orijinal Kopyasini Goruntule',
        'TCe_28': 'Taseronun kapsam sertifikasi (bagimsiz sertifikali taseron icin gecerlidir):',
        'TCe_29': 'Ek Dosyayi Goruntule',
        'TCe_30': 'Islenmemis urunlerin taserona gonderilmesine iliskin tasima belgeleri ve teslimat notlari:',
        'TCe_31': 'Taseron faturasi:',
        'TCe_32': 'Taserondan islenmis urunlerin gonderilmesine iliskin tasima belgeleri:',
        'TCe_33': 'Teslimat Adresi',
        'TCe_34': '深圳-518000',
        'TCe_35': '广东省',
        'TCe_36': '中国',
        'TCe_37': 'Islem',
        'TCe_38': 'xxxxx sirketi',
        'TCe_39': 'Kategori Aciklamasi',
        'TCe_40': 'Detay Aciklamasi',
        'TCe_41': 'Malzeme Bilesen Aciklamasi',
        'TCe_42': 'Ek Bilgi',
        'TCe_43': 'TC taslagini kontrol ettiginizden ve onaylamak istediginizden emin misiniz?',
        'TCe_44': 'Ipuclari',
        'TCe_45': 'Onayla',
        'TCe_46': 'Iptal',
        'TCe_47': 'Onaylama Iptal Edildi',
        'TCe_48': 'Kapatmak Icin Onaylayin?',
        'TCe_49': 'Basariyla Yuklendi',
        'TCe_50': 'Giris TC No.',
        'TCe_51': 'Giris TC\'de Belirtilen Urun No.',
        'TCe_52': 'Urun No.',
        'TCe_53': 'MB Kodu',
        'TCe_54': 'Cikis Urun Siparis No.',
        'TCe_55': 'Cikis Urun Madde No.',
        'TCe_56': 'Ilgili Cikis TC No.',
        'TCe_62': 'Bu MB\'yi iptal etmek istediginizden emin misiniz?',
        'TCe_63': 'Basariyla Iptal Edildi',
        'TCe_64': 'Iptal Hatasi',
        'TCe_65': 'Ek',
        'TCe_66': 'Henuz fatura yok',
        'TCe_67':'ipuçları',
        'TCe_68':'Daha önce doldurduğunuz bilgiler otomatik olarak kaydedilmiştir. Kayıtlı bilgilerden devam etmek ister misiniz?',// EN
        'TCe_69':'Hayır',
        'TCe_70':'Evet',
    },
    'switchingLanguagesSucceeded': 'Birden çok dil arasında geçiş başarılı bir şekilde gerçekleştirildi Prosedürü',
    'please_select_product_category':'Lütfen bir ürün kategorisi seçiniz',
    'please_select_product_details':'Lütfen ürün detaylarını seçin',
    'please_select_certification_standard':'Lütfen ürünün sertifikasyon standardını (etiket sınıfını) seçin',
    'please_select_location':'Lütfen konumu seçin',
    'save_successfully': 'Başarıyla kaydedildi',
    'proportion': 'Oran:',
    'top_up':'Bakiye yükleme',
    'additional_information_content':'Ek bilgi',
    'mb_no':'MB sayı',
    'order_sn':'Üretilen ürünün sipariş numarası veya satış sözleşmesi numarası',
    'product_no':'Ürün numarası',
    'material_tc_no':'İlgili ürün TC numarası',
    'return_extension_application':'Bu başvuruyu geri çekmek istediğinizden emin misiniz?',
    'tips':'ipuçları',
    'confirm':'Onaylamak',
    'cancel':'İptal',
    'delete_success':'Başarıyla silindi!',
    'confirm_close':'Kapatmak istiyor musunuz?',
    'addition':'ek',
    'no_bill':'Fatura yok',
    'PDF_preview':'PDF önizleme',
    'component_additional_information':'Bileşen ek bilgisi',
    'read_authentication_criteria_again':'Kimlik doğrulama kriterlerini tekrar okuyun',
    'please_enter_english_name':'Lütfen son işlem partisinin İngilizce adını girin',
    'please_firstly_select_the_input_TC_certification_standard' : 'Lütfen öncelikle giriş TC sertifikasyon standardını seçin',
    'remark': 'Açıklama',
    'requireModify': 'Değişiklik yapılmasını istemek',
    'buyerScNumber': 'Alıcının Kapsam Sertifikası numarası',
    'buyerName': 'Alıcı adı',
    'etko_bank_account_info': 'ETKO Banka Hesap Bilgileri',
    'sfjjsq': 'Hızlı başvuru talebinde bulunmak ister misiniz',
    'sfjjsq-header': 'Hızlı başvuru',
    'sfjjsq-tips': 'Hızlı başvurulardan, ek ücret talep edilmektedir',

    "shenpi.shenpitc.xzczzs": "İptal TC'yi gör",

    "components.detail.74lq1y": "Ürünlerde kullanılan organik malzemelerin sertifikasyonu USDA NOP kurallarına uygun mu:",
    "components.detail.072962": "Ekleri Görüntüle",
    "components.detail.3cy3ox": "TC Satıcı Lisans Numarasını Girin:",
    "components.detail.15y4n0": "TC Satıcı Kapsam Sertifikası Numarasını Girin:",
    "components.detail.gmcg38": "Girdi TC'nin Sertifikasyon Kurulu:",
    "components.detail.0x5156": "Ayrıntılar",
    "components.detail.da2yth": "Girdi Malzeme Ürün No. birimleri:",
    "components.detail.48fx0f": "Üretim Tarihi:",
    "components.detail.n7n31n": "Standart (Etiket Sınıfı):",
    "components.detail.g10oj7": "GOTS Sertifikalı Ağırlık (KG):",
    "components.detail.v24ddr": "Sertifikalı Ağırlık (KG):",
    "components.detail.cn3zhy":
        "İpucu: Bu girdi malzemesi, kayıt edilmeden önce kısmen kullanılmış olabilir (ör. bu girdi malzemesi stoku başka bir CB'den taşınmış veya ETKO'nun EXCEL stok raporlarında kullanılmış olabilir).",
    "components.detail.svj7bg": "Girdi TC'de listelenen Ek Bilgiler:",
    "components.detail.176wve": "Malzeme bileşiminden hariç tutulan aksesuarlar (varsa):",
    "components.detail.h6y2h7": "Diğer standart(lar)a göre sertifikalı ilgili TC(ler) (varsa):",
    "components.detail.uikh4p": "Lifler için, lif uzunluğu (mm) ve lif inceği (uygulanabilir birim) (varsa):",
    "components.detail.438pc2": "İplikler için, iplik numarası (varsa):",
    "components.detail.3mcui6": "Kumaşlar için, kumaş GSM ve kumaş yapısı (varsa):",
    "components.detail.m12i6i": "Bitmiş tekstil ürünleri için, ilgili malzemeye sahip tüm kumaşların kumaş GSM'i (varsa):",
    "components.detail.3cck2i": "Girdi TC'deki Sertifikalı Ağırlık",
    "components.detail.0514tf": "Bu TC No. önceden kaydedilmiş. Lütfen doğru TC No.'yu girin!",
    "components.detail.1a2d2f": "eksik",
    "components.detail.f23f11": "Lütfen en az bir sertifikasyon standardı seçin!",
    "components.detail.12d7h8": "Lütfen en az bir sertifikasyon standardı seçin!",
    "components.detail.qw2ny8": "Kapatmak istediğinize emin misiniz?",
    "components.detail.6y6728": "Aynı",

    "detail.gots-information.5t26ix": "Laboratuvar test raporları:",
    "detail.gots-information.8ajb6i": "*Son işlemci:",
    "detail.gots-information.8g9amx": "Bu, 2. durum için sayfadır",
    "detail.gots-information.6681br": "Lütfen her malzeme için doğru yüzdeyi doldurun",
    "detail.gots-information.9rs5yp": "Girdiğiniz miktar toplam kullanılabilir miktarı aşıyor, lütfen doğru miktarı doldurun!",
    "detail.gots-information.3o7113": "Eşit",
    "detail.gots-information.61d6f6": "Kütle Denkligi Doğrulama",
    "detail.gots-information.5d3my4": "Bileşen-Kütle Denkligi Doğrulama",
    "detail.gots-information.j9f332": "Yüzde:",
    "detail.mbdetail-dialog.2p3z70": "MB Ayrıntılarını Görüntüle",
    "detail.te-information.nn88kd": "Çıktı Ürün Sertifikasyon Standart(lar)ı",
    "detail.te-information.frw455": "*Ürün resimleri ve üretim kayıtları:",
    "detail.te-information.6xjgts": "Laboratuvar test raporlarını yükle:",
    "detail.te-information.7af726": "Parçalı Ürün (Component Claim):",
    "detail.te-information.matv13": "Proses kategorileri:",
    "detail.te-information.261xrb": "Son İşlemci:",
    "detail.te-information.m4s6ko": "Son işlemcinin Adı:",
    "detail.te-information.3lhz83": "Son işlemcinin Lisans No.:",
    "detail.te-information.o89i2q": "Son işlemcinin ülke/bölgesi:",
    "detail.te-information.86596g": "Çıktı Ürün Sipariş No.:",
    "detail.te-information.4qx7o0": "Çıktı Ürün Makale No.:",
    "detail.te-information.60pe2x": "Çıktı Ürün Kategorisi:",
    "detail.te-information.i8436g": "Çıktı Ürün Detayı:",
    "detail.te-information.1wn57w": "Çıktı Ürün Bileşimi:",
    "detail.te-information.t64r30": "Standart (Etiket Sınıfı):",
    "detail.te-information.3dqg6q": "Satış Birimi:",
    "detail.te-information.4lhqm5": "Bileşen Onaylı Ağırlık:",
    "detail.te-information.k5q746": "Bileşen Ek Bilgi",
    "detail.te-information.v3ll1e": "Kullanılan Giriş TC Bilgisi",
    "detail.te-information.275fwr": "Sertifikasyon standardını tekrar yükleyin",
    "detail.te-information.sb4ubf": "Maksimum 6 öğe ekleyebilirsiniz",
    "detail.te-information.77xv56": "Lütfen önce son onaylı bileşen için kütle dengelemeyi \"uygun\" hale getirin",
    "detail.te-information.s1d505": "Kullanılan giriş malzemesi ekle",
    "detail.te-information.y75s6p": "MB Doğrulama Sonucu \"Uygun Değil\" ise gönderime izin verilmez. Lütfen kullanılan giriş miktarını, işlem kaybını, çıktı ürün net ağırlığını ve/veya ek ağırlığı doğrulama sonucu \"Uygun\" olana kadar kontrol edin ve gerekli düzeltmeleri yapın.",
    "detail.te-information.m2354g": "",
    "detail.te-information.5j272r": "Onaylı bileşen bilgisini tamamlamanız gerekmektedir!",

    "operation.sales-unit.lon717": "Ekle",
    "operation.sales-unit.dkv5jk": "Satış Birimi Adı:",
    "operation.sales-unit.jzj1h8": "*Görünür:",
    "operation.sales-unit.16qx69": "Durum:",
    "operation.sales-unit.kunw4m": "Açık/Kapalı",
    "operation.sales-unit.8d8wd9": "Açık",
    "operation.sales-unit.6t89w8": "Kapalı",
    "operation.sales-unit.4a1zq8": "Satış Birimi Adı",
    "operation.sales-unit.709588": "Oluşturma Zamanı",
    "operation.sales-unit.mkniku": "Görünür Durumu",
    "operation.sales-unit.7tsa85": "Satış Birimini Düzenle",
    "operation.sales-unit.y14ru9": "Lütfen sıralama No.'yu girin",
    "operation.sales-unit.1l4rer": "Açıksa, müşteriler toplu denge yaparken bunu seçebilir",
    "operation.sales-unit.2dfk58": "Silmek istediğinizden emin misiniz?",

    "listall.zongpingheng.1k4464": "TC Başvuru İnceleme ve Yönetimi",
    "listall.zongpingheng.987ga7": "Bu TC Başvurusu ile İlgili Kütle Denkligi",
    "listall.zongpingheng.u4byc8": "Detayları Görüntüle",
    "listall.zongpingheng.qq4fyz": "Önizleme |",
    "listall.zongpingheng.d82jfc": "Onayla",
    "listall.zongpingheng.qd80x3": "Reddet",
    "listall.zongpingheng.uf3q17": "MB Kodu",
    "listall.zongpingheng.uor80g": "Üretim Tarihi",
    "listall.zongpingheng.xnh2zp": "Proses Türleri",
    "listall.zongpingheng.l7hk31": "Son İşlemci",
    "listall.zongpingheng.vxriqn": "Çıkış Ürün Sipariş No.",
    "listall.zongpingheng.0d80t0": "Çıkış Ürün Kategorisi",
    "listall.zongpingheng.u9u61y": "Çıkış Ürün Detayı",
    "listall.zongpingheng.c22wxh": "Çıkış Ürün Bileşimi",
    "listall.zongpingheng.kp71xo": "Satış Birimi",
    "listall.zongpingheng.861onw": "Çıkış Ürün Birim Sayısı",
    "listall.zongpingheng.xohsa6": "Çıkış Ürün Net Ağırlık (kg)",
    "listall.zongpingheng.287484": "Çıkış Ürün Ek Ağırlık (kg)",
    "listall.zongpingheng.biwa03": "Çıkış Ürün Ek Bilgiler",
    "listall.zongpingheng.69dh43": "Bileşen Talep Bayrağı",
    "listall.zongpingheng.7goxu8": "Kullanılan Girişlerin Ayrıntıları",
    "listall.zongpingheng.k5rn2z": "Ürün Özelliklerini Görüntüle",
    "listall.zongpingheng.32264h": "MB Doğrulama Sonucu",
    "listall.zongpingheng.lp77w6": "ETKO Onay Durumu",
    "listall.zongpingheng.7wlqn5": "Başvurulan Çıkış TC No.",
    "listall.zongpingheng.21a36g": "Satış birimindeki çıkış ürünlerinin kalan stoku",
    "listall.zongpingheng.lpjhkj": "Kalan çıkış ürünlerinin net ağırlığı (KGS)",
    "listall.zongpingheng.bmh110": "Kalan çıkış ürünlerinin sertifikalı ağırlığı (KGS)",

    'tcxxhdb': 'TC Taslağı Ön Kontrol Belgesi',
    'other': 'Başka',

    'save_to_draft': "Draftı Kaydet",
    'delete_draft_tips': '您确定要删除该草稿吗',
    'continue_to_fill': 'Doldurmaya Devam Et',
    'saved_time': 'Kaydedilme Zamanı',
    'yltcbz': 'Kaynak TC Standardı',
    'unsubmitted': 'Tamamlanmadı',
    'unsubmitted_input_stocks': 'Tamamlanmamış Kaynak Stokları',
    'other_remark' : 'Diğer Notlar',
    'other_remark_1' : 'Duzenle Notlar',
    'unsubmitted_mass_balance': 'Tamamlanmamış Kütle-Balans',
    'unsubmitted_tc_applications': 'Unsubmitted TC Applications',

    'notes_to_the_tc_reviewer': 'TC İnceleyene Notlar',

    'import_supply_chain_companies': 'Üretim Zinciri Firmalarını İçe Aktar',
    'importing_template':'Şablonu İçe Aktar',
    'download_template': 'Şablonu İndir',
    'upload_importing_file': 'İçe Aktarma Dosyasını Yükle',
    'confirm_to_import': 'İçe Aktarmayı Onayla',
    "shenpi.shnepikc.48184g_4": "Stok manuel olarak azaltılmıştır.",
    "operator": "Operatör",
    "email_is_error": "Posta kutusu formatı yanlış",
}
