
import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'
import {Message} from 'element-ui'
import changePassword from "@/views/login/changePassword";
import i18n from '@/language/lang'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    alias: "/",
    name: 'login',
    component: login
  },
  {
    path: '/changePassword',
    alias: '/changePassword',
    name: 'changePasswordWithoutLogin',
    component: changePassword
  },
  // 首页
  {
    path: '/index',
    name: 'index',
    redirect: '/index/home',
    component: () => import('../views/index/index.vue'),
    children: [
      // 首页
      {
        path: '/index/home',
        name: 'home',
        component: () => import('../views/home/index.vue'),
      },
      // 申请扩项
      {
        path: '/index/Extension',
        name: 'Extension',
        component: () => import('../views/home/components/Extension.vue'),
      },
      // 申请扩项--下一步
      {
        path: '/index/application',
        name: 'application',
        component: () => import('../views/home/components/application.vue'),
      },
      // 申请扩项--成功
      {
        path: '/index/success',
        name: 'success',
        component: () => import('../views/home/components/success.vue'),
      },
      // 供应链单位首页
      {
        path: '/index/Ancillary-supply',
        name: 'Ancillary-supply',
        meta:{
          keep:true,
        },
        component: () => import('../views/subsidiary/Ancillary-supply.vue'),
      },
      // 新增供应链单位
      {
        path: '/index/subsidiary',
        name: 'subsidiary',
        meta:{
          keep:true,
        },
        component: () => import('../views/subsidiary/index.vue'),
      },
      // 我的认证产品
      {
        path: '/index/Certifiedproducts',
        name: 'Certifiedproducts',
        component: () => import('../views/MyCertifiedProducts/index.vue'),
      },
      // 我的原料库存
      {
        path: '/index/rawmaterial',
        name: 'rawmaterial',
        component: () => import('../views/Rawmaterialinventory/index.vue'),
      },
      // 新增原料库存
      {
        path: '/index/New-inventory',
        name: 'New-inventory',
        meta:{
          keep:true,
        },
        component: () => import('../views/Rawmaterialinventory/New-inventory.vue'),
      },
      // 原料库存详情
      {
        path: '/index/New-inventory/detail',
        name: 'New-inventory-detail',
        meta:{
          keep: true,
        },
        component: () => import('../views/Rawmaterialinventory/raw-detail.vue'),
      },
      //  TC申请状态管理
      {
        path: '/index/Administration',
        name: 'Administration',
        redirect: '/index/Administration/total',
        component: () => import('../views/TC-Applicationstatus/index.vue'),
        children: [
          // 总量平衡和成品库存列表
          {
            path: '/index/Administration/total',
            name: 'total',
            component: () => import('../views/TC-Applicationstatus/components/total.vue')
          },
          {
            path: '/index/Administration/apply',
            name: 'apply',
            component: () => import('../views/TC-Applicationstatus/components/apply.vue')
          },
          {
            path: '/index/Administration/extension',
            name: 'extension',
            component: () => import('../views/TC-Applicationstatus/components/extension.vue')
          },
        ]
      },
      // 添加成品的总量平衡信息
      {
        path: '/index/Add-information',
        name: 'Add-information',
        component: () => import('@/views/TC-Applicationstatus/Totalbalance/index.vue'),
      },
      {
        path: '/index/mb/revise',
        name: 'Add-information',
        component: () => import('@/views/mb/revise/index.vue'),
      },
      // 申请成品TC
      {
        path: '/index/Add-Approval',
        name: 'Add-Approval',
        meta:{
          keep: true,
        },
        component: () => import('@/views/TC-Applicationstatus/TC-Approval/index.vue'),
      },
      {
        path: '/index/Add-Approval/edit',
        name: 'Add-Approval-Edit',
        component: () => import('@/views/TC-Applicationstatus/TC-Approval/edit.vue'),
      },
      // TC草本
      {
        path: '/index/TC-ConfirmationDraft',
        name: 'TC-ConfirmationDraft',
        component: () => import('@/views/TC-Applicationstatus/components/TC-ConfirmationDraft.vue'),
      },
      {
        path: '/index/TC-ViewDraft',
        name: 'TC-ViewDraft',
        component: () => import('@/views/TC-Applicationstatus/components/TC-ViewDraft.vue'),
      },
      // TC认证详情
      {
        path: '/index/TC-ApplicationDetails',
        name: 'TC-ApplicationDetails',
        component: () => import('@/views/TC-Applicationstatus/components/TC-ApplicationDetails.vue'),
      },
      // 账单及付款信息
      {
        path: '/index/bill',
        name: 'bill',
        component: () => import('@/views/Billinginformation/index.vue'),
      },
      // 个人账户信息
      {
        path: '/index/userInfo',
        name: 'userInfo',
        component: () => import('@/views/personal/index.vue'),
      },
      // 修改密码
      {
        path: '/index/changePassword',
        name: 'changePassword',
        component: () => import('@/views/personal/components/ChangePassword.vue'),
      },
      // 联系我们
      {
        path: '/index/contact-us',
        name: 'contact-us',
        component: () => import('@/views/personal/components/contact-us.vue'),
      },
      // 钱包明细
      {
        path: '/index/Wallet-details',
        name: 'Wallet-details',
        component: () => import('@/views/personal/components/Wallet-details.vue'),
      },
      // 管理子账户
      {
        path: '/index/manage',
        name: 'manage',
        component: () => import('@/views/personal/components/manage.vue'),
        beforeEnter: (to, from, next) => {
          let child = localStorage.getItem('et-child-id') || 0
          if(child != 0){ next({ path: '/index/userInfo' })}
          else next()
        }
      },
    ]
  }
]
//  解决路由重复报错的问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}

const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  routerReplace.call(this, location).catch((error) => error)
}

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=> {
  let token = localStorage.getItem('et-token')

  // console.log(to)
  if (!token && to.name != 'login' && to.name != 'changePasswordWithoutLogin') {
    Message({
      message: i18n.t('loginFail'), // bug ---拿不到this，会报错,不会next
      type: 'error'
    })
    next({path: '/login'})
  } else {
    next()

  }


})
export default router
